import React, { useCallback } from 'react';

import { useQuery } from 'react-query';
import { HiOutlineSearch } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import UserHeader from '@comp/user/default/header/UserHeader';
import CommonBanner from '@comp/user/default/topBanner/CommonBanner';
// import { List } from '@static/user/default/noticeHead';

import { ReadResponse } from '@static/user/default/commonTypes';
import { getfetcher } from '@util/fetcher';
import {
  TopContainer,
  TableContainer,
  ReadHead,
  ReadContent,
  RoundButton,
  MoveNotice,
} from './styles';
import DrawerNavigation from '../../../DrawerNavigation';

// const getParamFetcher = async (url: number, siteId: number) => {
//   const res = await axios.get(`/notice/${url}?siteId=${siteId}`);
//   return res.data.data;
// };

function UserRead() {
  const navigate = useNavigate();
  const location = useLocation();
  const [totalCount, setTotalCount] = React.useState(0);

  const [page, setPage] = React.useState<number>(
    Number(location.pathname.split('/user/notice/').join('')) ?? 1,
  );

  const { data } = useQuery<ReadResponse>(
    ['post', page],
    () => getfetcher(`/notice/${page}?siteId=`, '', true),
    {
      onSuccess: (res: ReadResponse) => {
        setTotalCount(res.data.totalCount ?? 0);
      },
      keepPreviousData: true,
      retry: 3,
      refetchInterval: 1000 * 100,
    },
  );

  const prevNotice = useCallback(() => {
    const currentId = Number(
      location.pathname
        .split('/user/notice/')
        .filter((x) => Boolean(x))
        .join(''),
    );
    const prevId = currentId - 1 > 0 ? currentId - 1 : currentId;
    navigate(`/user/notice/${prevId}`);
    setPage(prevId);
  }, [navigate, location, setPage]);

  const nextNotice = useCallback(() => {
    const currentId = Number(
      location.pathname
        .split('/user/notice/')
        .filter((x) => Boolean(x))
        .join(''),
    );
    const dataSize = 20;
    const nextId = currentId + 1 > dataSize ? currentId : currentId + 1;
    navigate(`/user/notice/${nextId}`);

    setPage(nextId);
  }, [navigate, location, setPage]);

  const onBack = useCallback(() => navigate('/user/notice/'), [navigate]);

  return (
    <>
      <UserHeader />
      <DrawerNavigation />
      <CommonBanner title='공지사항' text='365일, 24시간 카카오톡 상담가능' />
      <TableContainer>
        <TopContainer>
          <h1>
            공지사항<p>전체 {totalCount ?? 0}건</p>
          </h1>
          <div className='searchBox'>
            <input
              placeholder='검색어를 입력하세요'
              //   value={keyword}
              //   onChange={onKeyword}
              //   onKeyUp={onSearch}
            />
            <HiOutlineSearch
              size={24}
              // onClick={onSearchClick}
            />
          </div>
        </TopContainer>
        {data && (
          <>
            <ReadHead>
              <div className='notice-title'>{`${data?.data.notice.title}`}</div>
              <div className='notice-data'>{`${new Date(
                data.data.notice.createdAt,
              )
                .toLocaleDateString('ko-KR', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
                .replace(/\s+/g, '')
                .slice(0, -1)}`}</div>
            </ReadHead>
            <ReadContent>{`${data?.data.notice.content}`}</ReadContent>
          </>
        )}
        <MoveNotice>
          <div>
            이전글
            {data?.data.next === null ? (
              <button type='button'>이전글이 없습니다.</button>
            ) : (
              <button type='button' onClick={nextNotice}>
                {data?.data.next.title}
              </button>
            )}
          </div>
          <div>
            다음글
            {data?.data.prev === null ? (
              <button type='button'>다음글이 없습니다.</button>
            ) : (
              <button type='button' onClick={prevNotice}>
                {data?.data.prev.title}
              </button>
            )}
          </div>
        </MoveNotice>
        <RoundButton type='button' onClick={onBack}>
          목록으로
        </RoundButton>
      </TableContainer>
    </>
  );
}

export default React.memo(UserRead);
