import axios from 'axios';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { siteTitleEnglish } from '@static/common';
import { flexAlign, pixelToRem } from '@util/commonStyles';
import theme from '@util/theme';
import { addCookie } from '@util/tokenUtil';

function SuperAdminLogin() {
  const [usersId, setUsersId] = React.useState('');
  const [usersPw, setUsersPw] = React.useState('');
  const navigate = useNavigate();

  const onChangeUsersId = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUsersId(e.target.value);
    },
    [],
  );

  const onChangeUsersPw = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUsersPw(e.target.value);
    },
    [],
  );

  const onLogin = useCallback(async () => {
    if (!usersId.trim() || !usersPw.trim()) {
      alert('아이디와 비밀번호를 확인해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('email', usersId);
    formData.append('password', usersPw);

    const result = await axios.post('/superadmin/user/login', formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.data.status === 200) {
      const { token } = result.data.data;
      addCookie('token', token);
      navigate('/superadmin/home');
    }
  }, [usersId, usersPw, navigate]);

  const onKeyPress = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onLogin();
      }
    },
    [onLogin],
  );

  return (
    <>
      <LoginGlobalStyles />
      <LoginContainer>
        <div>
          <h1>{siteTitleEnglish}</h1>
          <div>
            <p>아이디</p>
            <input
              type='text'
              value={usersId}
              onChange={onChangeUsersId}
              placeholder='이메일을 입력하세요.'
            />
          </div>
          <div>
            <p>비밀번호</p>
            <input
              type='password'
              value={usersPw}
              onChange={onChangeUsersPw}
              onKeyUp={onKeyPress}
              placeholder='비밀번호를 입력하세요.'
            />
          </div>
          <button onClick={onLogin} type='button'>
            로그인
          </button>
        </div>
      </LoginContainer>
    </>
  );
}

const LoginGlobalStyles = createGlobalStyle`
  html, body, #root {
    width: 100%;
    height: 100%;
  }
`;

const LoginContainer = styled.div`
  background-color: ${theme.light.background.gray707070};
  width: 100%;
  height: 100%;

  ${flexAlign()};

  & > div {
    h1 {
      font-size: ${pixelToRem(40)};
      color: ${theme.light.color.white};
      font-weight: 900;
      margin: 0;
      margin-bottom: ${pixelToRem(70)};
      text-align: center;
    }

    & > div {
      margin-bottom: ${pixelToRem(24)};
    }

    p {
      margin: 0;
      margin-bottom: ${pixelToRem(10)};
      font-size: ${pixelToRem(20)};
      color: ${theme.light.color.white};
      margin-left: ${pixelToRem(30)};
    }

    input {
      outline: none;
      border: none;
      font-size: ${pixelToRem(17)};
      color: ${theme.light.color.gray};
      font-weight: 500;
      padding-left: ${pixelToRem(30)};
      border-radius: ${pixelToRem(13)};
      background-color: ${theme.light.background.white};
      ${flexAlign(undefined, 'flex-start')};
      width: ${pixelToRem(445)};
      height: ${pixelToRem(60)};
      font-family: sans-serif;
    }

    input[type='password'] {
      font-family: sans-serif;
    }

    button {
      outline: none;
      border: none;
      color: ${theme.light.color.gray707070};
      font-size: ${pixelToRem(20)};
      border-radius: ${pixelToRem(13)};
      width: ${pixelToRem(445)};
      height: ${pixelToRem(60)};
      margin-top: ${pixelToRem(16)};
      font-weight: 700;
    }
  }
`;

export default React.memo(SuperAdminLogin);
