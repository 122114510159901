import React from 'react';
import UserLayout from '@layout/user/home/UserLayout';
import FaqSection from '@comp/user/default/faq/FaqSection';
import SEO from '@comp/SEO';

function UserFAQ() {
  return (
    <SEO>
      <UserLayout>
        <FaqSection />
      </UserLayout>
    </SEO>
  );
}

export default React.memo(UserFAQ);
