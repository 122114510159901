import styled from 'styled-components';
import {
  btnInit,
  flexAlign,
  pixelToRem,
  pixelToRemBorder,
} from '@util/commonStyles';
import theme from '@util/theme';

export const ModalContainer = styled.div<{ hidden: boolean }>`
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  ${({ hidden }) =>
    flexAlign(undefined, undefined, undefined, hidden ? 'none' : 'flex')};
`;

export const ModalBlock = styled.div<{ flex?: number }>`
  background-color: ${theme.light.background.white};
  border-radius: ${pixelToRem(20)};
  flex: ${({ flex }) => flex ?? 0.3};
  min-width: calc(350rem / 16);
`;

export const ModalHeader = styled.div<{
  fontSize?: number;
  fontWeight?: number;
}>`
  height: ${pixelToRem(90)};
  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
  box-sizing: border-box;
  ${flexAlign(undefined, 'flex-start')};
  color: ${theme.light.color.gray707070};
  font-size: ${({ fontSize }) => pixelToRem(fontSize ?? 20)};
  padding-left: ${pixelToRem(40)};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
`;

export const ModalBody = styled.div<{ fontSize?: number; fontWeight?: number }>`
  width: 100%;
  font-size: ${({ fontSize }) => pixelToRem(fontSize ?? 25)};
  color: ${theme.light.color.gray707070};
  flex: 1;
  ${flexAlign()};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  min-height: ${pixelToRem(200)};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: calc(10rem / 16);

  & > div:first-child {
    padding-bottom: ${pixelToRem(20)};
  }
  & > div:last-child {
    padding-top: ${pixelToRem(20)};
  }

  .notice {
    width: 100%;
    &:last-child {
      border-top: ${pixelToRemBorder(1, theme.light.border.gray)};
    }
    hr {
      margin: 0 0 ${pixelToRem(16)};
    }
    ul {
      margin: 0;
      padding-left: ${pixelToRem(20)};
      &.disc {
        li {
          font-size: ${pixelToRem(14)};
          list-style: disc;
          span.navy {
            color: ${theme.light.color.navy};
          }
        }
      }
    }
  }
`;

export const ModalButtonContainer = styled.div`
  ${flexAlign()};
  box-sizing: border-box;
  border-top: ${pixelToRemBorder(1, theme.light.border.gray)};
  height: ${pixelToRem(110)};

  & > button:last-child {
    margin-left: ${pixelToRem(16)};
  }
`;

export const ModalButton = styled.button<{
  color?: string;
  borderWidth?: number;
  borderColor?: string;
  bgColor?: string;
  fontWeight?: number;
}>`
  ${btnInit(
    theme.light.color.white,
    theme.light.color.gray707070,
    { borderWidth: 0, borderColor: theme.light.border.gray707070 },
    0,
    'pointer',
  )};
  color: ${({ color }) => color ?? theme.light.color.gray707070};
  background-color: ${({ bgColor }) =>
    bgColor ?? theme.light.background.gray707070};
  font-size: ${pixelToRem(15)};
  border: ${({ borderWidth, borderColor }) =>
    pixelToRemBorder(
      borderWidth ?? 0,
      borderColor ?? theme.light.border.gray707070,
    )};
  ${flexAlign()};
  height: ${pixelToRem(40)};
  width: ${pixelToRem(160)};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
`;
