import { useState, useCallback } from 'react';

export default function useModal(): {
  hidden: boolean;
  onHidden: () => void;
} {
  const [hidden, setHidden] = useState(true);
  const onHidden = useCallback(() => {
    setHidden((p) => !p);
  }, []);

  return { hidden, onHidden };
}
