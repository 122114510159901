import React from 'react';
import UserLayout from '@layout/user/home/UserLayout';
import AutoExchange from '@comp/user/default/autoExchange/AutoExchange';
import SEO from '@comp/SEO';

function UserAutoExchange() {
  return (
    <SEO>
      <UserLayout>
        <AutoExchange />
      </UserLayout>
    </SEO>
  );
}

export default React.memo(UserAutoExchange);
