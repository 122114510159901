import React from 'react';
import type { ExchangeRequestType } from '@static/user/default/exchangeRequest';
import {
  getPinExample,
  getPinExampleHappy,
  getPinExampleBook,
} from '@static/common';
import { PinExtractContainer } from './styles';

type PinTextAreaPros = {
  activeButton: ExchangeRequestType;
  pinText: string;
  onPinText: React.ChangeEventHandler<Element>;
};

function PinTextArea({ activeButton, pinText, onPinText }: PinTextAreaPros) {
  return (
    <PinExtractContainer>
      {activeButton.id === 1 ? (
        <textarea
          onChange={onPinText}
          value={pinText}
          placeholder={getPinExample}
        />
      ) : null}

      {activeButton.id === 2 ? (
        <textarea
          onChange={onPinText}
          value={pinText}
          placeholder={getPinExampleHappy}
        />
      ) : null}
      {activeButton.id === 3 ? (
        <textarea
          onChange={onPinText}
          value={pinText}
          placeholder={getPinExampleBook}
        />
      ) : null}
    </PinExtractContainer>
  );
}

export default React.memo(PinTextArea);
