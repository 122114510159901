import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { pixelToRem } from '@util/commonStyles';
import theme from '@util/theme';

export const SidaBarContainer = styled.aside`
  min-width: ${pixelToRem(200)};
  width: ${pixelToRem(200)};
  background-color: ${theme.light.background.white};
`;

export const SideBarList = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  font-weight: 700;
`;

export const SidebarMenu = styled(Link)<{ isselected: string }>`
  text-decoration: none;
  padding: 0;
  font-size: ${pixelToRem(20)};
  color: ${({ isselected }) =>
    isselected === 'true'
      ? theme.light.color.black
      : theme.light.color.gray707070};
  margin-bottom: ${pixelToRem(20)};
`;
