import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { seoHelmet } from '@static/helmet';

type SEOProps = {
  children: React.ReactNode;
};

// const baseURL = 'https://www.cashpin.net';

function SEO({ children }: SEOProps) {
  const [keys, setKeys] = React.useState<string[]>(['user', 'main']);
  const { pathname } = useLocation();

  React.useEffect(() => {
    const local = pathname.split('/').filter(Boolean).slice(0, 2);
    if (!local.length) {
      setKeys(['user', 'main']);
    } else {
      setKeys([...local]);
    }
  }, [pathname]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>캐시핀 CASH PIN</title>
        <meta httpEquiv='author' content='caramella Inc.' />
        <meta httpEquiv='publisher' content='caramella Inc.' />
        <meta name='copyright' content='caramella Inc.' />
        <link rel='canonical' href='https://www.cashpin.net' />
        <meta name='url' content='https://www.cashpin.net' />
        <meta httpEquiv='content-language' content='ko' />
        <meta name='keywords' content={seoHelmet[keys[0]][keys[1]]?.keywords} />
        <meta
          name='description'
          content={seoHelmet[keys[0]][keys[1]]?.keywords}
        />
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

export default SEO;
