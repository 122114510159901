import React from 'react';
import { useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { AdminContainer, DashboardHeader } from '@comp/admin/styles';
import SideBar from '@comp/superAdmin/sidebar/SideBar';
import { ContentInner, DashboardInner } from '@comp/superAdmin/styles';
import SuperAdminHeader from '@comp/superAdmin/SuperAdminHeader';
import { adminMenuText } from '@static/admin/admin';
import theme from '@util/theme';

const GlobalStyles = createGlobalStyle`
  html, body, #root {
    min-height: 100%;
  }

  div.swal-modal > div.swal-footer .swal-button-container,
  div.swal-modal > div.swal-footer button.swal-button,
  div.swal-modal > div.swal-footer button.swal--danger {
    &, &:hover, &:active {
      background-color: ${theme.light.background.gray707070};
      color: ${theme.light.color.white};
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  div.swal-overlay.swal-overlay--show-modal div.swal-modal > div.swal-icon.swal-icon--error {
    transform: scale(0.7);
    margin: 20px auto;
  }
`;

type HomeLayoutProps = {
  children: React.ReactNode;
  padding?: number;
};

function SuperLayout({ children, padding }: HomeLayoutProps) {
  const location = useLocation();
  return (
    <>
      <GlobalStyles />
      <AdminContainer>
        <SuperAdminHeader />
        <DashboardHeader>
          {
            adminMenuText.find((c) => location.pathname.startsWith(c.path))
              ?.name
          }
        </DashboardHeader>
        <DashboardInner>
          <SideBar />
          <ContentInner padding={padding}>{children}</ContentInner>
        </DashboardInner>
      </AdminContainer>
    </>
  );
}

export default React.memo(SuperLayout);
