import React, { useCallback, useState } from 'react';
import { AiOutlineUserAdd, AiOutlineUserDelete } from 'react-icons/ai';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useCheckbox from '@hook/useCheckbox';
import useModal from '@hook/useModal';
import { getfetcher, postFetcher, Res } from '@util/fetcher';
import { getCookie } from '@util/tokenUtil';
import { mutationKeys, queryKeys } from '@util/queries';
import Modal from '@modal/Modal';
import Pagination from '../../Pagination';
import {
  AccountListContainer,
  AdminTableContainer,
  AdminManageButtonContainer,
  AdminManageButton,
  AdminTable,
  AdminTableCheckboxContainer,
  AdminTableHead,
  AdminTableBody,
} from './styles';
import type { AccountListType } from './types';

function AccountList() {
  const token = (getCookie(document.cookie, 'token') as string) || '';
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [onCheckAll, onCheckList, checklist, isCheckAll] = useCheckbox();
  const qc = useQueryClient();
  const modal = useModal();
  const [removeComment, setRemoveComment] =
    useState('정말로 삭제하시겠습니까?');

  const [isVisibleModalButton, setIsVisibleModalButton] =
    useState<boolean>(true);

  const onAddAdmin = useCallback(() => {
    navigate('/superadmin/account/create');
  }, [navigate]);

  const onRemoveAdmin = useCallback(() => {
    const { onHidden } = modal;
    if (checklist.length < 1) {
      setRemoveComment('선택한 사업자가 없습니다!');
    } else if (checklist.length > 1) {
      setRemoveComment('삭제는 하나씩만 삭제 가능합니다.');
    } else {
      setRemoveComment('정말로 삭제하시겠습니까?');
    }
    onHidden();
  }, [checklist.length, modal]);

  const { data: list, refetch } = useQuery<Res<AccountListType>>(
    [queryKeys.accountManagerRead, page, token],
    () =>
      getfetcher(`/superadmin/publish?page=${page}&pageSize=10`, '', false, {
        headers: { token },
      }),
    {
      onSuccess: (result: Res<AccountListType>) => {
        setTotalCount(result.data.totalCount ?? 0);
      },
      enabled: !!token,
    },
  );

  const { mutate: onRemove } = useMutation<any, any, FormData>(
    [mutationKeys.superadmin.deleteAccount, token],
    (variable) =>
      postFetcher(`/superadmin/account/delete`, '', false, variable, {
        headers: {
          token,
        },
      }),
    {
      onError: () => {
        setIsVisibleModalButton(false);
        setRemoveComment(`삭제하는데 실패했습니다.\n자동으로 창이 닫힙니다.`);
        setTimeout(() => {
          modal.onHidden();
        }, 2000);
        setTimeout(() => {
          setIsVisibleModalButton(true);
        }, 3000);
      },
      onSuccess: (_, param) => {
        qc.setQueryData<Res<AccountListType>>(
          [queryKeys.accountManagerRead, page, token],
          (p) => {
            const id = param.get('id');
            const defaultValue = {
              status: 200,
              msg: '',
              data: {
                totalCount: 0,
                admins: [],
              },
            };

            if (!p || !id) {
              return defaultValue;
            }

            const value = {
              status: 200,
              msg: '',
              data: {
                totalCount: totalCount - 1,
                admins: p.data.admins.filter((k) => k.id !== +id),
              },
            };
            setTotalCount((c) => c - 1);
            return value;
          },
        );
        refetch();
        modal.onHidden();
      },
    },
  );

  const onRemoveAccount = useCallback(() => {
    const formData = new FormData();
    const [idList] = checklist;
    const extractArray = idList.split('superadmin-checkboxes-');
    const targetId = extractArray[extractArray.length - 1];
    formData.append('siteId', targetId);
    onRemove(formData);
  }, [checklist, onRemove]);

  const onLookThis = (id: number) => () => {
    navigate(`/superadmin/account/manage/${id}`);
  };

  return (
    <>
      <Modal
        {...modal}
        bodyText={removeComment}
        onClick={onRemoveAccount}
        flex={0.5}
        isVisibleButton={isVisibleModalButton}
      />
      <AccountListContainer>
        <h2>어드민 계정 관리</h2>
        <AdminTableContainer>
          <AdminManageButtonContainer>
            <AdminManageButton onClick={onAddAdmin}>
              <AiOutlineUserAdd size={24} />
              <span>사업자 등록</span>
            </AdminManageButton>
            <AdminManageButton onClick={onRemoveAdmin}>
              <AiOutlineUserDelete size={24} />
              <span>사업자 삭제</span>
            </AdminManageButton>
          </AdminManageButtonContainer>
          <AdminTable>
            <div className='all-check-element'>
              <AdminManageButton>파일 엑셀 다운</AdminManageButton>
            </div>
            <AdminTableHead
              isOverflow={(list && list.data.admins.length > 10) ?? false}
            >
              <AdminTableCheckboxContainer>
                <input
                  type='checkbox'
                  checked={isCheckAll}
                  onChange={onCheckAll}
                  id='superadmin-checkbox-all'
                />
              </AdminTableCheckboxContainer>
              <div className='grid-head'>
                <div>사업자 스토어명</div>
                <div>사업자명</div>
                <div>아이디</div>
                <div>사이트 주소</div>
                <div>연락처</div>
              </div>
            </AdminTableHead>
            <div className='table-body'>
              {list && list.data.admins.length > 0
                ? list.data.admins.map(
                    ({ id, name, ceoName, phone, email, url }) => (
                      <AdminTableBody isOverflow={false} key={id}>
                        <AdminTableCheckboxContainer>
                          <input
                            type='checkbox'
                            checked={checklist.includes(
                              `superadmin-checkboxes-${id}`,
                            )}
                            onChange={onCheckList}
                            id={`superadmin-checkboxes-${id}`}
                            className='superadmin-checkboxes'
                          />
                        </AdminTableCheckboxContainer>
                        <div className='grid-body'>
                          <div
                            onClick={onLookThis(id)}
                            style={{ cursor: 'pointer' }}
                          >
                            {name}
                          </div>
                          <div
                            onClick={onLookThis(id)}
                            style={{ cursor: 'pointer' }}
                          >
                            {ceoName}
                          </div>
                          <div
                            onClick={onLookThis(id)}
                            style={{ cursor: 'pointer' }}
                          >
                            {email}
                          </div>
                          <div
                            onClick={onLookThis(id)}
                            style={{ cursor: 'pointer' }}
                          >
                            {url}
                          </div>
                          <div
                            onClick={onLookThis(id)}
                            style={{ cursor: 'pointer' }}
                          >
                            {phone}
                          </div>
                        </div>
                      </AdminTableBody>
                    ),
                  )
                : null}
            </div>
          </AdminTable>
        </AdminTableContainer>
        <Pagination page={page} setPage={setPage} totalCount={totalCount} />
      </AccountListContainer>
    </>
  );
}

export default React.memo(AccountList);
