import React, { useEffect } from 'react';
import {
  FcClock,
  FcMoneyTransfer,
  FcPositiveDynamic,
  FcDiploma1,
} from 'react-icons/fc';
import { siteTitle, subStieTitle } from '@static/common';
import {
  BottomBannerContainer,
  BottomImageContainer,
  BottomImageWrapper,
  BottomBannerWrapper,
} from './styles';

function BottomBanner() {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    const isAndroid = navigator.userAgent.toLowerCase().includes('android');
    const isiPhone = navigator.userAgent.toLowerCase().includes('iphone');
    const isiPad = navigator.userAgent.toLowerCase().includes('ipad');
    if (isAndroid || isiPhone || isiPad) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <BottomBannerWrapper>
      <BottomBannerContainer>
        <div className='bottom-banner-inner'>
          <h2>
            안전하고 빠른&nbsp;
            {siteTitle}
          </h2>
          <h4>{subStieTitle}</h4>
        </div>
      </BottomBannerContainer>
      <BottomImageWrapper>
        <BottomImageContainer>
          <div className='bottom-banner-image-item'>
            <FcClock size={isMobile ? 90 : 45} />
            <h3>24시간 자동화 시스템</h3>
            <p>365일 24시간 자동화 시스템으로</p>
            <p>빠르고 안전하게 입금</p>
          </div>
          <div className='bottom-banner-image-item'>
            <FcMoneyTransfer size={isMobile ? 90 : 45} />
            <h3>1분이내 입금</h3>
            <p>모바일 상품권, 교환권 상관없이</p>
            <p>신청 즉시 입금까지 1분이내 완료</p>
          </div>
          <div className='bottom-banner-image-item'>
            <FcPositiveDynamic size={isMobile ? 90 : 45} />
            <h3>최고가 매입</h3>
            <p>최저 수수로, 최고가로</p>
            <p>고객님의 상품권을 매입!</p>
          </div>
          <div className='bottom-banner-image-item'>
            <FcDiploma1 size={isMobile ? 90 : 45} />
            <h3>거래 안심 서비스</h3>
            <p>가장 안전하고 신뢰받는</p>
            <p>티켓 거래소 캐시핀</p>
          </div>
        </BottomImageContainer>
      </BottomImageWrapper>
    </BottomBannerWrapper>
  );
}

export default React.memo(BottomBanner);
