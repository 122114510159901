// @ts-nocheck
import React, { useCallback, useState } from 'react';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';
import axios from 'axios';
import {
  bankGroup,
  depositText,
  exchangeRequestExplainText,
  exchangeRequestObj,
  feeExplain,
  pinText,
  sliceEndSwitcher,
} from '@static/user/default/exchangeRequest';
import type { ExchangeRequestType } from '@static/user/default/exchangeRequest';
import theme from '@util/theme';
import useInputName from '@hook/useInputName';
import RoundButton from '../../../RoundButton';
import {
  ExchangeRequestContainer,
  ExchangeRequestInner,
  ButtonContainer,
  ExchangeRequestExplain,
  ExchangeRequestWrapper,
  BuyFee,
  PinContainer,
  PinHeader,
  PinWrapper,
  DepositContainer,
  DepositSelectContainer,
  DepositCheckContainer,
  ExchangeButtonContainer,
  OptimizedBrowserNotification,
} from './styles';
import PinInput from './PinInput';
import PinTextArea from './PinTextArea';

const initialPin = {
  '1': { id: 1, data: '', issue: '', auth: '' },
  '2': { id: 2, data: '', issue: '', auth: '' },
  '3': { id: 3, data: '', issue: '', auth: '' },
  '4': { id: 4, data: '', issue: '', auth: '' },
  '5': { id: 5, data: '', issue: '', auth: '' },
};

const initialBank = {
  name: '',
  depositor: '',
  accountNumber: '',
  phone: '',
};

function ExchangeRequest() {
  const navigate = useNavigate();
  const onReturn = () => {
    navigate('/');
    window.location.reload();
  };

  const [activeButton, setActiveButton] = useState<ExchangeRequestType>(
    exchangeRequestObj[0],
  );

  const [bank, setBank] = useState(initialBank);

  const [pin, onPin, onIssue, onAuth, setPin] = useInputName(
    undefined,
    sliceEndSwitcher(activeButton.id),
  );

  const [pinCount, setPinCount] = useState<number>(Object.keys(pin).length);
  const [remember, setRemember] = useState<boolean>(false);
  const [pinTextarea, setPinTextarea] = useState(``);
  const onPinTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPinTextarea(e.target.value);
  };

  const onBank = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setBank((p) => ({ ...p, [e.target.name]: e.target.value }));
    },
    [],
  );

  const onRemember = useCallback(() => {
    setRemember((p) => !p);
  }, []);

  const onActive = useCallback(
    (id: number) => () => {
      setActiveButton(exchangeRequestObj[id - 1]);
    },
    [],
  );

  const onAddPinInput = useCallback(() => {
    const addRef = pinCount + 1;
    setPin((p) => ({
      ...p,
      [addRef]: { id: addRef, data: '', issue: '', auth: '' },
    }));
    setPinCount((p) => p + 1);
  }, [pinCount, setPin]);

  const onExtract = useCallback(
    (activeButtonId: number) => () => {
      if (!pinTextarea.trim()) {
        swal('에러', '추출할 핀 번호가 없습니다!', 'error');
      }
      const re = [
        /[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4,6}/g,
        /[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}/g,
        /[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}/g,
      ];
      const issue = /[0-9]{8}/;
      const matched = pinTextarea.match(re[activeButtonId - 1]);
      const issueMatched = pinTextarea.match(issue);

      if (!matched) {
        swal('에러', '추출된 핀 번호가 없습니다!', 'error');
        return;
      }
      if (activeButtonId === 2 || activeButtonId === 3) {
        if (!issueMatched) {
          swal('에러', '추출할 인증번호가 없습니다!', 'error');
          return;
        }
      }
      const obj = Object.values(pin);
      let index = -1;
      for (const k in obj) {
        if (!obj[k] || !obj[k].data) {
          index = +k;
          break;
        }
      }
      if (index === -1) {
        index = obj.length + 1;
      } else {
        index += 1;
      }

      const extracted = matched.reduce((prev, acc) => {
        const newObj = { id: index, data: acc, issue: '', auth: '' };
        if (typeof pin[index] !== 'object') {
          pin[index] = newObj;
        } else {
          pin[index].data = acc;
        }
        if (activeButtonId === 2) {
          pin[index].data = acc.slice(0, 19);
          pin[index].issue = issueMatched?.values().next().value;
        }
        if (activeButtonId === 3) {
          pin[index].data = acc.slice(0, 19);
          pin[index].auth = acc.slice(20);
        }
        index += 1;
        return prev;
      }, pin);

      setPin({ ...pin, ...extracted });
      setPinTextarea('');
    },
    [pin, pinTextarea, setPin],
  );

  const onRequestExchange = useCallback(
    // async (activeButtonId: number) => {
    async () => {
      // swal('서비스 점검중입니다.', '이용에 불편을 드려 죄송합니다.', 'warning');
      /* const existData = Object.values(pin)
        .map((c) => {
          if (!c.data) {
            return;
          }
          if (activeButtonId === 2 && !c.issue) {
            return;
          }
          if (activeButtonId === 3 && !c.auth) {
            return;
          }
          return c;
        })
        .filter(Boolean);

      if (existData.length === 0) {
        swal('오류!', '데이터를 입력해주세요!', 'error');
        return;
      }
      //* 요기서부터 수정...
      let isValid = true;
      existData.forEach((a) => {
        const endNum04 = /^\d{4}-\d{4}-\d{4}-\d{4}$/;
        const endNum06 = /^\d{4}-\d{4}-\d{4}-\d{6}$/;
        if (a && a.data && !(endNum04.test(a.data) || endNum06.test(a.data))) {
          isValid = false;
        }
      });
      if (!isValid) {
        swal('오류!', '유효하지 않은 핀번호가 존재합니다.', 'error');
        return;
      }

      if (
        bank.name.trim().length < 1 ||
        bank.depositor.trim().length < 1 ||
        bank.accountNumber.trim().length < 1 ||
        bank.phone.trim().length < 1
      ) {
        swal('오류!', '입금정보를 정확히 입력해주세요!', 'error');
        return;
      }

      try {
        const pinNumberStringArray = existData
          .filter((f) => f && f.data.trim().length > 0 && f?.data.includes('-'))
          .map((r) => r?.data.trim());

        if (!pinNumberStringArray) {
          swal('오류!', '데이터를 입력해주세요!', 'error');
          return;
        }

        if (pinNumberStringArray.length <= 0) {
          swal('오류!', '데이터를 입력해주세요!', 'error');
          return;
        }

        const {
          data: {
            data: {
              site: { id },
            },
          },
        } = await axios.get(`common?url=${window.location.origin}`);
        const result = await axios.post(`/exchange/${id}`, {
          name: bank.depositor.trim(),
          phone: bank.phone.trim().replaceAll('-', ''),
          account: bank.accountNumber.trim().replaceAll('-', ''),
          bankName: bank.name,
          voucherId: activeButtonId,
          pinNumber: pinNumberStringArray,
        });
        if (result.data.status === 200) {
          swal('성공!', '곧 계좌로 입금될 예정입니다!', 'success');

          return;
        }
        throw new Error('실패!');
      } catch (error) {
        swal('오류!', '서버 에러입니다. 담당자에게 문의 해주세요!', 'error');
      } finally {
        setPin(initialPin);
        setPinTextarea('');
        setBank(initialBank);
      }
    },
    [pin, bank, setPin], */
      // [],
    },
    [],
  );

  return (
    <ExchangeRequestContainer>
      <ExchangeRequestInner>
        <ExchangeRequestWrapper>
          <ExchangeRequestExplain>
            <h3>상품권 현금교환 신청</h3>
            {exchangeRequestExplainText.map((value, index) => (
              <p key={index}>{value}</p>
            ))}
          </ExchangeRequestExplain>
          <div className='exchange-request-warning'>
            ※ 현재는 컬쳐랜드 문화상품권만 교환 가능 합니다!
          </div>
          <ButtonContainer>
            {exchangeRequestObj.map((value) => {
              const { id, menu } = value;
              const isActive = value.id === activeButton.id;
              const { color: fontColor, background: bgColor } = theme.light;
              return (
                <RoundButton
                  key={id}
                  bgColor={isActive ? bgColor.navy : bgColor.white}
                  fontColor={isActive ? fontColor.white : fontColor.white}
                  borderWidth={isActive ? 0 : 1}
                  fontWeight={700}
                  fontSize={20}
                  type='button'
                  onClick={onActive(id)}
                >
                  {menu}
                </RoundButton>
              );
            })}
          </ButtonContainer>
        </ExchangeRequestWrapper>
        <ExchangeRequestWrapper>
          <PinContainer>
            <PinWrapper flex={1} marginRight={56}>
              <PinHeader>{pinText.left.header}</PinHeader>
              <div className='left-subheader'>
                {pinText.left.subHeader.map((value) => (
                  <p key={value}>{value}</p>
                ))}
              </div>
              <PinTextArea
                activeButton={activeButton}
                pinText={pinTextarea}
                // onPinText={onPinTextarea}
                onPinText={() => {}}
              />
              <div className='exchange-request-button-align'>
                <RoundButton
                  bgColor={theme.light.background.navy}
                  fontColor={theme.light.color.white}
                  borderWidth={0}
                  fontSize={20}
                  isHover
                  hoverColor={theme.light.color.white}
                  hoverBgColor={theme.light.background.gray707070}
                  hoverBorderWidth={0}
                  // onClick={onExtract(activeButton.id)}
                  onClick={undefined}
                >
                  핀번호 추출
                </RoundButton>
              </div>
            </PinWrapper>
            <PinWrapper flex={1.588}>
              <PinHeader>{pinText.right.header}</PinHeader>
              <div>
                {pinText.right.subHeader[activeButton.id - 1].content.map(
                  (value) => (
                    <p key={value}>{value}</p>
                  ),
                )}
              </div>
              {Object.values(pin).map((value) => (
                <PinInput
                  key={value.id}
                  name={value.id.toString()}
                  activeButton={activeButton}
                  orderNumber={value.id}
                  pin={value.data}
                  onPin={onPin(String(value.id))}
                  issueNumber={value.issue}
                  onIssueNumber={onIssue(String(value.id))}
                  authNumber={value.auth}
                  onAuthNumber={onAuth(String(value.id))}
                />
              ))}
              <div className='exchange-request-button-align'>
                <RoundButton
                  borderWidth={0}
                  borderRadius={50}
                  borderColor={theme.light.border.gray707070}
                  color={theme.light.color.gray707070}
                  fontSize={20}
                  bgColor={theme.light.background.navy}
                  fontColor={theme.light.color.white}
                  isHover
                  hoverBorderWidth={0}
                  hoverBorderColor={theme.light.border.gray707070}
                  hoverColor={theme.light.color.white}
                  hoverBgColor={theme.light.background.gray707070}
                  // onClick={onAddPinInput}
                >
                  핀번호 추가
                </RoundButton>
              </div>
            </PinWrapper>
          </PinContainer>
        </ExchangeRequestWrapper>
        <ExchangeRequestWrapper>
          <DepositContainer>
            <h3>{depositText.header}</h3>
            <BuyFee>
              <h3>{feeExplain.header}</h3>
              {feeExplain.contents.map((value) => (
                <p key={value}>{value}</p>
              ))}
            </BuyFee>
            {depositText.subHeader.map((item, idx) => (
              <p key={`depositText-${idx}`}>{item}</p>
            ))}
            <DepositSelectContainer>
              <div>
                <p>은행명</p>
                <select
                  title='bank'
                  name='name'
                  // value={bank.name}
                  value=''
                  onChange={() => {}}
                >
                  {bankGroup.map((item) => (
                    <option value={item.bank} key={item.value}>
                      {item.bank}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <p>계좌번호</p>
                <input
                  placeholder='(- 제외) 계좌번호 입력'
                  name='accountNumber'
                  type='tel'
                  // value={bank.accountNumber}
                  // onChange={onBank}
                  readOnly
                />
              </div>
              <div>
                <p>예금주명</p>
                <input
                  placeholder='예금주명 입력'
                  name='depositor'
                  value={bank.depositor}
                  // onChange={onBank}
                  readOnly
                />
              </div>
              <div>
                <p>연락처</p>
                <input
                  placeholder='(- 제외) 연락처 입력'
                  name='phone'
                  type='tel'
                  // value={bank.phone}
                  // onChange={onBank}
                  readOnly
                />
              </div>
            </DepositSelectContainer>
            <DepositCheckContainer>
              <label htmlFor='deposit-check' />
              <input
                type='checkbox'
                hidden
                id='deposit-check'
                checked={remember}
                readOnly
                // onChange={onRemember}
                onChange={undefined}
              />
              <p>입금은행 정보 기억하기</p>
            </DepositCheckContainer>
          </DepositContainer>
        </ExchangeRequestWrapper>
        <ExchangeRequestWrapper isNotBorder>
          <ExchangeButtonContainer>
            <RoundButton
              borderWidth={0}
              borderRadius={50}
              borderColor={theme.light.border.gray707070}
              bgColor={theme.light.background.navy}
              fontSize={20}
              fontColor={theme.light.color.white}
              isHover
              hoverBorderWidth={0}
              hoverBorderColor={theme.light.border.gray707070}
              hoverColor={theme.light.color.white}
              hoverBgColor={theme.light.background.gray707070}
              style={{ marginTop: '3rem' }}
              // onClick={() => onRequestExchange(activeButton.id)}
              // onClick={() => onRequestExchange()}
              onClick={onReturn}
            >
              교환신청
            </RoundButton>
          </ExchangeButtonContainer>
          <OptimizedBrowserNotification>
            ※ 캐시핀은 크롬에 최적화되어 있습니다.
          </OptimizedBrowserNotification>
        </ExchangeRequestWrapper>
      </ExchangeRequestInner>
    </ExchangeRequestContainer>
  );
}

export default React.memo(ExchangeRequest);
