import { css, FlattenSimpleInterpolation } from 'styled-components';
import theme from './theme';

export const pixelToRem = (pixel: number = 16): string => `${pixel / 16}rem`;
export const pixelToRemBorder = (
  pixel: number = 16,
  borderColor: string = '#000',
): string => `${pixel / 16}rem solid ${borderColor}`;

export type StrOrNum = string | number;

export const flexAlign = (
  dir: string = 'row',
  justify: string = 'center',
  align: string = 'center',
  display: string = 'flex',
): FlattenSimpleInterpolation => css`
  display: ${display};
  flex-direction: ${dir};
  justify-content: ${justify};
  align-items: ${align};
  box-sizing: border-box;
`;

type Border = {
  borderWidth: number;
  borderColor: string;
};

export const btnInit = (
  color: string = '#000',
  bgColor: string = '#fff',
  { borderWidth, borderColor }: Border = {
    borderWidth: 0,
    borderColor: '#000',
  },
  borderRadius: StrOrNum = 0,
  cursor: string = 'pointer',
): FlattenSimpleInterpolation => css`
  outline: none;
  box-sizing: border-box;
  color: ${color};
  background-color: ${bgColor};
  border: ${`${borderWidth}px solid ${borderColor}`};
  border-radius: ${borderRadius};
  cursor: ${cursor};
`;

type TemplateGap = {
  rowGap: StrOrNum;
  columnGap: StrOrNum;
};

export const gridInit = (
  gridTemplateColumns: string = 'repeat(3, 1fr)',
  { rowGap = 0, columnGap = 0 }: TemplateGap,
): FlattenSimpleInterpolation => css`
  display: grid;
  grid-template-columns: ${gridTemplateColumns};
  row-gap: ${rowGap};
  column-gap: ${columnGap};
`;

export const inputChecked = (checkedColor?: string) => css`
  input[type='checkbox'] {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }
  input[type='checkbox']:checked {
    border: 1px solid ${checkedColor ?? theme.light.border.white};
    background-color: ${checkedColor ?? theme.light.background.navy};
  }
  input[type='checkbox']:checked::before,
  input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #fff;
  }
  input[type='checkbox']:checked::before {
    top: 7.5px;
    left: 6px;
    height: 6px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  input[type='checkbox']:checked::after {
    top: 5px;
    left: 11px;
    height: 9px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const layoutContainer = () => css``;
