import React from 'react';
import { CommonBannerContainer } from './styles';

type CommonBannerProps = {
  title: string;
  text: string;
};

function CommonBanner({ title, text }: CommonBannerProps) {
  const size = { width: window.innerWidth || document.body.clientWidth };

  return (
    <CommonBannerContainer>
      <div className='banner-text'>
        <h1 className='title'>
          <div className='mobile-line' />
          {title}
          <div className='line' />
        </h1>
        {size.width < 650 ? (
          <>
            <p className='sub-title'>{text.slice(0, 20)}</p>
            <p className='sub-title'>{text.slice(22)}</p>
          </>
        ) : (
          <p className='sub-title'>{text}</p>
        )}
      </div>
    </CommonBannerContainer>
  );
}

export default React.memo(CommonBanner);
