import React from 'react';
import { CgMenuGridO } from 'react-icons/cg';
import { headerText } from '@static/user/default/header';
import logo from '@static/images/icon.png';
import {
  HeaderContainer,
  HeaderInner,
  HeaderMenuContainer,
  MenuElement,
  LinkRadius,
  MenuElementLogo,
} from './styles';

function Header() {
  const onOpen = () => {
    const nav = document.getElementById('drawer-navigation');
    if (nav) {
      nav.style.transform = 'translateX(0)';
    }
  };

  return (
    <HeaderContainer className='header-container'>
      <HeaderInner>
        <div
          className='user-header-mobile-menu'
          onClick={onOpen}
          role='presentation'
        >
          <CgMenuGridO size={24} />
        </div>
        <MenuElementLogo to='/'>
          <img src={logo} alt='logo' />
          CASH PIN
        </MenuElementLogo>
        <HeaderMenuContainer>
          {headerText.map((value) => (
            <MenuElement key={value.path} to={value.path}>
              {value.text}
            </MenuElement>
          ))}
        </HeaderMenuContainer>
        {/* <LinkRadius to='/user/mylist'>내 교환내역</LinkRadius> */}
        <div className='user-header-dummy' />
      </HeaderInner>
    </HeaderContainer>
  );
}

export default React.memo(Header);
