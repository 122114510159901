import React, { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';

import useCheckbox from '@hook/useCheckbox';

import { siteAccountColumn } from '@static/admin/admin';

import switchVoucherType from '@util/admin/switchVoucherType';
import { getfetcher, postFetcher } from '@util/fetcher';
import { mutationKeys, queryKeys } from '@util/queries';
import theme from '@util/theme';
import { getCookie } from '@util/tokenUtil';
import RoundButton from '../../RoundButton';
import { AccountSyncListContainer } from './styles';
import type { SiteSyncDelete, SiteSyncList } from './types';

function AccountSyncList() {
  const token = (getCookie(document.cookie, 'token') as string) || '';
  const [onCheckAll, onCheckList, checklist, isCheckAll] = useCheckbox();
  const navigate = useNavigate();
  const params = useParams();
  const qc = useQueryClient();

  const { data: list } = useQuery<SiteSyncList>(
    [queryKeys.superadmin.syncSites, params.id, token],
    () =>
      getfetcher(`/superadmin/voucher/account/${params.id}`, '', false, {
        headers: { token },
      }),
    {
      enabled: !!token,
    },
  );

  const { mutate: onDeleteSync } = useMutation<SiteSyncDelete, any, FormData>(
    [mutationKeys.superadmin.deleteSyncSite, params.id, token],
    (data) =>
      postFetcher(
        `/superadmin/voucher/account/delete/${params.id}`,
        '',
        false,
        data,
        {
          headers: { token },
        },
      ),
    {
      onError: () => {
        swal({
          title: '에러',
          text: '서버와의 연결이 원활하지 않습니다.',
          icon: 'error',
          dangerMode: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: {
            confirm: {
              closeModal: true,
              text: '확인',
            },
          },
        });
      },
    },
  );

  const onDelete = useCallback(
    (id: number) => async () => {
      const alertResult = await swal({
        title: '알림',
        text: '정말 삭제하시겠어요?',
        dangerMode: true,
        icon: 'error',
        buttons: ['취소', '확인'],
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
      if (alertResult) {
        const formData = new FormData();
        formData.append('id', id.toString());
        onDeleteSync(formData);
        qc.setQueryData<SiteSyncList>(queryKeys.superadmin.syncSites, (k) => {
          if (!k) {
            return {
              status: 200,
              msg: '',
              data: {
                voucherAccount: [],
              },
            };
          }
          return {
            ...k,
            data: {
              voucherAccount: k?.data.voucherAccount.filter((c) => c.id !== id),
            },
          };
        });
      }
    },
    [onDeleteSync, qc],
  );

  const onCreate = () => {
    navigate('/superadmin/account/site/create', { state: { id: params.id } });
  };

  return (
    <AccountSyncListContainer>
      <div className='sync-list-header'>
        <h2>연동 사이트 현황</h2>
        <button type='button' onClick={onCreate}>
          연동 사이트 등록
        </button>
      </div>
      <div className='sync-list-wrapper'>
        <div className='sync-list-head'>
          <div>
            <input type='checkbox' checked={isCheckAll} onChange={onCheckAll} />
          </div>
          {siteAccountColumn.map((c) => (
            <div key={c.id}>{c.text}</div>
          ))}
        </div>
        {list && list.data && list.data.voucherAccount
          ? list.data.voucherAccount.map(
              ({ id, voucherType, username, url, isDisabled }) => (
                <div className='sync-list-body' key={id}>
                  <div>
                    <input
                      type='checkbox'
                      onChange={onCheckList}
                      checked={checklist.includes(
                        `superadmin-checkboxes-${id}`,
                      )}
                      id={`superadmin-checkboxes-${id}`}
                      className='superadmin-checkboxes'
                    />
                  </div>
                  <div>
                    <RoundButton
                      bgColor={
                        isDisabled === 0
                          ? theme.light.background.lightGreen
                          : theme.light.background.lightRed
                      }
                      fontSize={13}
                      borderWidth={0}
                      cursor='initial'
                      fontColor={theme.light.color.white}
                      fontWeight={700}
                      borderRadius={16}
                      width={70}
                      height={30}
                      isHover={false}
                      noUseMediaQuery
                    >
                      {isDisabled === 0 ? '연동중' : '미연동중'}
                    </RoundButton>
                  </div>
                  <div>
                    {switchVoucherType(voucherType).replace(' 문화상품권', '')}
                  </div>
                  <div>{username}</div>
                  <div>{url}</div>
                  <div>
                    <button type='button' onClick={onDelete(id)}>
                      삭제
                    </button>
                  </div>
                </div>
              ),
            )
          : null}
      </div>
    </AccountSyncListContainer>
  );
}

export default React.memo(AccountSyncList);
