import React from 'react';
import { useQuery } from 'react-query';
import { FiPlusSquare } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import theme from '@util/theme';
import { getfetcher } from '@util/fetcher';
import { ListResponse } from '@static/user/default/commonTypes';
import { queryKeys } from '@util/queries';
import {
  NoticeContainer,
  NoticeItem,
  NoticeItemContainer,
  BodyLoadingOrEmpty,
} from './styles';

function UserNotice() {
  const { data, isLoading } = useQuery<ListResponse>(
    [queryKeys.user.noitceList],
    () => getfetcher(`/main/`, '', true),
    {
      retry: 5,
      refetchInterval: 1000 * 100,
    },
  );

  return (
    <NoticeContainer>
      <div className='board-header'>
        <div className='board-notice'>
          <div>
            <div>
              <h3 className='board-notice-header'>공지사항</h3>
              <div className='border-content-mobile'>
                {data && data.data.listNotice.length > 0 ? (
                  data.data.listNotice.slice(0, 1).map((item) => (
                    <div key={item.id}>
                      <Link to={`/user/notice/${item.id}`}>
                        <span>[캐시핀] {item.title}</span>
                      </Link>
                    </div>
                  ))
                ) : (
                  <BodyLoadingOrEmpty>
                    {isLoading ? '로딩중입니다' : '공지사항이 없습니다'}
                  </BodyLoadingOrEmpty>
                )}
              </div>
            </div>
          </div>
        </div>
        <Link to='/user/notice'>
          <FiPlusSquare
            className='plus-icon'
            size={33}
            color={theme.light.color.gray}
          />
        </Link>
      </div>
      <NoticeItemContainer>
        {data && data.data.listNotice.length > 0 ? (
          data.data.listNotice.slice(0, 3).map((value) => (
            <NoticeItem key={value.id} to={`/user/notice/${value.id}`}>
              <div className='notice-elilpsis-box'>
                <div className='notice-elilpsis'>
                  <span className='notice-prefix'>[캐시핀] {value.title}</span>
                </div>
              </div>

              <div className='notice-date'>
                <div className='notice-vertical-bar' />
                <div>
                  {new Date(value.createdAt)
                    .toLocaleDateString('ko-KR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                    .replace(/\s+/g, '')
                    .slice(0, -1)}
                </div>
              </div>
            </NoticeItem>
          ))
        ) : (
          <BodyLoadingOrEmpty>
            {isLoading ? '로딩중입니다' : '공지사항이 없습니다'}
          </BodyLoadingOrEmpty>
        )}
      </NoticeItemContainer>
    </NoticeContainer>
  );
}

export default React.memo(UserNotice);
