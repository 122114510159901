import React, { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { siteChoiceButtons } from '@static/admin/admin';
import { switchVoucherTypeToStr } from '@util/admin/switchVoucherType';
import { postFetcher, Res } from '@util/fetcher';
import { getCookie } from '@util/tokenUtil';
import { mutationKeys } from '@util/queries';
import { SelectionLabel, TwoButtons } from '../../admin/styles';
import { SiteInfoContainer, SiteInfoContent } from './styles';

type SiteInfoCreateProps = {};

function SiteInfoCreate({}: SiteInfoCreateProps) {
  const token = (getCookie(document.cookie, 'token') as string) || '';
  const navigate = useNavigate();
  const [siteInfo, setSiteInfo] = useState({
    site: 0,
    email: '',
    password: '',
  });
  const location = useLocation();

  const { mutate: onUpdateInfo } = useMutation<
    Res<{}>,
    { status: number; msg: string },
    FormData
  >(
    [mutationKeys.superadmin.createSite, (location as any).state.id, token],
    (data) =>
      postFetcher(
        `/superadmin/voucher/account/${(location as any).state.id}`,
        '',
        false,
        data,
        {
          headers: { token },
        },
      ),
    {
      onSuccess: async (res) => {
        if (res.status === 200) {
          const resultAlert = await swal({
            title: '알림',
            text: '사이트 정보가 생성되었습니다.',
            buttons: {
              confirm: {
                text: '확인',
                closeModal: true,
                visible: true,
              },
            },
          });
          if (resultAlert) {
            navigate(-1);
          }
        }
      },
      onError: () => {
        swal({
          title: '에러',
          text: `아이디와 비밀번호를 확인해주세요.
          또는 아이디가 중복되었을 수도 있습니다.`,
          icon: 'error',
          dangerMode: true,
        });
      },
    },
  );

  const onChangeSite = useCallback(
    (id: number) => () => {
      setSiteInfo({ ...siteInfo, site: id });
    },
    [siteInfo],
  );

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSiteInfo({ ...siteInfo, [e.target.name]: e.target.value });
    },
    [siteInfo],
  );
  const onCreate = useCallback(() => {
    const alertArray = [];
    const emailRegExp = /.+@.+\..+/g;
    if (
      siteInfo.email.trim().length < 1 ||
      !emailRegExp.test(siteInfo.email.trim())
    ) {
      alertArray.push('이메일');
    }
    if (siteInfo.password.trim().length < 1) {
      alertArray.push('비밀번호');
    }

    if (alertArray.length > 0) {
      swal({
        title: '에러',
        text: `${alertArray.join(', ')}를 확인해주세요`,
        dangerMode: true,
        icon: 'error',
      });
      return;
    }

    const formData = new FormData();
    formData.append('username', siteInfo.email);
    formData.append('password', siteInfo.password);
    formData.append('voucherType', switchVoucherTypeToStr(siteInfo.site));
    onUpdateInfo(formData);
  }, [onUpdateInfo, siteInfo]);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <SiteInfoContainer>
      <h2>사이트 정보 등록</h2>
      <SiteInfoContent>
        <div className='site-info-form'>
          <div className='site-info-left-form'>사이트</div>
          <div className='site-info-right-form'>
            {siteChoiceButtons.map((c, i) => (
              <SelectionLabel
                type='button'
                onClick={onChangeSite(i)}
                key={c.id}
                isChecked={siteInfo.site === i}
              >
                {c.text}
              </SelectionLabel>
            ))}
          </div>
        </div>
        <div className='site-info-form'>
          <div className='site-info-left-form'>아이디</div>
          <div className='site-info-column-form'>
            <input
              type='text'
              value={siteInfo.email}
              name='email'
              onChange={onChangeText}
            />
            {siteInfo.email.trim().length < 1 ? (
              <p>{`${
                siteChoiceButtons[siteInfo.site].text
              } 사이트의 아이디를 입력해주세요`}</p>
            ) : null}
          </div>
        </div>
        <div className='site-info-form'>
          <div className='site-info-left-form'>비밀번호</div>
          <div className='site-info-column-form'>
            <input
              type='password'
              name='password'
              value={siteInfo.password}
              onChange={onChangeText}
            />
            {siteInfo.password.trim().length < 1 ? (
              <p>{`${
                siteChoiceButtons[siteInfo.site].text
              } 사이트의 비밀번호를 입력해주세요`}</p>
            ) : null}
          </div>
        </div>
      </SiteInfoContent>
      <TwoButtons>
        <button type='button' onClick={onCreate}>
          등록
        </button>
        <button type='button' onClick={onCancel} className='cancel-button'>
          취소
        </button>
      </TwoButtons>
    </SiteInfoContainer>
  );
}

export default React.memo(SiteInfoCreate);
