import styled, { css } from 'styled-components';
import arrow from '@static/images/arrowdown.png';
import {
  flexAlign,
  inputChecked,
  pixelToRem,
  pixelToRemBorder,
} from '@util/commonStyles';
import { mediaQuery, media } from '@util/mediaQuery';
import theme from '@util/theme';

export const ExchangeRequestContainer = styled.article`
  margin: 0 auto;
  ${mediaQuery()} {
    width: auto;
    padding-left: ${pixelToRem(30)};
    padding-right: ${pixelToRem(30)};
  }
  ${media.custom(600)} {
    padding-left: ${pixelToRem(15)};
    padding-right: ${pixelToRem(15)};
  }
`;

export const ExchangeRequestInner = styled.div`
  max-width: ${pixelToRem(1500)};
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding-top: ${pixelToRem(100)};
  padding-bottom: ${pixelToRem(80)};

  ${mediaQuery()} {
    width: auto;
    padding-top: ${pixelToRem(30)};
    padding-bottom: ${pixelToRem(25)};
  }
  ${media.custom(1550)} {
    margin: 0 auto;
    max-width: ${pixelToRem(1300)};
  }
`;

export const ButtonContainer = styled.div`
  ${flexAlign(undefined, 'flex-start')};
  padding-bottom: 2rem;
  padding-top: 2rem;

  ${mediaQuery()} {
    ${flexAlign()};
    border-bottom: 1px solid ${theme.light.border.gray};

    & > button {
      flex: 1;
      height: 2.5rem;
      font-size: ${pixelToRem(14)};
      ${media.custom(600)} {
        font-size: ${pixelToRem(11)};
      }
    }
  }
`;

export const ExchangeRequestExplain = styled.div`
  & > h3 {
    font-size: ${pixelToRem(35)};
    font-weight: 600;
    margin: 0;
    margin-bottom: ${pixelToRem(25)};
  }

  p {
    margin: 0;
    font-size: ${pixelToRem(20)};
  }

  p:nth-child(2) {
    margin-bottom: ${pixelToRem(10)};
  }

  ${mediaQuery()} {
    & > h3 {
      font-size: ${pixelToRem(21)};
      text-align: center;
    }

    p {
      font-size: ${pixelToRem(14)};
    }
  }
`;

export const BuyFee = styled.div`
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
  padding-left: ${pixelToRem(35)};
  padding-top: ${pixelToRem(30)};
  padding-bottom: ${pixelToRem(16)};
  margin-top: ${pixelToRem(55)};
  margin-bottom: ${pixelToRem(55)};

  & > h3 {
    font-size: ${pixelToRem(25)};
    font-weight: 700;
    margin: 0 0 ${pixelToRem(20)} 0;
  }

  & > p {
    margin: 0;
    font-size: ${pixelToRem(20)};
    margin-bottom: ${pixelToRem(14)};
  }

  ${mediaQuery()} {
    padding: ${pixelToRem(15)} ${pixelToRem(15)};
    padding-bottom: ${pixelToRem(5)};
    margin-top: ${pixelToRem(35)};

    & > h3,
    & > p {
      font-size: ${pixelToRem(14)};
    }
  }
`;

export const ExchangeRequestWrapper = styled.section<{ isNotBorder?: boolean }>`
  box-sizing: border-box;
  border-bottom: ${({ isNotBorder }) =>
    isNotBorder ? 'none' : pixelToRemBorder(1, theme.light.border.gray)};

  .exchange-request-warning {
    font-size: ${pixelToRem(20)};
    margin-top: ${pixelToRem(10)};
  }

  ${mediaQuery()} {
    border-bottom: none;

    .exchange-request-warning {
      font-size: ${pixelToRem(14)};
    }
  }
`;

export const PinContainer = styled.div`
  margin-top: ${pixelToRem(50)};
  margin-bottom: ${pixelToRem(50)};
  ${flexAlign(undefined, 'space-between')};

  ${mediaQuery()} {
    /* ${flexAlign('column')}; */
    display: block;
  }
`;

export const PinWrapper = styled.div<{ flex: number; marginRight?: number }>`
  flex: ${({ flex }) => flex};
  margin-right: ${(props) =>
    props.marginRight ? pixelToRem(props.marginRight) : 0};

  & > div:nth-child(2) {
    font-size: ${pixelToRem(20)};
    margin: 0;
    margin-bottom: ${pixelToRem(43)};
    line-height: 0.6;
    ${media.custom(400)} {
      line-height: 1.4;
    }
  }

  & > div.exchange-request-button-align {
    ${flexAlign()};
  }

  ${mediaQuery()} {
    margin-right: 0;
    flex: 1;
    padding-bottom: ${pixelToRem(18)};
    border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
    margin-bottom: ${pixelToRem(30)};
    & > div:nth-child(2) {
      font-size: ${pixelToRem(14)};
      margin-bottom: ${pixelToRem(20)};
    }
    & > div.exchange-request-button-align > button {
      width: 8rem;
      height: 2.5rem;
      font-size: ${pixelToRem(14)};
      ${media.custom(600)} {
        font-size: ${pixelToRem(11)};
      }
    }
  }
`;

export const PinHeader = styled.h3`
  font-size: ${pixelToRem(35)};
  font-weight: 700;
  margin: 0;
  margin-bottom: ${pixelToRem(25)};

  ${mediaQuery()} {
    font-size: ${pixelToRem(21)};
    margin-bottom: ${pixelToRem(18)};
    text-align: center;
  }
`;

export const PinExtractContainer = styled.div`
  box-sizing: border-box;
  width: ${pixelToRem(555)};
  height: ${pixelToRem(470)};
  margin-bottom: ${pixelToRem(43)};
  ${mediaQuery()} {
    width: auto;
    font-size: ${pixelToRem(16)};
    height: ${pixelToRem(280)};
  }
  textarea {
    width: 100%;
    height: 100%;
    outline: none;
    border: ${pixelToRemBorder(1, theme.light.border.gray)};
    box-sizing: border-box;
    resize: none;
    font-size: ${pixelToRem(18)};
    padding: ${pixelToRem(18)};
    ${media.custom(500)} {
      font-size: ${pixelToRem(14)};
    }
  }
  textarea:focus::placeholder {
    color: transparent;
  }
  textarea::placeholder {
    line-height: 1.5;
    ${mediaQuery()} {
      font-size: ${pixelToRem(16)};
    }
    ${media.custom(500)} {
      line-height: 1.2;
    }
  }
`;

export const pinInputCSS = () => css`
  font-size: ${pixelToRem(20)};
  color: ${theme.light.color.gray707070};
  height: 100%;
  outline: none;
  border: none;
  background-color: transparent;
`;

export const PinInputContainer = styled.div<{ isFix: boolean }>`
  height: ${pixelToRem(60)};
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
  padding-right: ${pixelToRem(27)};
  ${flexAlign(undefined, 'flex-start')};
  margin-bottom: ${pixelToRem(43)};

  input:focus::placeholder {
    color: transparent;
  }

  .exchange-request-mobile {
    ${flexAlign(undefined, 'flex-start')};
    height: 100%;
    flex: 1;
  }

  & > div:nth-child(1) {
    width: ${pixelToRem(60)};
    height: ${pixelToRem(58)};
    background-color: ${theme.light.background.navy};
    color: ${theme.light.color.white};
    font-size: ${pixelToRem(25)};
    font-weight: 700;
    ${flexAlign()};
  }

  .exchange-request-input-pin {
    padding-left: ${pixelToRem(25)};
    padding-right: ${pixelToRem(95)};
    flex: 1;

    ${pinInputCSS()};
  }

  .exchange-request-vertical-bar {
    height: ${pixelToRem(40)};
    width: ${pixelToRem(1)};
    background-color: ${theme.light.background.gray};
    margin-right: ${pixelToRem(30)};
  }

  .exchange-request-input-issue {
    ${pinInputCSS()};
  }

  .exchange-request-input-auth-number {
    ${pinInputCSS()};
  }

  ${mediaQuery()} {
    /* width: 100%; */

    margin-bottom: ${pixelToRem(20)};
    & > div:nth-child(1) {
      flex: 1 0 ${pixelToRem(-10)};
    }
    .exchange-request-mobile {
      display: flex;
      flex-direction: row;

      .exchange-request-input-pin {
        padding: 0;
        width: 70%;
        font-size: ${pixelToRem(18)};
        padding-left: ${pixelToRem(21)};
      }
      .exchange-request-input-issue,
      .exchange-request-input-auth-number {
        padding: 0;
        box-sizing: border-box;
        font-size: ${pixelToRem(20)};
        padding-left: ${pixelToRem(21)};
        flex: 0.5 0 ${pixelToRem(60)};
        border-left: 1px solid ${theme.light.border.gray};
      }

      .exchange-request-vertical-bar {
        display: none;
      }
    }
    ${media.custom(600)} {
      height: 100%;
      margin-bottom: ${pixelToRem(20)};
      & > div:nth-child(1) {
        font-size: ${pixelToRem(16)};
        width: 30px;
        height: 40px;
      }

      .exchange-request-mobile {
        .exchange-request-input-pin {
          padding: 0;
          flex: 2;
          font-size: ${pixelToRem(15)};
          padding-left: ${pixelToRem(10)};
        }
        .exchange-request-input-issue,
        .exchange-request-input-auth-number {
          padding: 0;
          padding-left: ${pixelToRem(10)};
          box-sizing: border-box;
          font-size: ${pixelToRem(15)};
          overflow-x: hidden;
          border: 0;
          flex: 1;
          border-radius: 0;
          border-left: 1px solid ${theme.light.border.gray};
          /* margin-left: ${pixelToRem(60)}; */
        }

        .exchange-request-vertical-bar {
          display: none;
        }
      }
    }
  }
`;

export const DepositContainer = styled.div`
  padding-bottom: ${pixelToRem(50)};

  & > h3 {
    margin: 0;
    margin-top: ${pixelToRem(45)};
    margin-bottom: ${pixelToRem(25)};
    font-size: ${pixelToRem(35)};
    font-weight: 700;
    color: ${theme.light.color.black};
  }

  & > p {
    margin: 0;
    font-size: ${pixelToRem(20)};
    margin-bottom: ${pixelToRem(10)};
    color: ${theme.light.color.black};
  }

  ${mediaQuery()} {
    padding-bottom: 0;
    & > h3 {
      font-size: ${pixelToRem(21)};
      text-align: center;
    }

    & > p {
      font-size: ${pixelToRem(14)};
    }
  }
`;

export const DepositSelectContainer = styled.div`
  ${flexAlign()};
  margin-top: ${pixelToRem(50)};

  & > div {
    flex: 1;

    p {
      font-size: ${pixelToRem(20)};
      color: ${theme.light.color.black};
      margin: 0;
      margin-bottom: ${pixelToRem(16)};

      ${mediaQuery()} {
        font-size: ${pixelToRem(14)};
      }
    }

    select,
    input {
      height: ${pixelToRem(60)};
      outline: none;
      border: ${pixelToRemBorder(1, theme.light.border.gray)};
      box-sizing: border-box;
      padding-left: ${pixelToRem(16)};
      width: 90%;
      font-size: ${pixelToRem(20)};
      ${media.custom(600)} {
        height: ${pixelToRem(50)};
      }
    }

    select {
      color: ${theme.light.color.black};
      appearance: none;
      -webkit-appearance: none;
      background: url(${arrow}) calc(100% - 5px) center no-repeat;
    }

    input,
    input::placeholder {
      ${flexAlign(undefined, 'flex-start')};
      color: ${theme.light.color.gray707070};

      ${media.custom(600)} {
        font-size: ${pixelToRem(14)};
      }
    }
  }

  ${mediaQuery()} {
    display: block;
    & > div {
      margin-bottom: ${pixelToRem(20)};

      input,
      select {
        width: 100%;
        /* font-size: ${pixelToRem(17)}; */
        height: ${pixelToRem(40)};
        font-size: ${pixelToRem(14)};
      }
    }
  }
`;

export const DepositCheckContainer = styled.div`
  ${flexAlign(undefined, 'flex-end')};
  margin-top: ${pixelToRem(30)};
  ${inputChecked()};

  p {
    margin: 0;
    margin-left: ${pixelToRem(16)};
  }

  ${mediaQuery()} {
    margin-top: 0;
    box-sizing: border-box;
    padding-bottom: ${pixelToRem(20)};
    border-bottom: ${pixelToRemBorder(1, theme.light.border.gray707070)};
    margin-bottom: ${pixelToRem(20)};

    p {
      font-size: ${pixelToRem(14)};
      margin-left: ${pixelToRem(8)};
    }
  }
`;

export const ExchangeButtonContainer = styled.div`
  ${flexAlign()};

  ${mediaQuery()} {
    & > button {
      width: 8rem;
      height: 2.5rem;
      font-size: ${pixelToRem(14)};
      margin-top: 0 !important;
      ${media.custom(600)} {
        font-size: ${pixelToRem(11)};
      }
    }
  }
`;

export const OptimizedBrowserNotification = styled.div`
  text-align: center;
  margin-top: ${pixelToRem(16)};
  font-size: ${pixelToRem(17)};
`;
