import React from 'react';
import { bannerBoldText, bannerLightText } from '@static/common';
import { UserLayoutContainer } from '@layout/user/home/styles';
import { LinkRadius, TopBannerContainer } from './styles';

function TopBanner() {
  return (
    <>
      <UserLayoutContainer>
        <TopBannerContainer>
          <div className='top-banner-inner'>
            <div className='top-banner-guard'>
              <div>
                <div className='line' />
                <div className='top-banner-header-container'>
                  {bannerBoldText.map((text: string) => (
                    <div className='top-banner-header' key={text}>
                      {text}
                    </div>
                  ))}
                </div>
                <div className='top-banner-content-container'>
                  {bannerLightText.map((text: string) => (
                    <div className='bannerlight' key={text}>
                      {text}
                    </div>
                  ))}
                </div>
                {/* className='top-banner-goto-exchange' */}
                <LinkRadius to='/user/exchange'>상품권교환바로가기</LinkRadius>
              </div>
            </div>
          </div>
        </TopBannerContainer>
      </UserLayoutContainer>
    </>
  );
}

export default React.memo(TopBanner);
