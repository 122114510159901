import React from 'react';
import SiteInfoCreate from '@comp/superAdmin/account/SiteInfoCreate';
import ManagerLayout from '@layout/superAdmin/ManagerLayout';

function SuperAdminSiteCreate() {
  return (
    <ManagerLayout>
      <SiteInfoCreate />
    </ManagerLayout>
  );
}

export default React.memo(SuperAdminSiteCreate);
