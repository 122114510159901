export type Voucher = 'CULTURELAND' | 'HAPPYMONEY' | 'BOOKNLIFE';

export default function switchVoucherType(str: Voucher): string {
  switch (str) {
    case 'CULTURELAND':
      return '컬쳐랜드 문화상품권';
    case 'HAPPYMONEY':
      return '해피머니 문화상품권';
    case 'BOOKNLIFE':
      return '북앤라이프 문화상품권';
    default:
      return '';
  }
}

export function switchVoucherTypeToStr(num: number): Voucher {
  switch (num) {
    case 0:
      return 'CULTURELAND';
    case 1:
      return 'HAPPYMONEY';
    case 2:
      return 'BOOKNLIFE';
    default:
      return 'CULTURELAND';
  }
}
