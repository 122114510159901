import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export type SiteDataType = {
  site: {
    id: number;
    name: string;
    url: string;
    headerLogo: string;
    footerLogo: string;
  };
  business: {
    license: string;
    licenseImage: string;
    companyName: string;
    ceoName: string;
    phone: string;
    email: string;
    fax: string;
    address: string;
    zipcode: string;
  };
};

type Res = {
  status: number;
  msg: string;
  data: SiteDataType;
};

const initialSiteData = {
  site: {
    id: -1,
    name: '',
    url: '',
    headerLogo: '',
    footerLogo: '',
  },
  business: {
    license: '',
    licenseImage: '',
    companyName: '',
    ceoName: '',
    phone: '',
    email: '',
    fax: '',
    address: '',
    zipcode: '',
  },
};

export type SiteIdType = {
  origin: string;
  data: SiteDataType;
};

export const SiteIdState: SiteIdType = {
  origin: window.location.origin,
  data: initialSiteData,
};

export const fetchSiteId = createAsyncThunk('siteId/fetchSiteId', async () => {
  const response = await axios.get(`/common?url=${window.location.origin}`);
  return response.data;
});

const siteIdSlice = createSlice({
  name: 'siteId',
  initialState: SiteIdState,
  reducers: {
    setSiteId: (state, action: PayloadAction<SiteDataType>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSiteId.fulfilled, (state, action: PayloadAction<Res>) => {
        state.data = action.payload.data;
      })
      .addCase(fetchSiteId.rejected, (state) => {
        state.data = initialSiteData;
      });
  },
});

export default siteIdSlice;
