type SEOType = {
  [key: string | number]: any;
};

export const seoHelmet: SEOType = {
  user: {
    main: {
      keywords:
        '상품권, 컬쳐랜드, 해피머니, 북앤라이프, 상품권 교환, 티켓, 티켓교환, 티켓 링크, 캐시핀, 블루 티켓, 24핀팜, 24고고핀, 백화점 상품권, 백화점, 신세계 상품권, 신세계, 롯데 상품권, 롯데, 현금 교환, 현금, 상품권 현금, 현금 상품권',
    },
    myexchange: {
      keywords:
        '상품권, 컬쳐랜드, 해피머니, 북앤라이프, 상품권 교환, 티켓, 티켓교환, 티켓 링크, 캐시핀, 블루 티켓, 24핀팜, 24고고핀, 백화점 상품권, 백화점, 신세계 상품권, 신세계, 롯데 상품권, 롯데, 현금 교환, 현금, 상품권 현금, 현금 상품권, 현금 교환, 교환, 상품권 내역, 상품권 현금',
    },
    exchange: {
      keywords:
        '상품권, 컬쳐랜드, 해피머니, 북앤라이프, 상품권 교환, 티켓, 티켓교환, 티켓 링크, 캐시핀, 블루 티켓, 24핀팜, 24고고핀, 백화점 상품권, 백화점, 신세계 상품권, 신세계, 롯데 상품권, 롯데, 현금 교환, 현금, 상품권 현금, 현금 상품권',
    },
    realtime: {
      keywords:
        '상품권, 실시간 상품권, 실시간 교환 상품권, 실시간, 현금 교환, 현금, 상품권 현금, 상품권 교환, 상품권 교환 현황',
    },
    notice: {
      keywords:
        '상품권, 컬쳐랜드, 해피머니, 북앤라이프, 상품권 교환, 티켓, 티켓교환, 티켓 링크, 캐시핀, 블루 티켓, 24핀팜, 24고고핀, 백화점 상품권, 백화점, 신세계 상품권, 신세계, 롯데 상품권, 롯데, 현금 교환, 현금, 상품권 현금, 현금 상품권, 공지사항',
    },
    faq: {
      keywords:
        '상품권, 컬쳐랜드, 해피머니, 북앤라이프, 상품권 교환, 티켓, 티켓교환, 티켓 링크, 캐시핀, 블루 티켓, 24핀팜, 24고고핀, 백화점 상품권, 백화점, 신세계 상품권, 신세계, 롯데 상품권, 롯데, 현금 교환, 현금, 상품권 현금, 현금 상품권, faq, 자주 묻는 질문, 질문, 자주, 묻는',
    },
  },
};
