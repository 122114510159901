export type Token = {
  id: number;
  email: string;
  name: string;
  permit: number;
  iat: number;
  exp: number;
  iss: string;
  siteId?: number;
  siteName?: string;
};

export function addCookie(key: string, value: string, expires?: string): void {
  if (typeof document === 'undefined') return;
  if (expires) {
    const data = new Date(Date.now() + expires);
    document.cookie = `${key}=${encodeURIComponent(
      value,
    )}; expires=${data.toUTCString()}; path=/;`;
  } else {
    document.cookie = `${key}=${encodeURIComponent(value)}; path=/;`;
  }
}

export function getCookie(
  cookie: Document['cookie'],
  key: string,
): string | undefined | null {
  if (!cookie) return null;
  const regExp = new RegExp(
    `(?:^|; )${encodeURIComponent(key).replace(
      /([.$?*|{}()[\]\\/+^])/g,
      '\\$1',
    )}=([^;]*)`,
  );
  const matches = cookie.match(regExp);
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function removeCookie(key: string): void {
  if (typeof document === 'undefined') return;
  document.cookie = `${key}=; expires=-1; path=/;`;
}

export function decodedToken(t?: string): Token {
  const token = t || getCookie(document.cookie, 'token');
  if (!token) return {} as Token;
  let jsonPayload = '';
  const base64Url = token.split('.')[1]?.replace(/-/g, '+').replace(/_/g, '/');
  window
    .atob(base64Url as string)
    .split('')
    .forEach((c) => {
      jsonPayload += `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
    });
  jsonPayload = decodeURIComponent(jsonPayload);
  return JSON.parse(jsonPayload);
}

export function authToken(admin: 1 | 0): boolean {
  const token = decodedToken();
  if (Object.keys(token).length === 0) {
    return false;
  }
  const { id, name, email, exp, iat, iss, permit, siteId, siteName } = token;
  if (!id || !name || !email || !exp || !iat || !iss) {
    return false;
  }
  if (Number.isNaN(Number(id))) {
    return false;
  }
  if (Number.isNaN(Number(permit)) || ![0, 1].includes(permit)) {
    return false;
  }
  if (Number.isNaN(Number(exp))) {
    return false;
  }
  if (Number.isNaN(Number(iat))) {
    return false;
  }
  if (
    new Date(exp * 1000).getTime() !==
    new Date(iat * 1000 + 7 * 24 * 3600 * 1000).getTime()
  ) {
    return false;
  }
  if (admin !== permit) {
    return false;
  }
  if (permit === 1) {
    if (!siteId || !siteName) {
      return false;
    }
    if (Number.isNaN(Number(siteId))) {
      return false;
    }
  }
  if (!name || (name && !name.match(/^[가-힣a-zA-Z0-9]{1,10}$/))) {
    return false;
  }
  if (
    !email ||
    (email &&
      !email.match(
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/,
      ))
  ) {
    return false;
  }

  return true;
}
