import React from 'react';
import { FooterContainer } from './styles';

// type UserFooterProps = {};

function UserFooter() {
  return (
    <>
      <FooterContainer>
        <div className='footer-section'>
          <div className='footer-content'>CASH PIN</div>
          <div className='footer-info'>
            <div>copyrightⓒ 2022 All rights reserved by Ahead Inc.</div>
            <div>
              (주) 어헤드 ㅣ 대표이사: 박혜성 | 사업자등록번호 : 284-87-02266
            </div>
            <div>주소 : 광주광역시 남구 진다리로 52번 길 7</div>
            <div>고객센터: cashpinahead@gmail.com</div>
            <div>
              <a
                className='agrees'
                href='./docs/term.pdf'
                target='_blank'
                rel='noopener noreferer'
              >
                서비스 이용약관
              </a>{' '}
              ㅣ{' '}
              <a
                className='agrees'
                href='/docs/privacy.pdf'
                target='_blank'
                rel='noopener noreferer'
              >
                개인정보처리방침
              </a>
            </div>
          </div>
        </div>
      </FooterContainer>
    </>
  );
}

export default React.memo(UserFooter);
