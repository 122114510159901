import styled, { createGlobalStyle } from 'styled-components';
import { pixelToRem } from '@util/commonStyles';
import { mediaQuery } from '@util/mediaQuery';

export const UserGlobalStyles = createGlobalStyle`
  html, body, #root {
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
`;

export const UserLayoutContainer = styled.div`
  margin: 0 auto;
  max-width: ${pixelToRem(1920)};
  width: 100%;
  position: relative;
  margin-bottom: ${pixelToRem(30)};

  ${mediaQuery()} {
    width: 100%;
  }
`;
