export const siteTitle = '캐시핀';
export const subStieTitle = '상품권 현금교환은 믿을 수 있는 캐시핀에서!';
export const siteTitleEnglish = 'CASH PIN';

export const bannerBoldText: string[] = [
  '실시간 자동화 시스템으로',
  '1분안에 자동 입금!',
];

export const bannerLightText: string[] = [
  '365일, 24시간 연중무휴 자동화 시스템으로',
  '신청 즉시 핀번호 확인부터 입금까지 빠르고 안전한 상품권 교환',
];

export const getPinExample: string =
  '문자메세지, 카카오톡 메세지를 그대로 복사 붙여넣기 하세요';

export const getPinExampleHappy: string = `ex)
[Web발신]
* 제휴사명 : 해피머니
* 상품명칭 : 해피머니 모바일 문화상품권
* 쿠폰번호 : 0000-0000-0000-0000
* 인증번호 : 12345678
* 유효기간 : ~ 20XX-XX-XX
* 사용가격 : 10,000
* 결제금액 : 10,000`;

export const getPinExampleBook: string = `ex)
[Web발신]
* 제휴사명 : 북앤라이프
* 상품명칭 : 북앤라이프 모바일 도서문화상품권
* 쿠폰번호 : 0000-0000-0000-0000-0000
* 유효기간 : ~ 20XX-XX-XX
* 사용가격 : 10,000
* 결제금액 : 10,000`;
