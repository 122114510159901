import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import { useMutation, useQuery } from 'react-query';
import swal from 'sweetalert';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import 'react-datepicker/dist/react-datepicker.css';

import { headList } from '@static/admin/admin';
import dateFormatter from '@util/dateFormatter';
import { getfetcher, postFetcher, Res } from '@util/fetcher';
import switchVoucherType from '@util/admin/switchVoucherType';
import { mutationKeys, queryKeys } from '@util/queries';

import { pixelToRem } from '@util/commonStyles';
import theme from '@util/theme';
import { decodedToken, getCookie } from '@util/tokenUtil';
import type { ProcessStatus, ResultTable, ResultTableResponse } from './types';
import ProcessStatusBtn from './ProcessStatusBtn';
import Pagination from '../Pagination';
import {
  HeaderSculpture,
  Selection,
  SelectionLabel,
  StatementOperator,
  StatementOperatorContainer,
  TwoButtons,
  StatementResultContainer,
} from './styles';

registerLocale('ko', ko);

const dateFormat = 'yyyy-MM-dd';

function CalcDate(a: any, b: any) {
  const getTime = Number(a.getTime() - b.getTime());
  const time = getTime / 1000 / 60 / 60 / 24;
  return Math.ceil(time);
}

const siteList = [
  // { key: 'ALL', value: '전체' },
  { key: 'CULTURELAND', value: '컬쳐랜드' },
  // { key: 'HAPPYMONEY', value: '해피머니' },
  // { key: 'BOOKNLIKE', value: '도서상품권' },
];

const statusList: { key: string; value: ProcessStatus }[] = [
  // { key: '전체', value: 'ALL' },
  { key: '충전요청', value: 'REQUESTED' },
  { key: '충전완료', value: 'COMPLETED' },
  { key: '충전거부', value: 'REJECTED' },
  { key: '입금대기', value: 'WAITDEPOSIT' },
  { key: '입금요청', value: 'RQSTDEPOSIT' },
  { key: '입금완료', value: 'CPLTDEPOSIT' },
  { key: '입금실패', value: 'FAILDEPOSIT' },
  { key: '입금거절', value: 'RJTDDEPOSIT' },
];

const searchList = [
  { id: 1, diff: 0, text: '오늘' },
  { id: 2, diff: 7, text: '일주일' },
  { id: 3, diff: 30, text: '1개월' },
];

type VoucherType = {
  id: number;
  siteId: number;
  voucherType: string;
  name: string;
  rate: number;
};

function Statement() {
  const token = (getCookie(document.cookie, 'token') as string) || '';
  const [choiceDay, setChoiceDay] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [choiceSite, setChoiceSite] = useState(0);
  const [choiceStatus, setChoiceStatus] = useState(0);
  // const [feeTarget, setFeeTarget] = useState(0);
  const [rowSize, setRowSize] = useState<number | undefined>(undefined);
  const [feeRatio] = useState(10);
  const [list, setList] = useState<ResultTable[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const onVoucherList = useCallback(async () => {
    if (!token) {
      return;
    }

    const { id, permit } = decodedToken();
    const voucherResult =
      permit === 1
        ? await axios.get(`/admin/voucher/${id}`, { headers: { token } })
        : null;

    const voucherTarget = voucherResult
      ? (voucherResult.data.data.voucherList as VoucherType[])
      : [];

    if (voucherTarget.length === 0 || !voucherTarget) {
      swal({ text: '해당 플랫폼을 찾지 못했어요.' });
      return;
    }
  }, [token]);

  useEffect(() => {
    onVoucherList();
  }, [onVoucherList]);

  const [page, setPage] = React.useState<number>(1);
  const { refetch } = useQuery<ResultTableResponse>(
    [
      queryKeys.adminSiteStatement,
      page,
      choiceDay.start,
      choiceDay.end,
      feeRatio,
      token,
      choiceStatus,
    ],
    () =>
      getfetcher(
        `/admin/exchange/`,
        `?page=${page}&pageSize=10&voucherType=${
          siteList[choiceSite].key
        }&startDate=${dayjs(choiceDay.start).format(
          'YYYY-MM-DDT00:00:00',
        )}&endDate=${dayjs(choiceDay.end).format('YYYY-MM-DDT23:59:59')}
        ${
          choiceStatus === 0 ? '' : `&status=${statusList[choiceStatus].value}`
        }`.replace(/\s+/g, ''),
        true,
        { headers: { token } },
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onSuccess: (old) => {
        setList(old.data?.history.exchanges as ResultTable[]);
        setTotalCount(old.data?.totalCount ?? 0);
      },
      enabled: !!token && decodedToken().permit === 1,
    },
  );

  const onChangeExcelRow = useCallback((e) => {
    setRowSize(e.target.value);
  }, []);

  const onDownloadExcel = useCallback(async (workhook, voucherName: string) => {
    const buffer = await workhook.xlsx.writeBuffer();
    const mimetype = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    const blob = new Blob([buffer], mimetype);
    saveAs(blob, `캐시핀_${voucherName}_교환현황.xlsx`);
  }, []);

  const fetchGetExchangeExcelData = useCallback(async () => {
    if (token) {
      const { siteId } = decodedToken();
      await axios
        .get(
          `/admin/excel?siteId=${siteId}&voucherType=${
            siteList[choiceSite].key
          }&startDate=${dayjs(choiceDay.start).format(
            'YYYY-MM-DDT00:00:00',
          )}&endDate=${dayjs(choiceDay.end).format(
            'YYYY-MM-DDT23:59:59',
          )}&pageSize=${rowSize}`,
          { headers: { token } },
        )
        .then(async (res) => {
          const { columns, rows, voucherName } = res.data.data;
          const workhook = new ExcelJS.Workbook();
          const sheet = workhook.addWorksheet(voucherName);
          sheet.columns = columns;
          sheet.addRows(rows, 'i');
          onDownloadExcel(workhook, voucherName);
        });
    }
  }, [token, choiceDay, choiceSite, rowSize, onDownloadExcel]);

  const onChoiceSite = useCallback(
    (id: number) => () => {
      setChoiceSite(id);
    },
    [],
  );

  const onSubtractDay = (diff: number) => () => {
    setChoiceDay({
      ...choiceDay,
      start: dayjs(choiceDay.end).subtract(diff, 'day').toDate(),
    });
  };

  const onReset = useCallback(() => {
    setChoiceSite(0);
    setChoiceDay({ start: new Date(), end: new Date() });
  }, []);

  const onSearch = () => {
    refetch();
  };

  const onChoiceStatus = useCallback(
    (id: number) => () => {
      setChoiceStatus(id);
    },
    [],
  );

  const { mutate } = useMutation<Res<string>, unknown, any>(
    [mutationKeys.admin.modifyName],
    (data: any) =>
      postFetcher(`/admin/exchange/redeposit`, '', false, data, {
        headers: { token },
      }),
    {},
  );

  const onModify = useCallback(
    (id: number, holderName: string) => async () => {
      const formData = new FormData();

      formData.append('id', id.toString());

      if (
        /^[가-힣]{1,}$/g.test(holderName.trim()) === false ||
        holderName.trim().length <= 0
      ) {
        swal({ text: '예금주명은 한글만 입력해주세요' });
        return;
      }
      formData.append('holderName', holderName.trim());

      mutate(formData);
    },
    [mutate],
  );

  const onChoiceStartDay = (date: any) =>
    setChoiceDay((p) => ({ ...p, start: new Date(date) }));

  const onChoiceEndDay = (date: any) =>
    setChoiceDay((p) => ({ ...p, end: new Date(date) }));

  return (
    <>
      <StatementOperatorContainer>
        <StatementOperator>
          <HeaderSculpture fontSize={20}>통계</HeaderSculpture>
          <Selection>
            <div>
              <div>조회기간</div>
              {searchList.map((value) => (
                <SelectionLabel
                  type='button'
                  key={value.id}
                  isChecked={
                    CalcDate(choiceDay.end, choiceDay.start) === value.diff
                  }
                  onClick={onSubtractDay(value.diff)}
                >
                  {value.text}
                </SelectionLabel>
              ))}
              <DatePicker
                startDate={new Date()}
                onChange={onChoiceStartDay}
                value={dateFormatter(choiceDay.start.toLocaleDateString('ko'))}
                dateFormat={dateFormat}
                className='date-picker-input'
              />
              <div>&nbsp;&nbsp;~&nbsp;&nbsp;</div>
              <DatePicker
                startDate={new Date()}
                onChange={onChoiceEndDay}
                value={dateFormatter(choiceDay.end.toLocaleDateString('ko'))}
                dateFormat={dateFormat}
                className='date-picker-input'
              />
            </div>
            <div>
              <div>사이트 선택</div>
              <div>
                {siteList.map(({ key, value }, index) => (
                  <SelectionLabel
                    type='button'
                    key={`site-${key}`}
                    isChecked={choiceSite === index}
                    onClick={onChoiceSite(index)}
                  >
                    {value}
                  </SelectionLabel>
                ))}
              </div>
            </div>
            <div>
              <div>상태</div>
              <div>
                {statusList.map(({ key, value }, index) => (
                  <SelectionLabel
                    type='button'
                    key={`status-${value}`}
                    isChecked={choiceStatus === index}
                    onClick={onChoiceStatus(index)}
                  >
                    {key}
                  </SelectionLabel>
                ))}
              </div>
            </div>
          </Selection>
          <TwoButtons>
            <button type='button' onClick={onSearch}>
              검색
            </button>
            <button type='button' onClick={onReset}>
              초기화
            </button>
          </TwoButtons>
        </StatementOperator>
      </StatementOperatorContainer>
      <StatementResultContainer>
        <div className='statement-result-title'>
          {`사이트 통계 (${totalCount ?? 0}건)`}
        </div>
        <div className='statement-result-table-container'>
          <div className='statement-result-table-wrapper'>
            <div className='statement-result-table-top'>
              <select
                title='엑셀 개수 선택'
                value={rowSize}
                onChange={onChangeExcelRow}
              >
                <option value=''>전체</option>
                <option value='10'>10</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
              <button
                type='button'
                onClick={fetchGetExchangeExcelData}
                className='excel-download-button'
              >
                핀번호 엑셀 다운
              </button>
            </div>
            <div className='statement-result-table-box'>
              <div className='statement-result-table-head'>
                {headList.map((item) => (
                  <div key={item}>{item}</div>
                ))}
              </div>
              {list &&
                list.length > 0 &&
                list.map((item) => (
                  <div className='statement-result-table-body' key={item.id}>
                    <div>{switchVoucherType(item.voucherType)}</div>
                    <div>{item.chargedPrice.toLocaleString('ko-KR')}원</div>
                    <div>{item.holderName}</div>
                    <div>
                      {dayjs(item.applicatedAt).format('YY-MM-DD HH:mm:ss')}
                    </div>
                    <div>
                      {dayjs(item.completedAt).format('YY-MM-DD HH:mm:ss')}
                    </div>
                    <div>{item.depositPrice.toLocaleString('ko-KR')}원</div>
                    <div>
                      <ProcessStatusBtn status={item.status} />
                    </div>
                    <div className='statement-result-table-row-align'>
                      <button
                        type='button'
                        onClick={onModify(item.id, item.holderName)}
                        className='excel-download-button'
                      >
                        재이체
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            <Pagination page={page} setPage={setPage} totalCount={totalCount} />
          </div>
        </div>
      </StatementResultContainer>
    </>
  );
}

export const ModifyNameBlock = styled.div`
  p {
    font-size: ${pixelToRem(17)};
    width: ${pixelToRem(150)};
  }

  input {
    outline: none;
    border: 1px solid ${theme.light.border.navy};
    height: ${pixelToRem(40)};
    margin-right: ${pixelToRem(10)};
    padding-left: ${pixelToRem(10)};

    &:last-child {
      margin-right: 0;
    }

    &:disabled {
      background-color: ${theme.light.background.grayECECEC};
    }
  }

  .modify-name-block-names {
    display: flex;
    align-items: center;

    p {
      margin-right: ${pixelToRem(10)};
    }
  }

  .modify-name-block-require {
    color: ${theme.light.color.red};
    display: flex;
    justify-content: flex-end;
    font-size: ${pixelToRem(12)};
    margin-bottom: ${pixelToRem(10)};
  }
`;

export default React.memo(Statement);
