import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
// import { useNavigate } from 'react-router-dom';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { GrCircleInformation } from 'react-icons/gr';
import { UserLayoutContainer } from '@layout/user/home/styles';
import { FAQData, FAQResponse } from '@static/user/default/commonTypes';
import { getfetcher } from '@util/fetcher';
import { queryKeys } from '@util/queries';
import CommonBanner from '../topBanner/CommonBanner';
import {
  AccordionSection,
  Container,
  Wrap,
  Dropdown,
  BodyLoadingOrEmpty,
} from './styles';

function FaqSection() {
  const [clicked, setClicked] = React.useState<any>(false);
  // const navigate = useNavigate();
  const { data, isLoading } = useQuery<FAQResponse>(
    [queryKeys.user.faqList, { page: 1 }],
    () => getfetcher(`/faq/`, '', true),
    {
      retry: 5,
      refetchInterval: 1000 * 100,
    },
  );
  const toggle = useCallback(
    (index: any) => {
      if (clicked === index) {
        return setClicked(null);
      }
      return setClicked(index);
    },
    [clicked],
  );

  return (
    <>
      <UserLayoutContainer>
        <CommonBanner
          title='자주 묻는 질문'
          text='365일, 24시간 카카오톡 상담가능'
        />
        <AccordionSection>
          <Container>
            {data && data.data.length > 0 ? (
              data.data.map((item: FAQData, index: number) => (
                <div key={item.id}>
                  <Wrap onClick={() => toggle(index)}>
                    <GrCircleInformation size={30} />
                    <h1>{item.title}</h1>
                    <span>
                      {clicked === index ? (
                        <FiChevronUp size={25} />
                      ) : (
                        <FiChevronDown size={25} />
                      )}
                    </span>
                  </Wrap>
                  {clicked === index ? (
                    <Dropdown>{item.content}</Dropdown>
                  ) : null}
                </div>
              ))
            ) : (
              <BodyLoadingOrEmpty>
                {isLoading ? '로딩중...' : '자주 묻는 질문이 없습니다.'}
              </BodyLoadingOrEmpty>
            )}
          </Container>
        </AccordionSection>
      </UserLayoutContainer>
    </>
  );
}

export default React.memo(FaqSection);
