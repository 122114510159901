import { Link } from 'react-router-dom';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  flexAlign,
  gridInit,
  pixelToRem,
  pixelToRemBorder,
} from '@util/commonStyles';
import { mediaQuery, media } from '@util/mediaQuery';
import theme from '@util/theme';

export const UserBoardContainer = styled.article`
  height: ${pixelToRem(850)};
  ${mediaQuery()} {
    height: auto;
    padding-bottom: ${pixelToRem(30)};
    padding-left: ${pixelToRem(30)};
    padding-right: ${pixelToRem(30)};
  }
  ${media.custom(600)} {
    padding-bottom: ${pixelToRem(20)};
    padding-left: ${pixelToRem(15)};
    padding-right: ${pixelToRem(15)};
  }
`;

export const UserBoardInner = styled.div`
  ${flexAlign()};
  height: 100%;

  .user-board-desktop {
    display: flex;
  }

  .user-board-mobile {
    display: none;
  }
  ${mediaQuery()} {
    ${flexAlign('column', 'flex-start', 'center')};
    width: auto;

    .user-board-desktop {
      display: none;
    }

    .user-board-mobile {
      display: block;
      width: 100%;
    }
  }
`;

export const RightContainer = styled.section`
  ${mediaQuery()} {
    width: 100%;
  }
`;

const boardInnerCSS = (
  width: number = 100,
  height: number = 100,
): FlattenSimpleInterpolation => css`
  height: ${pixelToRem(height)};
  width: ${pixelToRem(width)};
  box-sizing: border-box;
  border: 1px solid ${theme.light.border.gray};
  border-radius: ${pixelToRem(6)};
  .board-header {
    ${flexAlign(undefined, 'space-between')};
    flex: 1;
    height: ${pixelToRem(80)};
    box-sizing: border-box;
    border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
    padding-left: ${pixelToRem(30)};
    padding-right: ${pixelToRem(30)};
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 8%);
    & > h3 {
      font-size: ${pixelToRem(22)};
      font-weight: 900;
    }

    & > div > h3 {
      font-size: ${pixelToRem(22)};
      font-weight: 900;
    }

    .add-view-btn {
      ${flexAlign()};
      width: ${pixelToRem(30)};
      height: ${pixelToRem(30)};
      box-sizing: border-box;
      border: ${pixelToRemBorder(1, theme.light.border.gray)};
      border-radius: ${pixelToRem(3)};
      svg {
        fill: ${theme.light.border.gray};
      }
    }
  }

  ${mediaQuery()} {
    width: 100%;
    .board-header {
      height: ${pixelToRem(60)};
      padding-left: ${pixelToRem(19)};
      padding-right: ${pixelToRem(19)};

      & > h3 {
        font-size: ${pixelToRem(18)};
        font-weight: 900;
        ${media.custom(400)} {
          font-size: ${pixelToRem(15)};
        }
      }
      & > div > h3 {
        font-size: ${pixelToRem(18)};
      }

      svg {
        width: 30px;
        height: 30px;
        ${media.custom(400)} {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
`;

export const RealtimeExchangeContainer = styled.section`
  ${boardInnerCSS(800, 685)};
  margin-left: ${pixelToRem(30)};
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 8%);

  ${mediaQuery()} {
    margin-left: 0;
    margin-bottom: ${pixelToRem(25)};
  }
  ${media.custom(1550)} {
    ${boardInnerCSS(700, 685)};
  }
  ${media.custom(600)} {
    ${boardInnerCSS(700, 350)};
  }
`;

export const RealtimeListHead = styled.div`
  ${gridInit('1.5fr 3fr 1fr 1.5fr', { rowGap: 0, columnGap: 0 })};
  background-color: ${theme.light.background.grayECECEC};

  & > div {
    ${flexAlign()};
    height: ${pixelToRem(40)};
    color: ${theme.light.color.gray707070};
    font-size: ${pixelToRem(17)};
    ${mediaQuery()} {
      font-size: ${pixelToRem(15)};
    }
    ${media.custom(400)} {
      font-size: ${pixelToRem(13)};
    }
  }

  & > div:nth-child(2) {
    justify-content: flex-start;
  }
`;

export const RealtimeListBodyContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: ${pixelToRem(560)};
  & > div {
    margin-top: ${pixelToRem(10)};
    margin-bottom: ${pixelToRem(16)};
  }

  ${mediaQuery()} {
    max-height: ${pixelToRem(580)};
  }
  ${media.custom(600)} {
    max-height: ${pixelToRem(245)};
    & > div {
      padding: ${pixelToRem(5)} 0;
      margin: 0;
    }
    & > :nth-child(odd) {
      background-color: ${theme.light.background.lightbeige};
    }
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const RealtimeListBody = styled(RealtimeListHead)`
  background-color: ${theme.light.background.white};

  & > div:nth-child(2) {
    color: ${theme.light.color.black};
    font-size: ${pixelToRem(20)};
  }
  & > div:nth-child(3),
  & > div:nth-child(4) {
    color: ${theme.light.color.gray707070};
    font-size: ${pixelToRem(20)};
  }
  & > .realtime-exchange-mobile {
    display: none;
  }

  ${mediaQuery()} {
    & > div:nth-child(2),
    & > div:nth-child(3),
    & > div:nth-child(4) {
      font-size: ${pixelToRem(17)};
      ${media.custom(400)} {
        font-size: ${pixelToRem(14)};
      }
    }
  }
  ${media.custom(600)} {
    .realtime-exchange-web {
      display: none;
    }
    .realtime-exchange-mobile {
      display: flex;
      justify-content: flex-start;
    }
    & > div:nth-child(odd) {
    }
  }
`;

export const NoticeContainer = styled.section`
  ${boardInnerCSS(660, 230)};
  margin-bottom: ${pixelToRem(24)};
  ${media.custom(1550)} {
    ${boardInnerCSS(550, 230)};
  }
  .border-content-mobile {
    display: none;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .board-notice-header {
    font-size: ${pixelToRem(22)};
    font-weight: 900;
  }

  ${mediaQuery()} {
    height: ${pixelToRem(60)};
    width: 100%;
    ${flexAlign(undefined, 'flex-start')};
    border: none;
    border-bottom: none;
    border: ${pixelToRemBorder(1, theme.light.border.gray)};
    border-radius: ${pixelToRem(6)};

    .board-header {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
      border: none;
      max-width: 100%;

      .board-notice {
        width: calc(100% - 38px);

        & > div {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .board-notice-header {
          font-size: 1.125rem;
          font-weight: 900;
        }
      }

      .border-content-mobile {
        display: block;

        & > span {
          font-size: ${pixelToRem(15)};
        }
        ${media.custom(400)} {
          font-size: ${pixelToRem(12)};
        }
      }

      & > div > div > div {
        ${flexAlign(undefined, 'flex-start')};
        & > h3 {
          margin-right: ${pixelToRem(16)};
          ${media.custom(400)} {
            font-size: ${pixelToRem(15)};
          }
        }
      }
    }
  }
`;

export const NoticeItemContainer = styled.div`
  ${flexAlign('column', 'center', 'center', 'flex')};
  height: calc(100% - 120px);
  margin-top: 1.2rem;

  ${mediaQuery()} {
    display: none;
  }
`;

export const NoticeItem = styled(Link)`
  font-size: ${pixelToRem(18)};
  padding-left: ${pixelToRem(30)};
  padding-right: ${pixelToRem(20)};
  flex: 1;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  color: ${theme.light.color.black};
  ${flexAlign(undefined, 'space-between')};

  .notice-elilpsis-box {
    flex: 1;

    .notice-elilpsis {
      width: 100%;
      max-width: calc(100% - 106px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .notice-prefix {
        margin-right: ${pixelToRem(5)};
      }
    }
  }

  .notice-vertical-bar {
    margin-right: ${pixelToRem(15)};
    width: ${pixelToRem(1)};
    height: ${pixelToRem(18)};
    background-color: ${theme.light.background.gray};
  }

  .notice-date {
    margin-right: ${pixelToRem(10)};
    color: ${theme.light.color.grayC0C0C0};
    font-size: ${pixelToRem(17)};
    ${flexAlign(undefined, 'flex-start', 'center')};
  }

  ${mediaQuery()} {
  }
`;

export const FAQItemContainer = styled.div`
  height: calc(100% - 80px);
  ${flexAlign('column', 'flex-start', 'flex-start', 'flex')};

  & > a:last-child {
    border-bottom: none;
  }

  ${mediaQuery()} {
    height: calc(100% - 60px);
  }
`;

export const FAQItem = styled(Link)`
  font-size: ${pixelToRem(18)};
  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
  ${flexAlign(undefined, 'flex-start')};
  padding-left: ${pixelToRem(30)};
  flex: 1;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  color: ${theme.light.color.black};

  & > span {
    margin-right: ${pixelToRem(20)};
  }

  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${pixelToRem(17)};
  }

  ${mediaQuery()} {
    font-size: ${pixelToRem(16)};
    height: ${pixelToRem(45)};

    & > span {
      margin-right: ${pixelToRem(10)};

      & > svg {
        display: block;
        width: ${pixelToRem(20)};
        height: ${pixelToRem(20)};
      }
    }
  }
`;

export const FAQContainer = styled.section`
  ${boardInnerCSS(660, 431)};
  ${media.custom(1550)} {
    ${boardInnerCSS(550, 431)};
  }
  box-shadow: 0 ${pixelToRem(1)} ${pixelToRem(5)} 0 rgb(0 0 0 / 8%);
`;

export const BodyLoadingOrEmpty = styled.div`
  font-size: ${pixelToRem(23)};
  margin: auto;
  ${media.medium} {
    font-size: ${pixelToRem(17)};
  }
`;
