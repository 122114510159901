import React from 'react';
import { siteTitleEnglish } from '@static/common';
import { AdminHeaderContainer } from '../admin/styles';
import LoginStatus from './login/LoginStatus';

function SuperAdminHeader() {
  return (
    <AdminHeaderContainer>
      <div>{siteTitleEnglish}</div>
      <LoginStatus />
    </AdminHeaderContainer>
  );
}

export default React.memo(SuperAdminHeader);
