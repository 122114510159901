/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';

type ReturnUseCheckbox = [
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  string[],
  boolean,
  React.Dispatch<React.SetStateAction<string[]>>,
  React.Dispatch<React.SetStateAction<boolean>>,
];

export default function useCheckbox(
  selector: string = 'input[type="checkbox"]',
): ReturnUseCheckbox {
  const [checklist, setCheckList] = useState<string[]>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const onCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsCheckAll((p) => !p);
      const els = document.querySelectorAll(selector);
      const newEls = Array.from(els).filter((c) => c.id !== e.target.id);
      if (isCheckAll) {
        setCheckList([]);
      } else {
        newEls.forEach((el) => {
          setCheckList((p) => [...new Set(p.concat(el.id))]);
        });
      }
    },
    [isCheckAll, selector],
  );

  const onCheckList = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (checklist.includes(e.target.id)) {
        setCheckList((p) => [...new Set(p.filter((c) => c !== e.target.id))]);
      } else {
        setCheckList((p) => [...new Set(p.concat(e.target.id))]);
      }
    },
    [checklist],
  );

  return [
    onCheckAll,
    onCheckList,
    checklist,
    isCheckAll,
    setCheckList,
    setIsCheckAll,
  ];
}
