import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { postFetcher } from '@util/fetcher';
import { mutationKeys } from '@util/queries';
import { getCookie } from '@util/tokenUtil';
import useInputObj from '@hook/useInputObj';
import useModal from '@hook/useModal';
import { accountAddForm } from '@static/admin/admin';
import Modal from '@modal/Modal';
import { TwoButtons } from '../../admin/styles';
import { AccountInputForm, AccountListContainer } from './styles';
import type { AccountCreateType, EmailCheck } from './types';

const obj: { [key: string]: string } = {};

const initForm = accountAddForm.reduce((prev, acc) => {
  const { name } = acc;
  prev[name] = '';
  return prev;
}, obj);

function AccountCreate() {
  const token = getCookie(document.cookie, 'token') || '';
  const navigate = useNavigate();
  const [inputForm, onInputForm] = useInputObj(initForm);
  const fileRef = useRef<HTMLInputElement>(null);
  const [licenseImg, setLicenseImg] = useState<File | undefined>(undefined);

  const onOpenFile = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const { mutate: onCreateAccount } = useMutation<
    AccountCreateType,
    any,
    FormData
  >(
    [mutationKeys.superadmin.createAccount],
    (data) =>
      postFetcher('/superadmin/account', '', false, data, {
        headers: { token },
      }),
    {
      onSuccess: async () => {
        const alertSwal = await swal({
          title: '알림',
          text: '계정이 등록되었습니다!',
          buttons: {
            confirm: {
              closeModal: true,
              text: '확인',
              visible: true,
            },
          },
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
        if (alertSwal) {
          navigate('/superadmin/account/list');
        }
        return;
      },
      onError: () => {
        swal({ title: '알림', text: '등록이 실패되었습니다.' });
      },
    },
  );

  const { mutate: onMutateCheckEmail } = useMutation<EmailCheck, any, FormData>(
    [mutationKeys.superadmin.checkEmail],
    (data: FormData): Promise<any> =>
      postFetcher('/superadmin/account/dupCheck', '', false, data, {
        headers: { token },
      }),
    {
      onSuccess: (res: EmailCheck) => {
        if (res.status === 200) {
          swal({ text: '사용 가능한 이메일입니다.' });
          return;
        }
      },
      onError: (err: Error) => {
        if (err.message.includes('409')) {
          swal({
            title: '에러',
            text: '중복된 이메일입니다.',
            dangerMode: true,
            icon: 'error',
          });
          return;
        }
        swal({
          title: '에러',
          text: '서버와의 연결이 원활하지 않습니다.',
          dangerMode: true,
          icon: 'error',
        });
      },
    },
  );

  const onCheckEmail = useCallback(() => {
    const emailRegExp = /.+@.+\..+/g;
    if (
      !inputForm.email ||
      !inputForm.email.trim() ||
      !emailRegExp.test(inputForm.email)
    ) {
      swal({
        title: '에러',
        text: '이메일 형식이 올바르지 않습니다!',
        icon: 'error',
        dangerMode: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('email', inputForm.email);
    onMutateCheckEmail(formData);
  }, [inputForm, onMutateCheckEmail]);

  const onUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 100 * 1024 * 1024) {
        return;
      }
      setLicenseImg(e.target.files[0]);
    }
    return;
  }, []);

  const onCreate = useCallback(() => {
    if (!token) {
      swal('다시 로그인 해주세요!');
      return;
    }

    const temp = [];

    const emailRegExp = /.+@.+\..+/g;
    const phoneRegExp = /010(\d{8})/g;

    if (!inputForm.name || inputForm.name.trim().length < 1) {
      temp.push('대표자명');
    }

    if (
      !inputForm.phone ||
      inputForm.phone.trim().length < 1 ||
      !phoneRegExp.test(inputForm.phone.trim())
    ) {
      temp.push('연락처');
    }

    if (
      !inputForm.email ||
      inputForm.email.trim().length < 1 ||
      !emailRegExp.test(inputForm.email.trim())
    ) {
      temp.push('이메일');
    }

    if (!inputForm.password || inputForm.password.trim().length < 1) {
      temp.push('비밀번호');
    }

    if (
      !inputForm.passwordConfirm ||
      inputForm.passwordConfirm.trim().length < 1
    ) {
      temp.push('비밀번호 확인');
    }

    if (!inputForm.siteUrl || inputForm.siteUrl.trim().length < 1) {
      temp.push('사이트 주소');
    }

    if (!inputForm.siteName || inputForm.siteName.trim().length < 1) {
      temp.push('사이트명');
    }

    if (!inputForm.companyName || inputForm.companyName.trim().length < 1) {
      temp.push('회사명');
    }

    if (!licenseImg) {
      temp.push('사업자 등록증');
    }

    if (temp.length > 0) {
      swal({
        text: `${temp.join(', ')}을(를) 확인해주세요!`,
        icon: 'error',
        title: '에러',
        dangerMode: true,
      });
      return;
    }

    if (inputForm.password !== inputForm.passwordConfirm) {
      swal({
        text: `비밀번호가 일치하지 않습니다.`,
        icon: 'error',
        title: '에러',
        dangerMode: true,
      });
      return;
    }

    const formData = new FormData();

    const {
      email,
      password,
      name,
      phone,
      siteUrl,
      siteName,
      license,
      companyName,
    } = inputForm;
    formData.append('email', email);
    formData.append('password', password);
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('siteUrl', siteUrl);
    formData.append('siteName', siteName);
    formData.append('license', license);
    formData.append('companyName', companyName);
    formData.append('licenseImage', licenseImg as File);
    onCreateAccount(formData);
  }, [inputForm, onCreateAccount, token, licenseImg]);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const modal = useModal();

  return (
    <>
      <Modal {...modal} />
      <AccountListContainer>
        <h2>사업자 계정 등록</h2>
        <AccountInputForm>
          {accountAddForm.slice(0, 3).map((item) => (
            <div className='account-form' key={item.id}>
              <p className='account-left-form'>{item.text}</p>
              <input
                type={item.type}
                className='account-right-form'
                name={item.name}
                placeholder={item.placeholder}
                value={inputForm[item.name]}
                onChange={onInputForm}
              />
            </div>
          ))}
          <div className='add-label-form'>
            <p className='add-account-label-form'>사업자 등록증</p>
            <div>
              <div className='add-file'>
                <input
                  type='file'
                  hidden
                  ref={fileRef}
                  onChange={onUpload}
                  accept='.pdf,.png,.jpg'
                  multiple={false}
                />
                <label onClick={onOpenFile}>
                  {licenseImg ? licenseImg.name : ''}
                </label>
                <button type='button' onClick={onOpenFile}>
                  불러오기
                </button>
              </div>
              <p>
                * 사업자의 경우 세금계산서 발행 등의 지원을 위해 사업자등록증
                사본을 파일로 업로드 해주시기 바랍니다.
              </p>
              <p>* pdf, jpg, png 파일만 등록 가능합니다.</p>
            </div>
          </div>
          {accountAddForm.slice(3).map((item) => (
            <div className='account-form' key={item.id}>
              <p className='account-left-form'>{item.text}</p>
              <input
                type={item.type}
                className='account-right-form'
                name={item.name}
                placeholder={item.placeholder}
                value={inputForm[item.name]}
                onChange={onInputForm}
              />
              {item.id === 7 && (
                <button type='button' onClick={onCheckEmail}>
                  ID 중복 확인
                </button>
              )}
              {item.id === 9 &&
              inputForm.password !== inputForm.passwordConfirm ? (
                <div className='not-equal-password'>
                  비밀번호가 일치하지 않습니다.
                </div>
              ) : null}
            </div>
          ))}
        </AccountInputForm>
        <TwoButtons>
          <button type='button' onClick={onCreate}>
            등록
          </button>
          <button type='button' onClick={onCancel}>
            취소
          </button>
        </TwoButtons>
      </AccountListContainer>
    </>
  );
}

export default React.memo(AccountCreate);
