import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { flexAlign, pixelToRem } from '@util/commonStyles';
import BannerImg from '@static/images/banner.png';
import theme from '@util/theme';
import { mediaQuery, media } from '@util/mediaQuery';

export const TopBannerContainer = styled.section`
  background-image: url(${BannerImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: ${pixelToRem(500)};
  width: 100%;

  ${flexAlign(undefined, 'flex-start', 'flex-end')};
  .top-banner-inner {
    height: ${`calc(100% - ${pixelToRem(100)})`};
    max-width: ${pixelToRem(1500)};
    width: 100%;
    margin: 0 auto;

    .top-banner-guard {
      height: 100%;
      margin: 0 2rem;
      ${flexAlign(undefined, 'flex-start')};
      color: ${theme.light.color.white};

      .line {
        width: ${pixelToRem(100)};
        height: ${pixelToRem(3)};
        background-color: ${theme.light.background.white};
      }

      .top-banner-header-container {
        font-size: ${pixelToRem(40)};
        margin-top: ${pixelToRem(30)};
        margin-bottom: ${pixelToRem(30)};
        font-weight: 900;
        line-height: 1.3;
      }

      .top-banner-content-container {
        font-size: ${pixelToRem(20)};
        margin-bottom: ${pixelToRem(25)};
        line-height: 1.4;
      }
    }
  }

  ${mediaQuery()} {
    height: ${pixelToRem(430)};
    margin-bottom: ${pixelToRem(25)};
    .top-banner-inner {
      .top-banner-guard {
        width: 100%;
        margin: 0;
        ${flexAlign()};

        div {
          ${flexAlign('column')};

          .line {
            width: ${pixelToRem(50)};
          }

          .top-banner-header {
            font-size: ${pixelToRem(25)};
          }

          .top-banner-content-container {
            font-size: ${pixelToRem(14)};
          }
        }
      }
    }
    ${media.custom(500)} {
      height: ${pixelToRem(350)};
    }
  }
`;

export const LinkRadius = styled(Link)`
  text-decoration: none;
  color: ${theme.light.color.gray707070};
  background-color: ${theme.light.background.white};
  border-radius: ${pixelToRem(50)};
  width: ${pixelToRem(240)};
  height: ${pixelToRem(75)};
  font-size: ${pixelToRem(20)};
  font-weight: 700;
  ${flexAlign()};

  &:visited {
    text-decoration: none;
    color: ${theme.light.color.gray707070};
  }

  ${mediaQuery()} {
    width: ${pixelToRem(120)};
    height: ${pixelToRem(30)};
    font-size: ${pixelToRem(11)};
  }
`;

export const CommonBannerContainer = styled.section`
  background-image: url(${BannerImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: ${pixelToRem(500)};
  ${media.medium} {
    height: ${pixelToRem(430)};
  }
  ${media.custom(500)} {
    height: ${pixelToRem(302)};
  }

  .banner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${`calc(100% - ${pixelToRem(0)})`};
    color: ${theme.light.color.white};
    ${media.custom(600)} {
      margin-top: 3rem;
    }
  }
  .title {
    font-size: ${pixelToRem(40)};
    ${media.custom(600)} {
      font-size: ${pixelToRem(25)};
    }
  }
  .mobile-line {
    display: none;
    ${media.custom(767)} {
      display: flex;
      width: ${pixelToRem(60)};
      height: ${pixelToRem(2)};
      margin: 0 auto 2rem;
      background-color: ${theme.light.background.white};
    }
  }
  .line {
    margin-top: 1.4rem;
    height: ${pixelToRem(2)};
    background-color: ${theme.light.background.white};
    ${media.custom(767)} {
      display: none;
    }
  }
  .sub-title {
    font-size: ${pixelToRem(18)};
    margin-top: -1rem;
    ${media.medium} {
      margin: 0 2rem;
      text-align: center;
    }
    ${media.custom(600)} {
      font-size: ${pixelToRem(15)};
    }
  }
`;
