import React from 'react';
import type { ExchangeRequestType } from '@static/user/default/exchangeRequest';
import { PinInputContainer } from './styles';

type PinInputProps = {
  activeButton: ExchangeRequestType;
  name: string;
  pin: string;
  onPin: React.ChangeEventHandler<Element>;
  orderNumber: number;
  issueNumber: string;
  onIssueNumber: React.ChangeEventHandler<Element>;
  authNumber: string;
  onAuthNumber: React.ChangeEventHandler<Element>;
};

function PinInput({
  orderNumber,
  activeButton,
  name,
  pin,
  onPin,
  issueNumber,
  onIssueNumber,
  authNumber,
  onAuthNumber,
}: PinInputProps) {
  return (
    <PinInputContainer isFix={activeButton.id !== 1}>
      <div>{orderNumber}</div>
      <div className='exchange-request-mobile'>
        <input
          placeholder='핀(PIN) 번호 입력'
          type='tel'
          className='exchange-request-input-pin'
          value={pin}
          name={name}
          onChange={onPin}
        />
        {activeButton.id === 1 ? null : (
          <div className='exchange-request-vertical-bar' />
        )}
        {activeButton.id === 2 ? (
          <input
            placeholder='발행일/인증번호 8자리'
            type='tel'
            className='exchange-request-input-issue'
            value={issueNumber}
            onChange={onIssueNumber}
          />
        ) : null}
        {activeButton.id === 3 ? (
          <input
            placeholder='인증번호 입력'
            type='tel'
            className='exchange-request-input-auth-number'
            value={authNumber}
            onChange={onAuthNumber}
          />
        ) : null}
      </div>
    </PinInputContainer>
  );
}

export default React.memo(PinInput);
