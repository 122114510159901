import styled, { css } from 'styled-components';
import { flexAlign, pixelToRem, pixelToRemBorder } from '@util/commonStyles';
import theme from '@util/theme';

export const commonPadding = () => css`
  padding-left: ${pixelToRem(21)};
`;

type AdminTableBaseCSS = {
  gridTemplateColumn: string;
  color: string;
  firstColor?: string;
  fontSize: string;
  removeBorderRightNumber: number;
};

const adminTableBaseCSS: AdminTableBaseCSS = {
  gridTemplateColumn: '2fr 5fr 2fr 2fr 2fr',
  color: theme.light.color.gray707070,
  fontSize: pixelToRem(17),
  firstColor: theme.light.color.black,
  removeBorderRightNumber: 5,
};

export const adminTableHeadCSS = (
  s: AdminTableBaseCSS = adminTableBaseCSS,
) => css`
  display: grid;
  grid-template-columns: ${s.gridTemplateColumn};
  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
  box-sizing: border-box;

  & > div {
    ${flexAlign()};
    color: ${s.color};
    font-size: ${s.fontSize};
    height: ${pixelToRem(60)};
    border-right: ${pixelToRemBorder(1, theme.light.border.gray)};
    box-sizing: border-box;
  }

  & > div:nth-child(1) {
    color: ${s.firstColor ?? theme.light.color.gray707070};
  }

  & > div:nth-child(${s.removeBorderRightNumber}) {
    border-right: none;
  }
`;

export const adminTableBodyCSS = (
  s: AdminTableBaseCSS = adminTableBaseCSS,
) => css`
  ${adminTableHeadCSS(s)};

  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
`;

export const AdminContainer = styled.div`
  min-width: ${pixelToRem(1920)};
  max-width: ${pixelToRem(1920)};
  background: ${theme.light.background.lightGray};
  min-height: 100%;
  margin: 0 auto;
`;

export const AdminHeaderContainer = styled.header`
  min-width: ${pixelToRem(1920)};
  max-width: ${pixelToRem(1920)};
  height: ${pixelToRem(70)};
  font-size: ${pixelToRem(40)};
  font-weight: 900;
  color: ${theme.light.color.white};

  background-color: ${theme.light.background.navy};
  ${commonPadding()};
  padding-right: ${pixelToRem(40)};

  ${flexAlign(undefined, 'space-between')};
`;

export const DashboardHeader = styled.div`
  background-color: ${theme.light.background.white};
  font-size: ${pixelToRem(25)};
  ${commonPadding()};
  ${flexAlign(undefined, 'flex-start')};
  height: ${pixelToRem(65)};
  box-sizing: border-box;
  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
`;

export const DashboardContainer = styled.article`
  ${commonPadding()};
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
`;

export const DashboardInner = styled.div`
  padding-top: ${pixelToRem(23)};
  padding-bottom: ${pixelToRem(23 * 2)};
  padding-left: ${pixelToRem(23)};
  padding-right: ${pixelToRem(23)};
`;

const statementBaseCSS = () => css`
  background-color: ${theme.light.background.white};
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
`;

export const StatementOperatorContainer = styled.section`
  ${flexAlign(undefined, 'flex-start')};
`;

export const StatementOperator = styled.div`
  ${statementBaseCSS()};
  height: auto;
  width: 100%;
`;

export const HeaderSculpture = styled.header<{ fontSize: number }>`
  font-size: ${({ fontSize }) => pixelToRem(fontSize)};
  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
  height: ${pixelToRem(64)};
  ${flexAlign(undefined, 'flex-start')};
  padding-left: ${pixelToRem(36)};
`;

export const Selection = styled.div`
  padding-left: ${pixelToRem(36)};
  display: flex;
  flex-direction: column;
  padding-top: ${pixelToRem(28)};
  padding-bottom: ${pixelToRem(24)};

  .react-datepicker-wrapper {
    max-width: 240px;
    margin-right: 10px;
    height: 40px;
    .react-datepicker__input-container {
      max-width: 240px;
      input {
        width: 240px;
        height: 40px;
        box-sizing: border-box;
        text-align: center;
      }
    }
  }

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    margin-bottom: ${pixelToRem(21)};
    & > div:first-child {
      font-weight: 700;
      font-size: ${pixelToRem(17)};
      min-width: 100px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    & > div:first-child {
      font-weight: 700;
      font-size: ${pixelToRem(17)};
      width: 100px;
    }

    & > div:last-child {
      display: flex;
      align-items: center;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    align-items: center;
    margin-top: ${pixelToRem(21)};
    & > div:first-child {
      font-weight: 700;
      font-size: ${pixelToRem(17)};
      width: 100px;
    }

    & > div:last-child {
      display: flex;
      align-items: center;
    }
  }
`;

export const SelectionLabel = styled.button<{ isChecked: boolean }>`
  outline: none;
  box-sizing: border-box;
  padding: 0;
  border: ${(props) =>
    props.isChecked ? 'none' : pixelToRemBorder(1, theme.light.border.navy)};
  color: ${({ isChecked }) =>
    isChecked ? theme.light.color.white : theme.light.color.navy};
  font-size: ${pixelToRem(13)};
  background-color: ${(props) =>
    props.isChecked
      ? theme.light.background.navy
      : theme.light.background.white};
  ${flexAlign()};
  cursor: pointer;
  margin-right: ${pixelToRem(12)};
  width: ${pixelToRem(80)};
  min-width: ${pixelToRem(80)};
  height: ${pixelToRem(40)};
`;

export const TwoButtons = styled.div`
  box-sizing: border-box;
  border-top: ${pixelToRemBorder(1, theme.light.border.gray)};
  padding-left: ${pixelToRem(36)};
  padding-top: ${pixelToRem(25)};
  display: flex;
  height: ${pixelToRem(91)};
  button {
    font-size: ${pixelToRem(13)};
    font-weight: 700;
    width: ${pixelToRem(133)};
    height: ${pixelToRem(40)};
    ${flexAlign()};
    outline: none;
    border: none;
  }
  button:first-child {
    background-color: ${theme.light.color.navy};
    color: ${theme.light.color.white};
    margin-right: ${pixelToRem(18)};
  }
  button:last-child {
    background-color: ${theme.light.color.white};
    color: ${theme.light.color.navy};
    border: 1px solid ${theme.light.border.navy};
  }
`;

export const StatementResultContainer = styled.div`
  background-color: ${theme.light.background.white};
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
  margin-top: ${pixelToRem(30)};

  .statement-result-title {
    font-size: ${pixelToRem(20)};
    height: ${pixelToRem(65)};
    font-weight: 900;
    color: ${theme.light.color.black};
    border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
    ${flexAlign(undefined, 'flex-start')};
    padding-left: ${pixelToRem(36)};
  }

  .statement-result-table-container {
    .statement-result-table-wrapper {
      margin-left: ${pixelToRem(36)};
      margin-right: ${pixelToRem(36)};
      margin-top: ${pixelToRem(17)};
      margin-bottom: ${pixelToRem(40)};

      .statement-result-table-top {
        background-color: ${theme.light.background.grayFAFAFC};
        ${flexAlign(undefined, 'flex-end')};
        box-sizing: border-box;
        border: ${pixelToRemBorder(1, theme.light.border.gray)};
        border-bottom: none;
        height: ${pixelToRem(65)};

        select {
          background-color: ${theme.light.color.white};
          color: ${theme.light.color.gray707070};
          font-size: ${pixelToRem(13)};
          font-weight: 700;
          border: ${pixelToRemBorder(1, theme.light.border.gray)};
          width: ${pixelToRem(133)};
          height: ${pixelToRem(40)};
          box-sizing: border-box;
          margin-right: ${pixelToRem(16)};
          padding: 0 ${pixelToRem(10)};
        }

        .excel-download-button {
          ${flexAlign()};
          background-color: ${theme.light.color.white};
          color: ${theme.light.color.gray707070};
          font-size: ${pixelToRem(13)};
          font-weight: 700;
          outline: none;
          border: ${pixelToRemBorder(1, theme.light.border.gray)};
          width: ${pixelToRem(133)};
          height: ${pixelToRem(40)};
          box-sizing: border-box;
          margin-right: ${pixelToRem(16)};
        }
      }

      .statement-result-table-box {
        box-sizing: border-box;
        border: ${pixelToRemBorder(1, theme.light.border.gray)};
        border-bottom: none;

        .statement-result-table-head {
          ${adminTableHeadCSS({
            gridTemplateColumn: '2fr 2fr 1fr 2fr 2fr 2fr 2fr 1fr',
            color: theme.light.color.gray707070,
            fontSize: pixelToRem(17),
            firstColor: theme.light.color.black,
            removeBorderRightNumber: 8,
          })};
        }

        .statement-result-table-body {
          ${adminTableBodyCSS({
            gridTemplateColumn: '2fr 2fr 1fr 2fr 2fr 2fr 2fr 1fr',
            color: theme.light.color.gray707070,
            fontSize: pixelToRem(17),
            firstColor: theme.light.color.black,
            removeBorderRightNumber: 8,
          })};

          .statement-result-table-row-align {
            ${flexAlign()};
            padding: 0 ${pixelToRem(10)};
          }

          .excel-download-button {
            ${flexAlign()};
            background-color: ${theme.light.color.white};
            color: ${theme.light.color.gray707070};
            font-size: ${pixelToRem(13)};
            font-weight: 700;
            outline: none;
            border: ${pixelToRemBorder(1, theme.light.border.gray)};
            padding: 0 ${pixelToRem(20)};
            height: ${pixelToRem(40)};
            box-sizing: border-box;
          }

          .modifyable {
            &:hover {
              cursor: pointer;
              text-decoration: underline;
              color: ${theme.light.color.navy};
            }
          }
        }
      }
    }
  }
`;
