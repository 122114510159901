import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { HiOutlineSearch } from 'react-icons/hi';
import type { NoticeRes } from '@static/user/default/commonTypes';
import { boardHeadText } from '@static/user/default/notice';
import { getfetcher } from '@util/fetcher';
import { UserLayoutContainer } from '@layout/user/home/styles';
import { queryKeys } from '@util/queries';
import Pagination from '../../../Pagination';
import CommonBanner from '../topBanner/CommonBanner';
import {
  TopContainer,
  TableContainer,
  HeadGrid,
  BodyGrid,
  BodyGridNoContent,
  FooterContainer,
} from './styles';

function UserNoticeList() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState<number>(1);
  const [keyword, onKeyWord] = React.useState('');
  const [totalCount, setTotalCount] = React.useState(0);

  const onKeyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onKeyWord(e.target.value);
  }, []);

  const onRead = useCallback(
    (id: number) => () => {
      navigate(`${id}`, {
        state: { id },
      });
    },
    [navigate],
  );

  const {
    data: list,
    isLoading,
    refetch,
  } = useQuery<NoticeRes>(
    [queryKeys.user.noitceList, page, keyword],
    () =>
      getfetcher(
        `/notice?siteId=`,
        `&page=${page}&pageSize=10&keyword=${keyword}`,
        true,
      ),
    {
      onSuccess: (res: NoticeRes) => {
        setTotalCount(res.data.totalCount ?? 0);
      },
      retry: 5,
      refetchInterval: 1000 * 3,
    },
  );

  const onSearchClick = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      <UserLayoutContainer>
        <CommonBanner title='공지사항' text='365일, 24시간 카카오톡 상담가능' />
        <TableContainer>
          <TopContainer>
            <h1>
              공지사항<p>전체 {totalCount ?? 0}건</p>
            </h1>

            <div className='searchBox'>
              <input
                placeholder='검색어를 입력하세요'
                value={keyword}
                onChange={onKeyChange}
                onKeyUp={onSearchClick}
              />
              <HiOutlineSearch size={24} onClick={onSearchClick} />
            </div>
          </TopContainer>
          <HeadGrid>
            {boardHeadText.map((value: string) => (
              <div style={{ fontWeight: 700 }} key={value}>
                {value}
              </div>
            ))}
          </HeadGrid>
          {list && list.data.notices && list.data.notices.length > 0 ? (
            list.data.notices.map((x) => (
              <BodyGrid key={x.id}>
                <div>{x.id}</div>
                <div role='presentation' onClick={onRead(x.id)}>
                  {x.title}
                </div>
                <div>{x.author}</div>
                <div>
                  {new Date(x.createdAt)
                    .toLocaleDateString('ko-KR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                    .replace(/\s+/g, '')
                    .slice(0, -1)}
                </div>
              </BodyGrid>
            ))
          ) : (
            <BodyGridNoContent>
              {isLoading ? '로딩중입니다' : '게시물이 없습니다'}
            </BodyGridNoContent>
          )}
          <FooterContainer>
            <Pagination setPage={setPage} page={page} totalCount={totalCount} />
          </FooterContainer>
        </TableContainer>
      </UserLayoutContainer>
    </>
  );
}

export default React.memo(UserNoticeList);
