import React, { useRef } from 'react';
import styled from 'styled-components';
import { CgClose } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import theme from '@util/theme';
import { flexAlign, pixelToRem, pixelToRemBorder } from '@util/commonStyles';
import { mediaQuery } from '@util/mediaQuery';

const navList = [
  { id: 1, to: '/user/exchange', text: '자동 상품권 교환' },
  { id: 2, to: '/user/realtime', text: '실시간 교환 현황' },
  { id: 3, to: '/user/notice', text: '공지사항' },
  { id: 4, to: '/user/faq', text: '자주 묻는 질문' },
  { id: 5, to: '/user/mylist', text: '내 교환내역' },
];

function DrawerNavigation() {
  const ref = useRef<HTMLElement>(null);
  const onClose = React.useCallback(() => {
    if (ref.current) {
      ref.current.style.transform = 'translateX(-100%)';
    }
  }, []);

  return (
    <NavigationContainer isVisible ref={ref} id='drawer-navigation'>
      <div className='drawer-close'>
        <div className='drawer-close-btn' role='presentation' onClick={onClose}>
          <CgClose size={40} />
        </div>
      </div>
      <div className='drawer-header'>
        <p>가장 안전하고 신뢰받는</p>
        <p>티켓 거래소 캐시핀</p>
      </div>
      <div className='drawer-nav-inner'>
        {navList.map((item) => (
          <NavigationNavigator key={item.id} to={item.to}>
            {item.text}
          </NavigationNavigator>
        ))}
      </div>
    </NavigationContainer>
  );
}

export const NavigationContainer = styled.nav<{ isVisible: boolean }>`
  display: none;
  flex-direction: column;
  background-color: ${theme.light.background.white};
  transition: 0.8s;
  z-index: 9999;
  overflow: auto;
  position: fixed;
  height: 100%;
  top: 45px;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);

  .drawer-close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${pixelToRem(50)};

    .drawer-close-btn {
      margin-top: 5%;
      margin-right: 5%;
      cursor: pointer;
    }
  }

  .drawer-header {
    font-size: ${pixelToRem(27)};
    color: ${theme.light.color.gray707070};
    font-weight: 900;
    text-align: center;
    line-height: 1.5;
    margin-bottom: ${pixelToRem(50)};
    & > p {
      margin: 0;
    }
  }

  .drawer-nav-inner {
    padding-left: ${pixelToRem(40)};
    padding-right: ${pixelToRem(40)};
  }

  ${mediaQuery()} {
    display: flex;
  }
`;

export const NavigationNavigator = styled(Link)`
  border-radius: ${pixelToRem(6)};
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
  /* padding-left: ${pixelToRem(30)}; */
  color: ${theme.light.color.gray707070};
  font-size: ${pixelToRem(18)};
  font-weight: 700;
  height: ${pixelToRem(30)};
  margin-bottom: ${pixelToRem(20)};
  ${flexAlign(undefined, 'flex-start')};
  padding: 23px 0 23px 30px;
`;

export default React.memo(DrawerNavigation);
