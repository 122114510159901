export type ExchangeRequestType = {
  id: number;
  menu: string;
  fee: {
    header: string;
    contents: string[];
  };
};

export type ExchangeData = {
  name: string;
  phone: string;
  account: string;
  bankName: string;
  voucherId: number;
  pinNumber: string;
  // happypinNumber: string;
  // bookpinNumber: string;
};

export const exchangeRequestObj: ExchangeRequestType[] = [
  {
    id: 1,
    menu: '컬쳐랜드 문화상품권',
    fee: {
      header: '',
      contents: [''],
    },
  },
];

export const exchangeRequestExplainText = [
  '핀번호 자동 추출기를 사용해보세요!',
  '핀번호 입력 → 교환신청 하기로 고객님께서 가지고 계신 상품권을 현금으로 교환해 드립니다!',
];

export const feeExplain = {
  header: '매입 수수료는 10%입니다.',
  contents: [
    '- 거래건에 대해 건별로 500원의 계좌이체 수수료가 부과됩니다.',
    '- 매입 신청 시 수수료를 제외하고 바로 입금됩니다.',
    '- 교환권은 핀(PIN)번호로 변경 후 입력해주세요.',
  ],
};

export const pinText = {
  left: {
    header: '핀(PIN)번호 자동 추출기',
    subHeader: [
      '- 온라인/모바일 상품권의 경우 전송받은 문자 내용을 아래에 입력 후',
      `\xa0\xa0'핀(PIN)번호 추출' 버튼을 클릭하면 자동으로 핀번호가 추출됩니다.`,
    ],
  },
  right: {
    header: '핀(PIN)번호 입력',
    subHeader: [
      {
        content: [
          '- 컬쳐랜드 핀(PIN)번호 18자리를 입력해주세요.',
          `- 핀(PIN)번호 복사 후 붙여넣기가 가능합니다.`,
        ],
      },
      {
        content: [
          '- 해피머니 핀(PIN)번호 16자리와 인증번호 8자리를 입력해주세요.',
          `- 핀(PIN)번호 복사 후 붙여넣기가 가능합니다.`,
        ],
      },
      {
        content: [
          '- 북앤라이프 핀(PIN)번호 16자리와 인증번호 4자리를 입력해주세요.',
          `- 핀(PIN)번호 복사 후 붙여넣기가 가능합니다.`,
        ],
      },
    ],
  },
};

export const depositText = {
  header: '교환 신청',
  subHeader: [
    '- 계좌번호를 정확하게 입력해주세요. ',
    '- 계좌번호와 입금자명을 정확하게 기입해주세요.',
    '- 입금자명이 잘못된 경우 송금이 거부될 수 있습니다.',
  ],
};

export const sliceEndSwitcher = (id: number): number => {
  switch (id) {
    case 1:
      return 18 + 3;
    case 2:
      return 16 + 3;
    case 3:
      return 15 + 4;
    default:
      return 1;
  }
};

export type InputNum = {
  [key: string]: {
    id: number;
    data: string;
    issue: string;
    auth: string;
  };
};

export const basePin: InputNum = {
  '1': { id: 1, data: '', issue: '', auth: '' },
  '2': { id: 2, data: '', issue: '', auth: '' },
  '3': { id: 3, data: '', issue: '', auth: '' },
  '4': { id: 4, data: '', issue: '', auth: '' },
  '5': { id: 5, data: '', issue: '', auth: '' },
};
type OptionBank = {
  value: string;
  bank: string;
};

export const bankGroup: Array<OptionBank> = [
  { value: '000', bank: '은행선택' },
  { value: '001', bank: '한국은행' },
  { value: '002', bank: '산업은행' },
  { value: '003', bank: '기업은행' },
  { value: '004', bank: '국민은행' },
  { value: '007', bank: '수협은행' },
  { value: '008', bank: '수출입은행' },
  { value: '011', bank: 'NH농협은행' },
  { value: '012', bank: '지역농․축협' },
  { value: '020', bank: '우리은행' },
  { value: '023', bank: 'SC제일은행' },
  { value: '027', bank: '한국씨티은행' },
  { value: '031', bank: '대구은행 ' },
  { value: '032', bank: '부산은행 ' },
  { value: '034', bank: '광주은행 ' },
  { value: '035', bank: '제주은행 ' },
  { value: '037', bank: '전북은행 ' },
  { value: '039', bank: '경남은행 ' },
  { value: '041', bank: '우리카드 ' },
  { value: '045', bank: '새마을금고' },
  { value: '048', bank: '신협' },
  { value: '050', bank: '저축은행' },
  { value: '052', bank: '모건스탠리은행' },
  { value: '054', bank: 'HSBC은행' },
  { value: '055', bank: '도이치은행' },
  { value: '057', bank: '제이피모간체이스행' },
  { value: '058', bank: '미즈호은행' },
  { value: '059', bank: '엠유에프지은행' },
  { value: '060', bank: 'BOA은행' },
  { value: '061', bank: '비엔피파리바은행' },
  { value: '062', bank: '중국공상은행' },
  { value: '063', bank: '중국은행 ' },
  { value: '064', bank: '산림조합중앙회' },
  { value: '065', bank: '대화은행' },
  { value: '066', bank: '교통은행' },
  { value: '067', bank: '중국건설은행' },
  { value: '071', bank: '우체국' },
  { value: '076', bank: '신용보증기금' },
  { value: '077', bank: '기술보증기금' },
  { value: '081', bank: '하나은행' },
  { value: '088', bank: '신한은행' },
  { value: '089', bank: '케이뱅크' },
  { value: '090', bank: '카카오뱅크' },
  { value: '092', bank: '토스뱅크' },
  { value: '101', bank: '한국신용정보원' },
  { value: '102', bank: '대신저축은행' },
  { value: '103', bank: '에스비아이저축은행' },
  { value: '104', bank: '에이치케이저축은행' },
  { value: '105', bank: '웰컴저축은행' },
  { value: '106', bank: '신한저축은행' },
  { value: '209', bank: '유안타증권' },
  { value: '218', bank: 'KB증권' },
  { value: '221', bank: '상상인증권' },
  { value: '222', bank: '한양증권' },
  { value: '223', bank: '리딩투자증권' },
  { value: '224', bank: 'BNK투자증권' },
  { value: '225', bank: 'IBK투자증권' },
  { value: '227', bank: 'KTB투자증권' },
  { value: '238', bank: '미래에셋증권' },
  { value: '240', bank: '삼성증권' },
  { value: '243', bank: '한국투자증권' },
  { value: '247', bank: 'NH투자증권' },
  { value: '261', bank: '교보증권' },
  { value: '262', bank: '하이투자증권' },
  { value: '263', bank: '현대차증권' },
  { value: '264', bank: '키움증권' },
  { value: '265', bank: '이베스트투자증권' },
  { value: '266', bank: 'SK증권' },
  { value: '267', bank: '대신증권' },
  { value: '269', bank: '한화투자증권' },
  { value: '270', bank: '하나금융투자' },
  { value: '271', bank: '토스증권' },
  { value: '272', bank: 'NH선물' },
  { value: '278', bank: '신한금융투자' },
  { value: '279', bank: 'DB금융투자' },
  { value: '280', bank: '유진투자증권' },
  { value: '287', bank: '메리츠증권' },
  { value: '288', bank: '카카오페이증권' },
  { value: '290', bank: '부국증권' },
  { value: '291', bank: '신영증권' },
  { value: '292', bank: '케이프투자증권' },
];
