const date = new Date().toLocaleTimeString('ko-KO', {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'Asia/Seoul',
});

const date2 = new Date().toLocaleTimeString('ko-KO', {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'Asia/Seoul',
});

export const dummyData = [
  {
    title: '컬쳐랜드 문화상품권 50,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 30,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 20,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 100,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 15,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 250,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 50,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 70,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 30,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 40,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
  {
    title: '컬쳐랜드 문화상품권 140,000원',
    소요시간: '00분 25초',
    start: date,
    end: date2,
  },
];

export const boradDummy = [
  { title: '컬쳐랜드 문화상품권 5,000원' },
  { title: '컬쳐랜드 문화상품권 15,000원' },
  { title: '컬쳐랜드 문화상품권 210,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 40,000원' },
  { title: '컬쳐랜드 문화상품권 50,000원' },
  { title: '컬쳐랜드 문화상품권 50,000원' },
  { title: '컬쳐랜드 문화상품권 70,000원' },
  { title: '컬쳐랜드 문화상품권 85,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 50,000원' },
  { title: '컬쳐랜드 문화상품권 64,000원' },
  { title: '컬쳐랜드 문화상품권 82,000원' },
  { title: '컬쳐랜드 문화상품권 12,000원' },
  { title: '컬쳐랜드 문화상품권 40,000원' },
  { title: '컬쳐랜드 문화상품권 7,000원' },
  { title: '컬쳐랜드 문화상품권 110,000원' },
  { title: '컬쳐랜드 문화상품권 75,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 85,000원' },
  { title: '컬쳐랜드 문화상품권 61,000원' },
  { title: '컬쳐랜드 문화상품권 50,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 150,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 80,000원' },
  { title: '컬쳐랜드 문화상품권 82,000원' },
  { title: '컬쳐랜드 문화상품권 27,000원' },
  { title: '컬쳐랜드 문화상품권 55,000원' },
  { title: '컬쳐랜드 문화상품권 120,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 50,000원' },
  { title: '컬쳐랜드 문화상품권 27,000원' },
  { title: '컬쳐랜드 문화상품권 12,000원' },
  { title: '컬쳐랜드 문화상품권 27,000원' },
  { title: '컬쳐랜드 문화상품권 100,000원' },
  { title: '컬쳐랜드 문화상품권 61,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 85,000원' },
  { title: '컬쳐랜드 문화상품권 25,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 50,000원' },
  { title: '컬쳐랜드 문화상품권 150,000원' },
  { title: '컬쳐랜드 문화상품권 30,000원' },
  { title: '컬쳐랜드 문화상품권 200,000원' },
  { title: '컬쳐랜드 문화상품권 50,000원' },
  { title: '컬쳐랜드 문화상품권 50,000원' },
  { title: '컬쳐랜드 문화상품권 110,000원' },
  { title: '컬쳐랜드 문화상품권 50,000원' },
  { title: '컬쳐랜드 문화상품권 37,000원' },
  { title: '컬쳐랜드 문화상품권 15,000원' },
  { title: '컬쳐랜드 문화상품권 31,000원' },
  { title: '컬쳐랜드 문화상품권 120,000원' },
  { title: '컬쳐랜드 문화상품권 5,000원' },
  { title: '컬쳐랜드 문화상품권 40,000원' },
  { title: '컬쳐랜드 문화상품권 52,000원' },
  { title: '컬쳐랜드 문화상품권 20,000원' },
  { title: '컬쳐랜드 문화상품권 55,000원' },
  { title: '컬쳐랜드 문화상품권 170,000원' },
  { title: '컬쳐랜드 문화상품권 420,000원' },
];
