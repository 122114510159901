export const mediaQuery = (maxWidth: number = 1280) => `
  @media (max-width: ${maxWidth}px)
`;

export const media = {
  medium: mediaQuery(1280),
  custom: mediaQuery,
  size: {
    medium: '1280px',
  },
};
