import React from 'react';
import theme from '@util/theme';
import {
  ModalBlock,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalButton,
  ModalButtonContainer,
} from './styles';

type ModalProps = {
  hidden: boolean;
  onHidden: () => void;
  flex?: number;
  headerFontSize?: number;
  headerText?: string;
  bodyFontSize?: number;
  mainText?: string;
  subText?: string;
  bodyText?: string;
  onClick?: () => void;
  isVisibleButton?: boolean;
  bodyChildren?: React.ReactNode;
};

function Modal({
  hidden,
  onHidden,
  flex,
  mainText,
  subText,
  headerText,
  headerFontSize,
  bodyFontSize,
  bodyText,
  onClick = () => {},
  bodyChildren,
  isVisibleButton = true,
}: ModalProps) {
  return (
    <ModalContainer hidden={hidden}>
      <ModalBlock flex={flex}>
        <ModalHeader fontSize={headerFontSize}>
          {headerText ?? '알림'}
        </ModalHeader>
        <ModalBody fontSize={bodyFontSize}>
          {bodyText ?? '선택하신 사업자를 삭제하시겠습니까?'}
          {bodyChildren ?? null}
        </ModalBody>
        <ModalButtonContainer>
          <ModalButton
            color={theme.light.color.gray707070}
            bgColor={theme.light.background.white}
            borderWidth={1}
            borderColor={theme.light.border.gray707070}
            onClick={onHidden}
          >
            {subText ?? '취소'}
          </ModalButton>
          {isVisibleButton ? (
            <ModalButton color={theme.light.color.white} onClick={onClick}>
              {mainText ?? '확인'}
            </ModalButton>
          ) : null}
        </ModalButtonContainer>
      </ModalBlock>
    </ModalContainer>
  );
}

export default React.memo(Modal);
