import React from 'react';
// import { useQuery } from 'react-query';
import { FiPlusSquare } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { realtimeHeadColumn } from '@static/user/default/exchange';
import theme from '@util/theme';
// import { getfetcher } from '@util/fetcher';
// import { RealTimeRes } from '@static/user/default/commonTypes';
// import { queryKeys } from '@util/queries';
import { RandomName } from '@util/randomName';
import { boradDummy } from '@static/user/default/dummyData';
import {
  RealtimeExchangeContainer,
  RealtimeListHead,
  RealtimeListBody,
  RealtimeListBodyContainer,
} from './styles';
import RoundButton from '../../../RoundButton';

function RealtimeExchange() {
  // const { data } = useQuery<RealTimeRes>([queryKeys.user.exchangeHistory], () =>
  //   getfetcher('/exchange/', '?page=1&pageSize=10', true),
  // );

  return (
    <RealtimeExchangeContainer>
      <div className='board-header'>
        <h3>실시간 교환현황</h3>
        <Link to='/user/realtime'>
          <FiPlusSquare size={32} color={theme.light.color.gray} />
        </Link>
      </div>
      <RealtimeListHead>
        {realtimeHeadColumn.map((value) => (
          <div key={value}>{value}</div>
        ))}
      </RealtimeListHead>
      <RealtimeListBodyContainer>
        {boradDummy
          .sort(() => Math.random() - 0.5)
          .map((item, index) => (
            <RealtimeListBody key={index}>
              {/* <div><ProcessStatusBtn status={item.status} /></div> */}
              <div>
                <RoundButton
                  borderWidth={0}
                  bgColor={theme.light.background.lightGreen}
                  fontSize={17}
                  fontWeight={700}
                  width={96}
                  height={35}
                  cursor='initial'
                  fontColor={theme.light.color.white}
                  mediaQueryFontSize={17}
                  mediaQueryWidth={96}
                  mediaQueryHeight={35}
                >
                  입금완료
                </RoundButton>
              </div>
              <div className='realtime-exchange-web'>{item.title}</div>
              <div className='realtime-exchange-mobile'>
                {item.title.replace('문화상품권', '').replace('상품권', '')}
              </div>
              <div>{RandomName()}</div>
              <div>
                00분
                {Math.floor(Math.random() * 40 + 20)}초
              </div>
            </RealtimeListBody>
          ))}
      </RealtimeListBodyContainer>
    </RealtimeExchangeContainer>
  );
}

export default React.memo(RealtimeExchange);

// {data &&
//   data.data &&
//   data.data.exchanges &&
//   data.data.exchanges.length > 0 &&
//   data.data.exchanges.map((item) => {
//     const diff = dayjs(item.completedAt).diff(item.applicatedAt, 's');
//     const seconds = 60;
//     const diffMinute = (diff / seconds).toFixed(0);
//     const diffSecond = (diff % seconds).toFixed(0);

//     return (
//       <RealtimeListBody key={item.id}>
//         <div>
//           <ProcessStatusBtn status={item.status} />
//         </div>
//         <div className='realtime-exchange-web'>
//           {`${switchVoucherType(item.voucherType)} `}
//           {/* ${item.price.toLocaleString('ko-KR')}원` */}
//         </div>
//         <div className='realtime-exchange-mobile'>
//           {`${switchVoucherType(item.voucherType)
//             .replace('문화상품권', '')
//             .replace('상품권', '')}`}
//           {`${item.price.toLocaleString('ko-KR')}원`}
//         </div>
//         <div>{item.holderName}</div>
//         <div>
//           {diffMinute}분 {diffSecond}초
//         </div>
//       </RealtimeListBody>
//     );
//   })}
