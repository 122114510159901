import styled, { css } from 'styled-components';
import {
  flexAlign,
  inputChecked,
  pixelToRem,
  pixelToRemBorder,
} from '@util/commonStyles';
import theme from '@util/theme';

const statementBaseCSS = () => css`
  background-color: ${theme.light.background.white};
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
`;

export const StatementOperatorContainer = styled.section`
  ${flexAlign(undefined, 'flex-start')};
`;

export const StatementOperator = styled.div`
  ${statementBaseCSS()};
  height: ${pixelToRem(370)};
  width: 100%;
`;

export const SiteStatementContainer = styled.div`
  background-color: ${theme.light.background.white};
  box-sizing: border-box;
  margin-top: ${pixelToRem(20)};
  width: ${pixelToRem(1600)};
  height: ${pixelToRem(800)};
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
`;

type AdminTableBaseCSS = {
  gridTemplateColumn: string;
  color: string;
  firstColor?: string;
  fontSize: string;
  removeBorderRightNumber: number;
};

const adminTableBaseCSS: AdminTableBaseCSS = {
  gridTemplateColumn: '60px 1fr 1fr 1fr 1fr',
  color: theme.light.color.gray707070,
  fontSize: pixelToRem(17),
  firstColor: theme.light.color.black,
  removeBorderRightNumber: 5,
};

export const adminTableHeadCSS = (
  s: AdminTableBaseCSS = adminTableBaseCSS,
) => css`
  display: grid;
  grid-template-columns: ${s.gridTemplateColumn};
  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
  box-sizing: border-box;

  & > div {
    ${flexAlign()};
    color: ${s.color};
    font-size: ${s.fontSize};
    height: ${pixelToRem(60)};
    border-right: ${pixelToRemBorder(1, theme.light.border.gray)};
    box-sizing: border-box;
  }

  & > div:nth-child(1) {
    color: ${s.firstColor ?? theme.light.color.gray707070};
  }

  & > div:nth-child(${s.removeBorderRightNumber}) {
    border-right: none;
  }
`;

export const adminTableBodyCSS = (
  s: AdminTableBaseCSS = adminTableBaseCSS,
) => css`
  ${adminTableHeadCSS(s)};

  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
`;

export const StatementResultContainer = styled.section<{
  s?: AdminTableBaseCSS;
}>`
  background-color: ${theme.light.background.white};
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
  margin-top: ${pixelToRem(30)};

  .statement-result-title {
    font-size: ${pixelToRem(20)};
    height: ${pixelToRem(65)};
    font-weight: 900;
    color: ${theme.light.color.black};
    border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
    ${flexAlign(undefined, 'flex-start')};
    padding-left: ${pixelToRem(36)};
  }

  .statement-result-table-container {
    .statement-result-table-wrapper {
      margin-left: ${pixelToRem(36)};
      margin-right: ${pixelToRem(36)};
      margin-top: ${pixelToRem(17)};
      margin-bottom: ${pixelToRem(40)};

      .statement-result-table-top {
        background-color: ${theme.light.background.grayFAFAFC};
        ${flexAlign(undefined, 'flex-end')};
        box-sizing: border-box;
        border: ${pixelToRemBorder(1, theme.light.border.gray)};
        border-bottom: none;
        height: ${pixelToRem(65)};

        .excel-download-button {
          ${flexAlign()};
          background-color: ${theme.light.color.white};
          color: ${theme.light.color.gray707070};
          font-size: ${pixelToRem(13)};
          font-weight: 700;
          outline: none;
          border: ${pixelToRemBorder(1, theme.light.border.gray)};
          width: ${pixelToRem(133)};
          height: ${pixelToRem(40)};
          box-sizing: border-box;
          margin-right: ${pixelToRem(16)};
        }
      }

      .statement-result-table-box {
        box-sizing: border-box;
        border: ${pixelToRemBorder(1, theme.light.border.gray)};
        border-bottom: none;

        ${inputChecked()};

        .statement-result-table-head {
          ${adminTableHeadCSS({
            gridTemplateColumn:
              '60px 1fr 2fr 2fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr',
            color: theme.light.color.gray707070,
            fontSize: pixelToRem(16),
            firstColor: theme.light.color.black,
            removeBorderRightNumber: 12,
          })};
        }

        .statement-result-table-body {
          ${adminTableBodyCSS({
            gridTemplateColumn:
              '60px 1fr 2fr 2fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr',
            color: theme.light.color.gray707070,
            fontSize: pixelToRem(16),
            firstColor: theme.light.color.black,
            removeBorderRightNumber: 12,
          })};
        }
      }
    }
  }
`;
