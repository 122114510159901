import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { flexAlign, pixelToRem } from '@util/commonStyles';
import theme from '@util/theme';
import {
  getCookie,
  removeCookie,
  decodedToken,
  authToken,
} from '@util/tokenUtil';

const navigateUrl = '/admin/login';

function LoginStatus() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');

  const onLogout = useCallback(() => {
    removeCookie('token');
    navigate(navigateUrl);
  }, [navigate]);

  useEffect(() => {
    const token = getCookie(document.cookie, 'token');
    if (authToken(1) && token) {
      const parsedToken = decodedToken();
      setUserId(parsedToken.email);
    } else {
      navigate(navigateUrl);
    }
  }, [navigate]);

  return (
    <LoginStatusContainer>
      <p className='user'>{userId}</p>
      <div />
      <p
        role='presentation'
        className='logout'
        onClick={onLogout}
        onKeyUp={() => {}}
      >
        로그아웃
      </p>
    </LoginStatusContainer>
  );
}

export const LoginStatusContainer = styled.div`
  ${flexAlign(undefined, 'flex-start')};

  p {
    margin: 0;
  }

  .user {
    font-size: ${pixelToRem(17)};
    margin-right: ${pixelToRem(10)};
  }

  div {
    width: ${pixelToRem(1)};
    height: ${pixelToRem(17)};
    background-color: ${theme.light.background.white};
    margin-right: ${pixelToRem(10)};
  }

  .logout {
    font-size: ${pixelToRem(17)};
    cursor: pointer;
  }
`;

export default React.memo(LoginStatus);
