import React from 'react';
import { useLocation } from 'react-router-dom';
import { menus } from '@static/admin/admin';
import { SidaBarContainer, SideBarList, SidebarMenu } from './styles';

function SideBar() {
  const location = useLocation();

  return (
    <>
      <SidaBarContainer>
        <SideBarList>
          {menus.map((item) => (
            <SidebarMenu
              key={item.id}
              to={item.path}
              isselected={location.pathname === item.path ? 'true' : 'false'}
            >
              {item.name}
            </SidebarMenu>
          ))}
        </SideBarList>
      </SidaBarContainer>
    </>
  );
}

export default React.memo(SideBar);
