import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';
import axios, { AxiosResponse } from 'axios';
import {
  HeaderSculpture,
  Selection,
  SelectionLabel,
  TwoButtons,
} from '../../admin/styles';
import {
  StatementOperator,
  StatementOperatorContainer,
  StatementResultContainer,
} from './styles';
import 'react-datepicker/dist/react-datepicker.css';
import dateFormatter from '../../../utils/dateFormatter';
import {
  getfetcher,
  getSiteId,
  postFetcher,
  Res,
} from '../../../utils/fetcher';
import { mutationKeys, queryKeys } from '../../../utils/queries';
import Pagination from '../../Pagination';
import type { SiteInfo, SiteInfos, StatementTable } from './types';
import { decodedToken, getCookie } from '../../../utils/tokenUtil';
import useCheckbox from '../../../hooks/useCheckbox';
import { ProcessStatus } from '../../admin/types';
import { ChargingStatusToText, DepositStatusToText } from './utils';

const dateFormat = 'yyyy-MM-dd';

function CalcDate(a: any, b: any) {
  const getTime = Number(a.getTime() - b.getTime());
  const time = getTime / 1000 / 60 / 60 / 24;
  return Math.ceil(time);
}

const siteList = [
  // { key: 'ALL', value: '전체' },
  { key: 'CULTURELAND', value: '컬쳐랜드' },
  // { key: 'HAPPYMONEY', value: '해피머니' },
  // { key: 'BOOKNLIKE', value: '도서상품권' },
];

const statusList: { key: string; value: ProcessStatus }[] = [
  { key: '충전요청', value: 'REQUESTED' },
  { key: '충전완료', value: 'COMPLETED' },
  { key: '충전거부', value: 'REJECTED' },
  { key: '입금대기', value: 'WAITDEPOSIT' },
  { key: '입금요청', value: 'RQSTDEPOSIT' },
  { key: '입금완료', value: 'CPLTDEPOSIT' },
  { key: '입금실패', value: 'FAILDEPOSIT' },
  { key: '입금거절', value: 'RJTDDEPOSIT' },
];

const searchList = [
  { id: 1, diff: 0, text: '오늘' },
  { id: 2, diff: 7, text: '일주일' },
  { id: 3, diff: 30, text: '1개월' },
];
function Statement() {
  const token = getCookie(document.cookie, 'token') || '';
  const [siteId, setSiteId] = useState(0);
  const [onCheckAll, onCheckList, checklist, isCheckAll] = useCheckbox();

  useEffect(() => {
    const parsedToken = decodedToken();
    if (Object.keys(parsedToken).length === 0) {
      setSiteId(parsedToken.siteId as number);
    }
  }, []);

  const [choiceDay, setChoiceDay] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [choiceSite, setChoiceSite] = useState(0);
  const [choiceStatus, setChoiceStatus] = useState(0);

  const onChoiceSite = useCallback(
    (id: number) => () => {
      setChoiceSite(id);
    },
    [],
  );

  const onSubtractDay = useCallback(
    (diff: number) => () => {
      setChoiceDay({
        ...choiceDay,
        start: dayjs(choiceDay.end).subtract(diff, 'day').toDate(),
      });
    },
    [choiceDay],
  );

  const onReset = useCallback(() => {
    setChoiceSite(0);
    setChoiceDay({ start: new Date(), end: new Date() });
  }, []);

  const [page, setPage] = React.useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [siteInfo, setSiteInfo] = React.useState<SiteInfo[]>([]);

  const getSiteInfo = React.useCallback(async () => {
    const res = await axios.get<any, AxiosResponse<Res<SiteInfos>>>(
      '/superadmin/site',
      { headers: { token } },
    );
    setSiteInfo(res.data.data.sites);
  }, [token]);

  useEffect(() => {
    getSiteInfo();
  }, [getSiteInfo]);

  const { data: list, refetch } = useQuery<Res<StatementTable>>(
    [
      queryKeys.superadmin.SiteStatement,
      siteId,
      page,
      choiceDay.start,
      choiceDay.end,
      choiceStatus,
    ],
    () =>
      getfetcher(
        `/superadmin/exchange/status/${
          statusList[choiceStatus].value
        }?page=${page}&pageSize=10&voucherType=CULTURELAND&startDate=${dayjs(
          choiceDay.start,
        ).format('YYYY-MM-DDT00:00:00+09:00')}&endDate=${dayjs(
          choiceDay.end,
        ).format('YYYY-MM-DDT23:59:59+09:00')}`,
        '',
        false,
        {
          headers: { token },
        },
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onSuccess: (result: Res<StatementTable>) => {
        setTotalCount(result.data.exchanges.length ?? 0);
      },
      enabled: !!token,
    },
  );

  const onChoiceStartDay = useCallback(
    (date: any) => setChoiceDay((p) => ({ ...p, start: new Date(date) })),
    [],
  );

  const onChoiceEndDay = useCallback(
    (date: any) => setChoiceDay((p) => ({ ...p, end: new Date(date) })),
    [],
  );

  const onSearch = useCallback(() => {
    refetch<Res<StatementTable>>();
  }, [refetch]);

  const [modifyId, setModifyId] = useState(-1);

  const onChoiceStatus = useCallback(
    (id: number) => () => {
      setChoiceStatus(id);
    },
    [],
  );

  const { mutate } = useMutation<Res<string>, unknown, any>(
    [mutationKeys.admin.modifyName, modifyId],
    (data: any) =>
      postFetcher(`/admin/exchange/redeposit`, '', false, data, {
        headers: { token },
      }),
    {},
  );

  const onModify = useCallback(async () => {
    if (checklist.length !== 1) {
      swal('1개만 선택할 수 있습니다.');
      return;
    }

    const target = checklist[0]
      .slice()
      .replaceAll('superadmin-checkboxes-', '');

    setModifyId(Number(target));

    const formData = new FormData();
    // barrier start
    const {
      data: {
        site: { id },
      },
    } = await getSiteId();

    const price =
      list?.data.exchanges.find((k) => k.chargeId === Number(modifyId))
        ?.chargedPrice || 0;

    if (price === 0) {
      swal('입금액이 0원이므로 수정할 수 없습니다.');
      return;
    }
    formData.append('siteId', id.toString());
    formData.append('chargeId', modifyId.toString());
    formData.append('status', statusList[choiceStatus].value);
    formData.append('price', price.toString());
    // barrier end

    mutate(formData);
    setModifyId(-1);
  }, [mutate, modifyId, choiceStatus, list?.data.exchanges, checklist]);

  return (
    <>
      <StatementOperatorContainer>
        <StatementOperator>
          <HeaderSculpture fontSize={20}>통계</HeaderSculpture>
          <Selection>
            <div>
              <div>조회기간</div>
              {searchList.map((value) => (
                <SelectionLabel
                  type='button'
                  key={value.id}
                  isChecked={
                    CalcDate(choiceDay.end, choiceDay.start) === value.diff
                  }
                  onClick={onSubtractDay(value.diff)}
                >
                  {value.text}
                </SelectionLabel>
              ))}
              <DatePicker
                startDate={new Date()}
                onChange={onChoiceStartDay}
                value={dateFormatter(choiceDay.start.toLocaleDateString('ko'))}
                dateFormat={dateFormat}
                className='date-picker-input'
              />
              <div>&nbsp;&nbsp;~&nbsp;&nbsp;</div>
              <DatePicker
                startDate={new Date()}
                onChange={onChoiceEndDay}
                value={dateFormatter(choiceDay.end.toLocaleDateString('ko'))}
                dateFormat={dateFormat}
                className='date-picker-input'
              />
            </div>
            <div>
              <div>사이트 선택</div>
              <div>
                {siteList.map((item, index) => (
                  <SelectionLabel
                    type='button'
                    key={item.key}
                    isChecked={choiceSite === index}
                    onClick={onChoiceSite(index)}
                  >
                    {item.value}
                  </SelectionLabel>
                ))}
              </div>
            </div>
            <div>
              <div>상태</div>
              <div>
                {statusList.map(({ key, value }, index) => (
                  <SelectionLabel
                    type='button'
                    key={`status-${value}`}
                    isChecked={choiceStatus === index}
                    onClick={onChoiceStatus(index)}
                  >
                    {key}
                  </SelectionLabel>
                ))}
              </div>
            </div>
          </Selection>
          <TwoButtons>
            <button type='button' onClick={onSearch}>
              검색
            </button>
            <button type='button' onClick={onReset}>
              초기화
            </button>
          </TwoButtons>
        </StatementOperator>
      </StatementOperatorContainer>
      <StatementResultContainer>
        <div className='statement-result-title'>
          {`전체 사이트 통계 (${
            (list && list.data && list.data.exchanges.length) ?? 0
          }건)`}
        </div>
        <div className='statement-result-table-container'>
          <div className='statement-result-table-wrapper'>
            <div className='statement-result-table-top'>
              <button
                type='button'
                onClick={onModify}
                className='excel-download-button'
              >
                재이체
              </button>
              <button
                type='button'
                onClick={() => {}}
                className='excel-download-button'
              >
                파일 엑셀 다운
              </button>
            </div>
            <div className='statement-result-table-box'>
              <div className='statement-result-table-head'>
                <div>
                  <input
                    aria-label='checkbox'
                    type='checkbox'
                    onChange={onCheckAll}
                    checked={isCheckAll}
                  />
                </div>
                <div>교환번호</div>
                <div>교환시작날짜</div>
                <div>교환완료날짜</div>
                <div>사이트</div>
                <div>이름</div>
                <div>플랫폼</div>
                <div>핀번호</div>
                <div>충전금액</div>
                <div>충전상태</div>
                <div>입금액</div>
                <div>입금상태</div>
              </div>
              {list && list.data.exchanges.length > 0
                ? list.data.exchanges.map(
                    ({
                      applicatedAt,
                      chargeStatus,
                      chargedPrice,
                      completedAt,
                      depositPrice,
                      depositStatus,
                      exchangeId,
                      holderName,
                      chargeId,
                      pinNumber,
                      siteId: idOfSite,
                      voucherType,
                    }) => (
                      <div
                        className='statement-result-table-body'
                        key={exchangeId}
                      >
                        <div>
                          <input
                            aria-label='checkbox'
                            type='checkbox'
                            onChange={onCheckList}
                            checked={checklist.includes(
                              `superadmin-checkboxes-${chargeId}`,
                            )}
                            id={`superadmin-checkboxes-${chargeId}`}
                            className='superadmin-checkboxes'
                          />
                        </div>
                        <div>{exchangeId}</div>
                        <div>
                          {dayjs(applicatedAt).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                        <div>
                          {dayjs(completedAt).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                        <div>
                          {siteInfo.find((i) => i.id === idOfSite)?.name ??
                            idOfSite}
                        </div>
                        <div>{holderName}</div>
                        <div>{voucherType}</div>
                        <div>{pinNumber}</div>
                        <div>{chargedPrice.toLocaleString('ko-KR')}</div>
                        <div>{ChargingStatusToText(chargeStatus)}</div>
                        <div>{depositPrice.toLocaleString('ko-KR')}</div>
                        <div>{DepositStatusToText(depositStatus)}</div>
                      </div>
                    ),
                  )
                : null}
            </div>
            <Pagination page={page} setPage={setPage} totalCount={totalCount} />
          </div>
        </div>
      </StatementResultContainer>
    </>
  );
}

export default React.memo(Statement);
