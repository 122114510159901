import React from 'react';
import RealtimeExchange from './RealtimeExchange';
import UserNotice from './UserNotice';
import UserFAQ from './UserFAQ';
import { RightContainer, UserBoardContainer, UserBoardInner } from './styles';

function UserBoard() {
  return (
    <UserBoardContainer>
      <UserBoardInner>
        <div className='user-board-desktop'>
          <RightContainer>
            <UserNotice />
            <UserFAQ />
          </RightContainer>
          <RealtimeExchange />
        </div>
        <div className='user-board-mobile'>
          <UserNotice />
          <RealtimeExchange />
          <UserFAQ />
        </div>
      </UserBoardInner>
    </UserBoardContainer>
  );
}

export default React.memo(UserBoard);
