/* eslint-disable no-nested-ternary */
import axios, { AxiosRequestConfig } from 'axios';

export const mode = process.env.REACT_APP_NODE_ENV === 'production';

axios.defaults.baseURL = mode
  ? 'https://api.cashpin.net'
  : 'http://localhost:3000';

export type Res<T> = {
  status: number;
  msg: string;
  data: T;
};

export const checkAgent = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile =
    userAgent.includes('android') ||
    userAgent.includes('iphone') ||
    userAgent.includes('ipad');

  return isMobile;
};

const agentInterceptor = () => {
  const originExcludeWWW = window.location.origin.replace(/www\./g, '');
  return originExcludeWWW;
};

export const getSiteId = async () => {
  const res = await axios.get(`/common?url=${agentInterceptor()}`);
  return res.data;
};

export const getfetcher = async (
  url: string,
  suffix: string = '',
  useId: boolean = true,
  config?: AxiosRequestConfig<any>,
) => {
  const {
    data: {
      site: { id },
    },
  } = await getSiteId();
  const result = await axios.get(`${url}${useId ? id : ''}${suffix}`, config);
  return result.data;
};

export const getParamFetcher = async (
  url: string,
  param: string | number,
  config?: AxiosRequestConfig<any>,
) => {
  const result = await axios.get(`${url}/${param}`, config);
  return result.data;
};

export const postFetcher = async (
  url: string,
  suffix: string = '',
  useSiteId: boolean = true,
  data?: any,
  config?: AxiosRequestConfig<any>,
) => {
  const {
    data: {
      site: { id },
    },
  } = await getSiteId();
  const result = await axios.post(
    `${url}${useSiteId ? id : ''}${suffix}`,
    data,
    config,
  );
  return result.data;
};
