import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { accountEditForm } from '@static/admin/admin';
import useInputObj from '@hook/useInputObj';
import { getfetcher } from '@util/fetcher';
import { queryKeys } from '@util/queries';
import { getCookie } from '@util/tokenUtil';
import type { SiteInfoType } from './types';
import AccountSyncList from './AccountSyncList';
import { AccountInputForm, AccountListContainer } from './styles';

const obj: { [key: string]: string } = {};

const initForm = accountEditForm.reduce((prev, acc) => {
  const { name } = acc;
  prev[name] = '';
  return prev;
}, obj);

function AccountManager() {
  const token = (getCookie(document.cookie, 'token') as string) || '';
  const [inputForm, , setInputForm] = useInputObj(initForm);
  const params = useParams();
  const [title, setTitle] = React.useState('');

  useQuery<SiteInfoType>(
    [queryKeys.superadmin.syncSites, params.id, token, inputForm],
    () =>
      getfetcher(`/superadmin/account/${params.id}`, '', false, {
        headers: {
          token,
        },
      }),
    {
      onSuccess: (old: SiteInfoType) => {
        setInputForm({
          ...inputForm,
          name: old?.data?.business?.ceoName,
          phone: old?.data?.business?.phone,
          companyName: old?.data?.business?.companyName,
          license: old?.data?.business?.license,
          licenseImg: old?.data?.business?.licenseImage,
          siteUrl: old?.data?.site?.url,
          siteName: old?.data?.site?.name,
          email: old?.data?.admin?.email,
        });
        setTitle(old?.data?.business?.ceoName);
      },
      enabled: !!token,
    },
  );

  const onViewOriginalImage = (src: string) => () => {
    const imgObj = new Image();
    imgObj.src = src;
    const imageWin = window.open(
      '',
      'profile_popup',
      `width=${imgObj.width}px, height=${imgObj.height}px`,
    );
    if (imageWin) {
      imageWin.document.write("<html><body style='margin:0'>");
      imageWin.document.write(
        `<a href=javascript:window.close()><img src='${imgObj.src}' border=0></a>`,
      );
      imageWin.document.write('</body><html>');
      imageWin.document.title = imgObj.src;
    }
  };

  return (
    <>
      <AccountListContainer>
        <h2>{title}</h2>
        <AccountInputForm>
          {accountEditForm.slice(0, 3).map((item) => (
            <div className='account-form' key={item.id}>
              <p className='account-left-form'>{item.text}</p>
              <input
                type={item.type}
                className='account-right-form'
                name={item.name}
                value={inputForm[item.name]}
                disabled
              />
            </div>
          ))}
          <div className='add-label-form'>
            <p className='add-account-label-form'>사업자 등록증</p>
            <div>
              <div
                className='view-image'
                // href={inputForm.licenseImg ?? ''}
                // target='_blank'
                // rel='noreferrer'
                onClick={onViewOriginalImage(inputForm.licenseImg ?? '')}
              >
                <img src={inputForm.licenseImg ?? ''} alt='사업자 등록증' />
              </div>
            </div>
          </div>
          {accountEditForm
            .filter((c) => c.id !== 7)
            .slice(3)
            .map((item) => (
              <div className='account-form' key={item.id}>
                <p className='account-left-form'>{item.text}</p>
                <input
                  type={item.type}
                  className='account-right-form'
                  name={item.name}
                  value={inputForm[item.name]}
                  disabled
                />
              </div>
            ))}
        </AccountInputForm>
      </AccountListContainer>
      <AccountSyncList />
    </>
  );
}

export default React.memo(AccountManager);
