import React from 'react';
import styled from 'styled-components';
// import terms from '@static/admin/terms.pdf';
// import privacy from '@static/admin/privacy.pdf';
import { pixelToRem } from '@util/commonStyles';
import theme from '@util/theme';

export const footerText =
  '(주)카라멜라 | 대표: 박서연 | 사업자 등록번호: 405-86-01678 | 광주광역시 동구 금남로 193-12 702호 | 고객센터: 070-4231-8731';

export const FooterContainer = styled.footer`
  font-size: ${pixelToRem(17)};
  color: ${theme.light.color.gray8C8C9B};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${pixelToRem(50)} 0;

  div,
  a {
    color: inherit;
    text-decoration: none;
  }

  & > div {
    margin-bottom: ${pixelToRem(16)};
  }

  .footer-pdf {
    display: flex;
    align-items: center;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <div>{footerText}</div>
      <div className='footer-pdf'>
        {/* <a href={terms} target='_blank' rel='noreferrer'>
          개인정보 처리방침
        </a>
        <div>&nbsp;|&nbsp;</div>
        <a href={privacy} target='_blank' rel='noreferrer'>
          서비스 이용약관
        </a> */}
      </div>
    </FooterContainer>
  );
}

export default Footer;
