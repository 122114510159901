import styled from 'styled-components';
import { flexAlign, gridInit, pixelToRem } from '@util/commonStyles';
import { media } from '@util/mediaQuery';
import theme from '@util/theme';

export const RealTimeTableContainer = styled.div`
  ${flexAlign('column', undefined, 'stretch', 'stretch')};
  max-width: ${pixelToRem(1400)};
  margin: 3rem auto 7rem;
  flex: 1;
  ${media.medium} {
    margin: 3rem 1rem 0;
    width: auto;
  }
`;

export const RealTimeTopContainer = styled.div`
  ${flexAlign(undefined, 'space-between')};
  flex: 1;
  margin-bottom: ${pixelToRem(30)};

  h3 {
    font-size: ${pixelToRem(30)};
    color: ${theme.light.color.black};
  }
  p {
    margin: 0;
    font-size: ${pixelToRem(17)};
    color: ${theme.light.color.black};
  }
  ${media.custom(500)} {
    h3 {
      font-size: ${pixelToRem(20)};
    }
    p {
      font-size: ${pixelToRem(15)};
    }
  }
`;

export const RealTimeHeadGrid = styled.div`
  display: grid;
  font-size: ${pixelToRem(20)};
  ${gridInit('0.7fr 1fr 3fr 1fr 1fr 1fr', { rowGap: 0, columnGap: 0 })};
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr;
  box-sizing: border-box;
  border-top: 2px solid ${theme.light.border.black};
  border-bottom: 1px solid ${theme.light.border.gray};
  padding: ${pixelToRem(16)} 0;
  font-size: 20px;

  ${media.custom(1000)} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: ${pixelToRem(60)};
    padding: 0 10px;
    & > div:nth-child(1) {
      width: ${pixelToRem(100)};
    }
    & > div:nth-child(3) {
      border: none;
    }
    & > div:nth-child(4) {
      display: none;
    }
    & > div:nth-child(2) {
      display: none;
    }

    & > div:nth-child(5) {
      display: none;
    }
    & > div:nth-child(6) {
      display: none;
    }
    ${media.custom(500)} {
      font-size: 15px;
      padding: 0;
      & > div:nth-child(1) {
        width: ${pixelToRem(80)};
        margin-left: 0.1rem;
        padding: 0;
      }
    }
  }

  & > div {
    box-sizing: border-box;
    border-right: 2px solid ${theme.light.border.gray};
    ${flexAlign()};
    padding: ${pixelToRem(5)} ${pixelToRem(5)};
    height: 40px;
    margin: 0;
    ${media.custom(500)} {
      margin-left: ${pixelToRem(-15)};
    }
  }

  & > div:nth-child(3) {
    padding: 0 ${pixelToRem(25)};
    justify-content: flex-start;

    ${media.custom(500)} {
    }
  }

  & > div:nth-child(6) {
    border-right: none;
  }
`;

export const RealTimeBodyGrid = styled(RealTimeHeadGrid)`
  background-color: ${theme.light.background.white};
  border-top: 1px solid ${theme.light.border.gray};
  border-bottom: 1px solid ${theme.light.border.gray};
  font-size: ${pixelToRem(18)};
  & > div:last-child {
    display: none;
  }
  & > div:nth-child(2) {
    .mobile-date {
      display: none;
    }
  }

  ${media.custom(1000)} {
    height: 70px;
    padding: ${pixelToRem(40)} 10px;
    & > div:nth-child(2) {
      width: auto;
      display: flex;
      flex-direction: column;
      border: 0;
      padding-left: 2rem;
      & > p {
        display: block;
        margin: 0;
      }
    }
  }
  ${media.custom(500)} {
    padding: 0;
    font-size: ${pixelToRem(15)};

    & > div:nth-child(2) {
      .mobile-date {
        display: flex;
      }
    }
    & > div:nth-child(3) {
      display: none;
    }
    & > div:nth-child(7) {
      display: none;
    }
    & > div:last-child {
      display: flex;
      border: none;
      margin-left: 0.5rem;
    }
  }

  grid & > div {
    overflow: hidden;
    width: ${pixelToRem(100)};
  }
  & > div:nth-child(2) {
    cursor: pointer;
  }
  & > div:nth-child(4) {
    font-weight: bold;
  }
  & > div:nth-child(6) {
    border-right: none;
  }
`;

// export const RealTimeFooterContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: ${pixelToRem(30)};
//   button.create {
//     width: ${pixelToRem(133)};
//     height: ${pixelToRem(40)};
//     ${flexAlign()};
//     color: ${theme.light.color.white};
//     border: none;
//     outline: none;
//     box-sizing: border-box;
//     cursor: pointer;
//   }
// `;
