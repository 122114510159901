import styled from 'styled-components';
import { pixelToRem, flexAlign } from '@util/commonStyles';
import theme from '@util/theme';
import { media } from '@util/mediaQuery';

export const AccordionSection = styled.div`
  ${flexAlign('column', 'center', 'center', 'flex')}
  margin: 0 auto 7rem;

  ${media.medium} {
    margin: 0 2rem;
  }
`;
export const Container = styled.div`
  margin: 4rem auto 0;
  width: 52vw;

  ${media.medium} {
    width: 100%;
  }
`;
export const Wrap = styled.div`
  background-color: ${theme.light.background.white};
  color: ${theme.light.color.black};
  ${flexAlign('none', 'space-between', 'center', 'flex')}
  height: 70px;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${theme.light.border.gray};
  margin-top: 1rem;
  padding: 0 30px;
  position: relative;

  ${media.medium} {
    padding: 0 15px;
  }
  ${media.custom(600)} {
    font-size: ${pixelToRem(14)};
    height: ${pixelToRem(50)};
  }

  h1 {
    position: absolute;
    left: 5rem;
    font-size: ${pixelToRem(20)};
    ${media.custom(600)} {
      font-size: ${pixelToRem(14)};
      left: 3rem;
    }
  }
  span {
    margin-right: 1.5rem;
  }
`;

export const Dropdown = styled.pre`
  color: ${theme.light.color.black};
  width: 100%;
  font-size: ${pixelToRem(20)};
  background-color: ${theme.light.background.white};
  border: 1px solid ${theme.light.border.gray};
  border-top: none;
  display: flex;
  padding: 30px 80px;
  flex-direction: column;
  box-sizing: border-box;

  ${media.medium} {
  }

  p {
    /* padding: 10px 20px; */
    font-size: 2rem;
  }
`;

export const BodyLoadingOrEmpty = styled.div`
  ${flexAlign('center', 'center', 'center', 'flex')}
  margin : auto;
  font-size: 30px;
  ${media.custom(600)} {
    font-size: ${pixelToRem(25)};
  }
`;
