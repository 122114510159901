import React from 'react';
import UserLayout from '@layout/user/home/UserLayout';
import UserNoticeList from '@comp/user/default/notice/UserNoticeList';
import SEO from '@comp/SEO';

function UserNotice() {
  return (
    <SEO>
      <UserLayout>
        <UserNoticeList />
      </UserLayout>
    </SEO>
  );
}

export default React.memo(UserNotice);
