import React from 'react';

import Router from '@router/Router';

import '@src/App.css';

function App() {
  return <Router />;
}
export default App;
