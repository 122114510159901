import React from 'react';
import { UserLayoutContainer } from '@layout/user/home/styles';
import UseExchange from './UseExchange';
import ExchangeRequest from '../exchangeRequest/ExchangeRequest';
import CommonBanner from '../topBanner/CommonBanner';

function AutoExchange() {
  return (
    <>
      <UserLayoutContainer>
        <CommonBanner
          title='자동상품권교환'
          text='24시간 연중무휴 자동화 시스템을 통해 실시간으로 신청 즉시 핀번호 확인부터 입금까지!'
        />
        <UseExchange />
        <ExchangeRequest />
      </UserLayoutContainer>
    </>
  );
}

export default React.memo(AutoExchange);
