import styled, { css } from 'styled-components';
import {
  btnInit,
  flexAlign,
  pixelToRem,
  pixelToRemBorder,
} from '@util/commonStyles';
import theme from '@util/theme';

export const DashboardInner = styled.div`
  display: flex;
  position: relative;
  min-height: ${`calc(${pixelToRem(1080 - 135)})`};
`;

export const ContentInner = styled.article<{
  padding?: number;
  borderLeft?: number;
}>`
  padding-top: ${({ padding }) => pixelToRem(padding ?? 23)};
  padding-bottom: ${({ padding }) => pixelToRem(padding ?? 23 * 2)};
  padding-left: ${({ padding }) => pixelToRem(padding ?? 23)};
  padding-right: ${({ padding }) => pixelToRem(padding ?? 23)};
  flex: 1;
  border-left: ${({ borderLeft }) =>
    pixelToRemBorder(borderLeft ?? 1, theme.light.border.gray)};
  box-sizing: border-box;
`;

export const SubNavigator = styled.div`
  height: ${pixelToRem(120)};
  background-color: ${theme.light.background.white};
  box-sizing: border-box;
  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
  padding-left: ${pixelToRem(40)};
  padding-top: ${pixelToRem(23)};

  & > h2 {
    font-size: ${pixelToRem(25)};
    font-weight: 700;
    margin: 0;
    margin-bottom: ${pixelToRem(33)};
  }
`;

export const SubNavigatorMenu = styled.span<{ ismatched: number }>`
  ${btnInit(
    theme.light.color.gray707070,
    'transparent',
    { borderWidth: 0, borderColor: '#fff' },
    0,
    'pointer',
  )};
  font-size: ${pixelToRem(17)};
  font-weight: 600;
  color: ${({ ismatched }) =>
    ismatched > 0 ? theme.light.color.black : theme.light.color.gray707070};
  margin-right: ${pixelToRem(40)};
`;

export const adminButtonStyle = (width?: number, height?: number) => css`
  ${btnInit(
    theme.light.color.gray707070,
    theme.light.color.white,
    { borderWidth: 1, borderColor: theme.light.color.gray },
    0,
    'pointer',
  )};
  ${flexAlign()};
  width: ${pixelToRem(width ?? 133)};
  height: ${pixelToRem(height ?? 40)};
`;
