import React, { useEffect, useState } from 'react';
import {
  FiChevronsLeft,
  FiChevronsRight,
  FiChevronLeft,
  FiChevronRight,
} from 'react-icons/fi';
import styled from 'styled-components';
import theme from '@util/theme';

type PaginationProps = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  totalCount: number;
  itemPerPage?: number;
  size?: number;
};

const Pagination = ({
  setPage,
  page,
  totalCount,
  itemPerPage = 10,
  size = 20,
}: PaginationProps) => {
  const [nums, setNums] = useState(
    new Array(totalCount).fill(0).map((v, i) => i + 1),
  );

  useEffect(() => {
    const len = Math.ceil(totalCount / itemPerPage);
    if (len > 0) {
      const array = new Array(len).fill(0).map((v, i) => i + 1);
      setNums(array);
    } else {
      setNums([]);
    }
  }, [totalCount, itemPerPage]);

  const onPage = (n: number) => () => {
    setPage(n);
  };

  const onFullPrevPage = () => {
    setPage(1);
  };

  const onPrevPage = () => {
    if (page - 5 < 1) {
      setPage(1);
      return;
    }
    setPage(page - 5);
  };

  const onNextPage = () => {
    if (page + 5 > Math.ceil(totalCount / itemPerPage)) {
      setPage(Math.ceil(totalCount / itemPerPage));
      return;
    }
    setPage(page + 5);
  };

  const onFullNextPage = () => {
    setPage(Math.ceil(totalCount / itemPerPage));
  };

  return (
    <PaginationContainer>
      <div
        role='presentation'
        className='arrow'
        onClick={onFullPrevPage}
        onKeyUp={() => {}}
      >
        <FiChevronsLeft size={size} />
      </div>
      <div
        className='arrow'
        role='presentation'
        onClick={onPrevPage}
        onKeyUp={() => {}}
      >
        <FiChevronLeft size={size} />
      </div>
      {page % 5 !== 0
        ? nums
            .slice(Math.floor(page / 5) * 5, Math.floor(page / 5) * 5 + 5)
            .map((v) => (
              <PageButton
                isMatch={page === v}
                role='presentation'
                className='number'
                key={v}
                onClick={onPage(v)}
                onKeyUp={() => {}}
              >
                {v}
              </PageButton>
            ))
        : nums
            .slice(Math.floor(page / 5) * 5 - 5, Math.floor(page / 5) * 5)
            .map((v) => (
              <PageButton
                isMatch={page === v}
                role='presentation'
                className='number'
                key={v}
                onClick={onPage(v)}
                onKeyUp={() => {}}
              >
                {v}
              </PageButton>
            ))}
      <div
        className='arrow'
        role='presentation'
        onClick={onNextPage}
        onKeyUp={() => {}}
      >
        <FiChevronRight size={size} />
      </div>
      <div
        className='arrow'
        role='presentation'
        onClick={onFullNextPage}
        onKeyUp={() => {}}
      >
        <FiChevronsRight size={size} />
      </div>
    </PaginationContainer>
  );
};

export const PaginationContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin: 0 5px;
  }
  .arrow,
  .number {
    box-sizing: border-box;
    cursor: pointer;
  }
  .arrow:hover,
  .number:hover {
  }
  .number {
  }
  .selected {
    color: ${theme.light.color.gray707070};
  }
`;

export const PageButton = styled.div<{ isMatch: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin: 0 5px;
  font-size: 20px;
  color: ${(props) => (props.isMatch ? '#08587d' : '#000')};
`;

export default React.memo(Pagination);
