/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { basePin, InputNum } from '@static/user/default/exchangeRequest';

type onChangeType = (
  id: string,
) => (e: React.ChangeEvent<HTMLInputElement>) => void;

export default function useInputNum(
  sliceStart: number = 0,
  sliceEnd: number = 10,
  initialValue: InputNum = basePin,
): [
  InputNum,
  onChangeType,
  onChangeType,
  onChangeType,
  React.Dispatch<React.SetStateAction<InputNum>>,
] {
  const [value, setValue] = useState<InputNum>(initialValue);

  const onChangeData: onChangeType = useCallback(
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const regExp = new RegExp(/^[0-9-]*$/g);
      if (regExp.test(e.target.value)) {
        setValue((p) => ({
          ...p,
          [id]: {
            id: Number(id),
            data: e.target.value.replace(
              /(\d{4})(\d{4})(\d{4})(\d{4})/,
              '$1-$2-$3-$4',
            ),
            issue: p[id].issue,
            auth: p[id].auth,
          },
        }));
      }
      setValue((p) => ({
        ...p,
        [id]: {
          id: Number(id),
          data: p[id].data.slice(sliceStart, sliceEnd),
          issue: p[id].issue,
          auth: p[id].auth,
        },
      }));
    },
    [sliceStart, sliceEnd],
  );

  const onChangeIssue: onChangeType = useCallback(
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const regExp = new RegExp(/^[0-9]*$/g);
      if (regExp.test(e.target.value)) {
        setValue((p) => ({
          ...p,
          [id]: {
            id: Number(id),
            data: p[id].data,
            issue: e.target.value,
            auth: p[id].auth,
          },
        }));
      }
      setValue((p) => ({
        ...p,
        [id]: {
          id: Number(id),
          data: p[id].data,
          issue: p[id].issue.slice(sliceStart, 8),
          auth: p[id].auth,
        },
      }));
    },
    [sliceStart],
  );

  const onChangeAuth: onChangeType = useCallback(
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const regExp = new RegExp(/^[0-9]*$/g);
      if (regExp.test(e.target.value)) {
        setValue((p) => ({
          ...p,
          [id]: {
            id: Number(id),
            data: p[id].data,
            issue: p[id].issue,
            auth: e.target.value,
          },
        }));
      }
      setValue((p) => ({
        ...p,
        [id]: {
          id: Number(id),
          data: p[id].data,
          issue: p[id].issue,
          auth: p[id].auth.slice(sliceStart, 4),
        },
      }));
    },
    [sliceStart],
  );

  return [value, onChangeData, onChangeIssue, onChangeAuth, setValue];
}
