const theme = {
  light: {
    background: {
      whiteF5f5f5: '#F5F5F5',
      white: '#fff',
      gray: '#ccc',
      grayECECEC: '#ECECEC',
      gray707070: '#707070',
      redE30100: '#E30100',
      green28A745: '#28A745',
      lightGray: '#F1F2F8',
      grayFAFAFC: '#FAFAFC',
      lightGreen: '#28A745',
      lightYellow: '#FBC101',
      lightRed: '#E30100',
      lightbeige: '#F9F9F9',
      navy: '#02024F',
    },
    color: {
      white: '#FFF',
      navy: '#02024F',
      grayC0C0C0: '#C0C0C0',
      gray707070: '#707070',
      gray8C8C9B: '#8C8C9B',
      gray: '#ccc',
      black: '#000',
      red: '#ff0000',
    },
    border: {
      gray: '#ccc',
      black: '#000',
      gray707070: '#707070',
      navy: '#02024F',
      white: '#fff',
    },
    switch: {
      active: {},
      inactive: {},
    },
  },
  dark: {
    background: {},
    color: {},
    border: {},
    switch: {
      active: {},
      inactive: {},
    },
  },
};

export default theme;
