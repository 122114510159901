export const queryKeys = {
  accountManagerRead: 'accountManagerRead',
  adminSiteStatement: 'adminSiteStatement',

  user: {
    realtimeExchange: 'realtimeExchange',
    faqList: 'faqList',
    noitceList: 'noticeList',
    exchangeHistory: 'exchangeHistory',
  },

  superadmin: {
    SiteStatement: 'superadminSiteStatement',
    syncSites: 'superadminSyncSites',
    loadSiteAccount: 'superadminLoadSiteAccount',
  },
};

export const mutationKeys = {
  user: {
    authPhone: 'authPhone',
    exchangeHistory: 'exchangeHistory',
  },
  admin: {
    downloadExcel: 'downloadExcel',
    modifyName: 'modifyName',
  },
  superadmin: {
    createAccount: 'superadminCreateAccount',
    createSite: 'superadminCreateSite',
    deleteSyncSite: 'superadminDeleteSyncSite',
    checkEmail: 'superadminCheckEmail',
    deleteAccount: 'superadminDeleteAccount',
  },
};
