import React from 'react';
import { useQuery } from 'react-query';
import { FiPlusSquare } from 'react-icons/fi';
import { GrCircleInformation } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import theme from '@util/theme';
import { MainFAQData, ListResponse } from '@static/user/default/commonTypes';
import { getfetcher } from '@util/fetcher';
import { queryKeys } from '@util/queries';
import {
  FAQContainer,
  FAQItemContainer,
  FAQItem,
  BodyLoadingOrEmpty,
} from './styles';

function UserFAQ() {
  const { data, isLoading } = useQuery<ListResponse>(
    [queryKeys.user.faqList, { page: 1 }],
    () => getfetcher(`/main/`, '', true),
    {
      retry: 5,
      refetchInterval: 1000 * 100,
    },
  );

  return (
    <FAQContainer>
      <div className='board-header'>
        <h3>자주하는 질문</h3>
        <Link to='/user/faq'>
          <FiPlusSquare size={33} color={theme.light.color.gray} />
        </Link>
      </div>
      <FAQItemContainer>
        {data && data.data.listFaq && data.data.listFaq.length > 0 ? (
          data.data.listFaq.slice(0, 5).map((value: MainFAQData) => (
            <FAQItem to='/user/faq' key={value.id}>
              <span>
                <GrCircleInformation size={25} />
              </span>
              <div>{value.title}</div>
            </FAQItem>
          ))
        ) : (
          <BodyLoadingOrEmpty>
            {isLoading ? '로딩중입니다' : '자주 묻는 질문이 없습니다'}
          </BodyLoadingOrEmpty>
        )}
      </FAQItemContainer>
    </FAQContainer>
  );
}

export default React.memo(UserFAQ);
