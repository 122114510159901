import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  flexAlign,
  gridInit,
  inputChecked,
  pixelToRem,
  pixelToRemBorder,
} from '@util/commonStyles';
import theme from '@util/theme';
import { adminButtonStyle } from '../styles';

const accountButtonCSS = () => css`
  ${flexAlign()};
  background-color: ${theme.light.color.gray707070};
  color: ${theme.light.color.white};
  font-size: ${pixelToRem(17)};
  border: none;
  height: ${pixelToRem(40)};
  width: ${pixelToRem(133)};
  outline: none;
  cursor: pointer;
  margin-left: ${pixelToRem(20)};
`;

export const AccountInputForm = styled.div`
  padding-left: ${pixelToRem(60)};
  padding-top: ${pixelToRem(30)};
  padding-bottom: ${pixelToRem(30)};

  & + div {
    ${flexAlign()};
    margin-bottom: ${pixelToRem(30)};
  }

  .account-form {
    ${flexAlign(undefined, 'flex-start')};
    margin-bottom: ${pixelToRem(30)};
    font-size: ${pixelToRem(17)};

    button {
      ${accountButtonCSS()};
    }

    .account-left-form {
      width: ${pixelToRem(150)};
      height: ${pixelToRem(40)};
      margin: 0;
      ${flexAlign(undefined, 'flex-start')};
    }

    .account-right-form {
      height: ${pixelToRem(40)};
      width: ${pixelToRem(310)};
      padding-left: ${pixelToRem(16)};
      font-size: ${pixelToRem(17)};
      outline: none;
      border: ${pixelToRemBorder(1, theme.light.border.gray)};
    }

    #account-right-form-image {
      background: red;
    }

    input[type='password'].account-right-form {
      font-family: sans-serif;
    }

    .not-equal-password {
      color: ${theme.light.color.red};
      margin-left: ${pixelToRem(16)};
      font-size: ${pixelToRem(17)};
    }
  }

  .add-label-form {
    ${flexAlign(undefined, 'flex-start', 'flex-start')};
    margin-bottom: ${pixelToRem(30)};
    .add-account-label-form {
      width: ${pixelToRem(150)};
      height: ${pixelToRem(40)};
      margin: 0;
      ${flexAlign(undefined, 'flex-start')};
      font-size: ${pixelToRem(17)};
    }

    .view-image {
      cursor: pointer;
      img {
        max-width: ${pixelToRem(310)};
        object-fit: contain;
      }
    }

    .add-file {
      margin-bottom: ${pixelToRem(10)};
      ${flexAlign(undefined, 'flex-start', 'flex-start')};

      & > label {
        ${flexAlign(undefined, 'flex-start')};
        height: ${pixelToRem(40)};
        width: ${pixelToRem(330)};
        padding-left: ${pixelToRem(16)};
        font-size: ${pixelToRem(17)};
        outline: none;
        border: ${pixelToRemBorder(1, theme.light.border.gray)};
        font-size: ${pixelToRem(17)};
        color: ${theme.light.color.gray707070};
      }

      & > button {
        ${accountButtonCSS()};
      }
    }

    & > div > p {
      margin: 0;
      font-size: ${pixelToRem(17)};
      color: ${theme.light.color.gray707070};

      &:not(:last-child) {
        margin-bottom: ${pixelToRem(10)};
      }
    }
  }
`;

export const SiteInfoContainer = styled.div`
  width: 100%;
  background-color: ${theme.light.background.white};
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};

  & > h2 {
    box-sizing: border-box;
    border-bottom: ${pixelToRemBorder(1, theme.light.color.gray)};
    font-size: ${pixelToRem(20)};
    height: ${pixelToRem(56)};
    padding-left: ${pixelToRem(20)};
    ${flexAlign(undefined, 'flex-start')};
    margin: 0;
  }

  & > div:last-child {
    ${flexAlign(undefined, 'flex-end')};
    padding-bottom: ${pixelToRem(25)};

    .cancel-button {
      order: -1;
      margin-right: ${pixelToRem(16)};
    }
  }
`;

export const SiteInfoContent = styled.div`
  padding-left: ${pixelToRem(20)};
  padding-top: ${pixelToRem(30)};

  .site-info-form {
    ${flexAlign(undefined, 'flex-start', 'flex-start')};
    margin-bottom: ${pixelToRem(30)};
    .site-info-left-form {
      font-size: ${pixelToRem(15)};
      font-weight: 700;
      width: ${pixelToRem(150)};
      ${flexAlign(undefined, 'flex-start')};
      min-height: ${pixelToRem(40)};
    }

    .site-info-right-form {
      ${flexAlign(undefined, 'flex-start')};
    }

    .site-info-column-form {
      ${flexAlign('column', 'flex-start', 'flex-start')};
      input[type='text'],
      input[type='password'] {
        height: ${pixelToRem(40)};
        width: ${pixelToRem(240)};
        box-sizing: border-box;
        border: ${pixelToRemBorder(1, theme.light.border.gray)};
        ${flexAlign(undefined, 'flex-start')};
        padding-left: ${pixelToRem(16)};
        font-size: ${pixelToRem(13)};
        font-weight: 700;
        outline: none;
        margin-bottom: ${pixelToRem(4)};
      }

      & > p {
        color: ${theme.light.color.red};
        margin: 0;
        font-size: ${pixelToRem(13)};
      }

      input[type='password'] {
        font-family: sans-serif;
      }
    }
  }
`;

export const syncGridHead = (
  isBorderBottom?: number,
  height?: number,
): FlattenSimpleInterpolation => css`
  ${gridInit('1fr 3fr 2.5fr 5fr 15fr 3fr', { rowGap: 0, columnGap: 0 })};
  border-bottom: ${pixelToRemBorder(
    isBorderBottom ?? 1,
    theme.light.border.gray,
  )};
  & > div {
    ${flexAlign()};
    font-size: ${pixelToRem(17)};
    font-weight: 700;
    color: ${theme.light.color.gray707070};
    border-right: ${pixelToRemBorder(1, theme.light.border.gray)};
    box-sizing: border-box;
    height: ${pixelToRem(height ?? 40)};
  }

  & > div:nth-child(1) {
    ${inputChecked()};
    input {
      outline: none;
    }
  }

  & > div:nth-child(5) {
    justify-content: flex-start;
    padding-left: ${pixelToRem(16)};
  }

  & > div:nth-child(6) {
    border-right: none;
  }
`;

export const AccountSyncListContainer = styled.div`
  background-color: ${theme.light.background.white};
  margin-top: ${pixelToRem(30)};
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};

  .sync-list-header {
    border-bottom: ${pixelToRemBorder(1, theme.light.color.gray)};
    box-sizing: border-box;
    ${flexAlign(undefined, 'space-between')};
    height: ${pixelToRem(60)};
    margin: 0;
    padding-left: ${pixelToRem(30)};
    padding-right: ${pixelToRem(30)};

    & > h2 {
      font-size: ${pixelToRem(20)};
      font-weight: 900;
    }

    & > button {
      ${adminButtonStyle(120, 30)};
    }
  }

  .sync-list-wrapper {
    margin-top: ${pixelToRem(30)};
    margin-left: ${pixelToRem(30)};
    margin-right: ${pixelToRem(30)};
    margin-bottom: ${pixelToRem(30)};
    box-sizing: border-box;
    border: ${pixelToRemBorder(1, theme.light.border.gray)};
    border-bottom: none;

    .sync-list-head {
      ${syncGridHead()};
    }

    .sync-list-body {
      ${syncGridHead(1, 60)};

      & > div:nth-child(6) {
        & > button {
          ${adminButtonStyle(70, 30)};
          ${flexAlign()};

          &:first-child {
            margin-right: ${pixelToRem(10)};
          }
        }
      }
    }
  }
`;

export const AccountListContainer = styled.section`
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
  background-color: ${theme.light.background.white};

  & > h2 {
    box-sizing: border-box;
    border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
    margin: 0;
    height: ${pixelToRem(64)};
    ${flexAlign(undefined, 'flex-start')};
    padding-left: ${pixelToRem(36)};
    font-size: ${pixelToRem(20)};
    font-weight: 900;
  }
`;

export const AdminTableContainer = styled.div`
  padding-left: ${pixelToRem(36)};
  padding-right: ${pixelToRem(36)};
  padding-top: ${pixelToRem(30)};
`;

export const AdminManageButtonContainer = styled.div`
  ${flexAlign(undefined, 'flex-start')};
  margin-bottom: ${pixelToRem(20)};

  button {
    margin-right: ${pixelToRem(21)};
  }

  button:last-child {
    margin-right: 0;
  }
`;

export const AdminManageButton = styled.button`
  ${adminButtonStyle()};
`;

export const AdminTable = styled.div`
  box-sizing: border-box;
  border: ${pixelToRemBorder(1, theme.light.border.gray)};
  border-bottom: none;

  .all-check-element {
    background-color: ${theme.light.background.grayFAFAFC};
    height: ${pixelToRem(60)};
    ${flexAlign(undefined, 'flex-end')};
    padding-right: ${pixelToRem(16)};
    border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
  }

  .table-body {
    max-height: ${pixelToRem(600)};
    box-sizing: border-box;
    overflow-y: auto;
  }
`;

export const AdminTableCheckboxContainer = styled.div`
  width: ${pixelToRem(70)};
  height: ${pixelToRem(60)};
  ${flexAlign()};
  box-sizing: border-box;
  border-right: ${pixelToRemBorder(1, theme.light.border.gray)};

  ${inputChecked()};
`;

const gridItemCSS = () => css`
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  ${gridInit('2fr 1.5fr 3fr 5fr 2fr', { rowGap: 0, columnGap: 0 })};
  & > div {
    height: 100%;
    ${flexAlign()};
    box-sizing: border-box;
    border-right: ${pixelToRemBorder(1, theme.light.border.gray)};
  }

  & > div:last-child {
    border-right: none;
  }
`;

export const AdminTableHead = styled.div<{ isOverflow: boolean }>`
  ${flexAlign(undefined, 'flex-start')};
  border-bottom: ${pixelToRemBorder(1, theme.light.border.gray)};
  height: ${pixelToRem(60)};
  position: sticky;
  padding-right: ${({ isOverflow }) => pixelToRem(isOverflow ? 17 : 0)};
  top: 0;
  .grid-head {
    ${gridItemCSS()};
  }
`;

export const AdminTableBody = styled(AdminTableHead)<{}>`
  box-sizing: border-box;
  padding-right: 0;
  position: static;

  .grid-body {
    ${gridItemCSS()};
  }
`;
