import styled from 'styled-components';
import { btnInit, flexAlign, pixelToRem } from '@util/commonStyles';
import { mediaQuery, media } from '@util/mediaQuery';
import theme from '@util/theme';

type RoundButtonStyleProps = {
  bgColor?: string;
  fontSize?: number;
  fontColor?: string;
  fontWeight?: number;
  borderColor?: string;
  borderWidth: number;
  cursor?: string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number;
  isHover?: boolean;
  hoverBgColor?: string;
  hoverColor?: string;
  hoverBorderWidth?: number;
  hoverBorderColor?: string;
  noUseMediaQuery?: boolean;
  mediaQueryWidth?: number;
  mediaQueryHeight?: number;
  mediaQueryFontSize?: number;
};

const RoundButton = styled.button<RoundButtonStyleProps>`
  ${({ bgColor, fontColor, borderWidth, cursor, borderRadius }) =>
    btnInit(
      fontColor || undefined,
      bgColor || undefined,
      { borderWidth, borderColor: theme.light.color.gray707070 } || undefined,
      pixelToRem(borderRadius ?? 50),
      cursor ?? 'pointer',
    )};
  width: ${({ width }) =>
    typeof width === 'string' ? width : pixelToRem(width ?? 240)};
  height: ${({ height }) =>
    typeof height === 'string' ? height : pixelToRem(height ?? 60)};
  font-size: ${({ fontSize }) => pixelToRem(fontSize ?? 16)};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  ${flexAlign()};

  &:hover {
    background-color: ${({ isHover, hoverBgColor }) => isHover && hoverBgColor};
    border-width: ${({ isHover, hoverBorderWidth }) =>
      isHover && `${hoverBorderWidth && hoverBorderWidth / 16}rem`};
    border-style: solid;
    border-color: ${({ isHover, hoverBorderColor }) =>
      isHover && hoverBorderColor};
    color: ${({ isHover, hoverColor }) => isHover && hoverColor};
  }

  ${({
    noUseMediaQuery,
    mediaQueryWidth,
    mediaQueryHeight,
    mediaQueryFontSize,
  }) =>
    noUseMediaQuery
      ? ''
      : `${mediaQuery()} {
    width: ${pixelToRem(mediaQueryWidth ?? 70)};
    height: ${pixelToRem(mediaQueryHeight ?? 25)};
    font-size: ${pixelToRem(mediaQueryFontSize ?? 11)};
  }
  ${media.custom(540)} {
    width: 80%;
    height: ${pixelToRem(28)};
    font-size: ${pixelToRem(10)};
  }`};
`;

export default RoundButton;
