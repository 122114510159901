import React from 'react';
import { useQuery } from 'react-query';
// import dayjs from 'dayjs';
import { realtimeExchangeHeadColumn } from '@static/user/default/exchange';
import { getfetcher } from '@util/fetcher';
import { queryKeys } from '@util/queries';
// import ProcessStatusBtn from '../../../admin/ProcessStatusBtn';
import { RealTimeRes } from '@static/user/default/commonTypes';
// import switchVoucherType from '@util/admin/switchVoucherType';
import { RandomName } from '@util/randomName';
import theme from '@util/theme';
import { dummyData } from '@static/user/default/dummyData';
import RoundButton from '../../../RoundButton';
import Pagination from '../../../Pagination';
import { FooterContainer } from '../notice/styles';
import {
  RealTimeTableContainer,
  RealTimeTopContainer,
  RealTimeHeadGrid,
  RealTimeBodyGrid,
} from './styles';
import CommonBanner from '../topBanner/CommonBanner';

function RandomSecond() {
  const randomSecond = Math.floor(Math.random() * (20 - 10 + 1)) + 10;
  return randomSecond;
}

function RealTimeExchange() {
  const [page, setPage] = React.useState<number>(1);
  const { data } = useQuery<RealTimeRes>(
    [queryKeys.user.exchangeHistory, page],
    () => getfetcher(`/exchange/`, `?page=${page}&pageSize=10`, true),
    {
      retry: 3,
      refetchInterval: 1000 * 3,
    },
  );

  return (
    <>
      <CommonBanner
        title='실시간 교환 현황'
        text='캐시핀을 믿고 이용해주시는 분들의 실시간 현금화 교환 현황입니다.'
      />
      <RealTimeTableContainer>
        <RealTimeTopContainer>
          <div className='board-header'>
            <h3>교환 신청 정보</h3>
            <p>- 해당 주문을 클릭하면 인증 후 조회가 가능합니다.</p>
          </div>
        </RealTimeTopContainer>
        <RealTimeHeadGrid style={{ fontWeight: 700 }}>
          {realtimeExchangeHeadColumn.map((value: string) => (
            <div key={value}>{value}</div>
          ))}
        </RealTimeHeadGrid>
        {dummyData
          .sort(() => Math.random() - 0.5)
          .map((item, index) => (
            <RealTimeBodyGrid key={index}>
              <div>
                <RoundButton
                  borderWidth={0}
                  bgColor={theme.light.background.lightGreen}
                  fontSize={17}
                  fontWeight={700}
                  width={96}
                  height={35}
                  cursor='initial'
                  fontColor={theme.light.color.white}
                  mediaQueryFontSize={17}
                  mediaQueryWidth={96}
                  mediaQueryHeight={35}
                >
                  입금완료
                </RoundButton>
              </div>
              <div>
                {RandomName()}
                <br />
                <div className='mobile-date'>
                  [
                  {new Date()
                    .toLocaleDateString('ko-KR', {
                      month: '2-digit',
                      day: '2-digit',
                    })
                    .slice(0, -1)}
                  ]
                </div>
              </div>
              <div>{item.title}</div>
              <div>00분 {RandomSecond()}초</div>
              <div>{item.start}</div>
              <div>{item.end}</div>
              <div>
                {item.title.replace('문화상품권', '').replace('상품권', '')}
              </div>
            </RealTimeBodyGrid>
          ))}
        <FooterContainer>
          <Pagination
            setPage={setPage}
            page={page}
            totalCount={data?.data.totalCount ?? 0}
          />
        </FooterContainer>
      </RealTimeTableContainer>
    </>
  );
}

export default React.memo(RealTimeExchange);

// {data &&
//   data.data &&
//   data.data.exchanges &&
//   data.data.exchanges.length > 0 &&
//   data.data.exchanges.map((item) => {
//     const diff = dayjs(item.completedAt).diff(item.applicatedAt, 's');
//     const seconds = 60;
//     const diffMinute = (diff / seconds).toFixed(0);
//     const diffSecond = (diff % seconds).toFixed(0);

//     return (
//       <RealTimeBodyGrid key={item.id}>
//         <div>
//           <ProcessStatusBtn status={item.status} />
//         </div>
//         <div>
//           {item.holderName}
//           <br />
//           <div className='mobile-date'>
//             [
//             {new Date()
//               .toLocaleDateString('ko-KR', {
//                 month: '2-digit',
//                 day: '2-digit',
//               })
//               .slice(0, -1)}
//             ]
//           </div>
//         </div>
//         <div>{`${switchVoucherType(
//           item.voucherType,
//         )} ${item.price.toLocaleString('ko-KR')}원`}</div>
//         <div>
//           {diffMinute}분 {diffSecond}초
//         </div>
//         <div>{`${dayjs(item.applicatedAt).format('MM')}분 ${dayjs(
//           item.applicatedAt,
//         ).format('DD')}초`}</div>
//         <div>{`${dayjs(item.completedAt).format('MM')}분 ${dayjs(
//           item.completedAt,
//         ).format('DD')}초`}</div>
//         <div>{item.holderName}</div>
//       </RealTimeBodyGrid>
//     );
//   })}
