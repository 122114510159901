import React from 'react';
import { UseExchangeText } from '@static/user/default/exchange';
import {
  AutoExchangeContainer,
  AutoExchangeText,
  BoxContainer,
} from './styles';

function UseExchange() {
  return (
    <>
      <AutoExchangeContainer>
        <AutoExchangeText>
          <h1 className='use-text'> CASH PIN 이용방법</h1>
        </AutoExchangeText>
        <BoxContainer>
          {UseExchangeText.map(({ id, subTitle, title, Icon }) => (
            <div key={id} className='box-container'>
              <div className='box-container-img'>
                <Icon size={80} />
              </div>
              <div className='box-container-text'>
                <p className='box-container-title'>
                  <span>{id}</span>
                  {title}
                </p>
                <div className='box-container-subtitle'>
                  {subTitle.map((line: string) => (
                    <p key={line}>{line}&nbsp;</p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </BoxContainer>
      </AutoExchangeContainer>
    </>
  );
}

export default React.memo(UseExchange);
