import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeLayout from '@layout/admin/HomeLayout';
import Statement from '@comp/admin/Statement';
import Footer from '@comp/Footer';

import { authToken, removeCookie } from '@util/tokenUtil';

type AdminHomeProps = {};

function AdminHome({}: AdminHomeProps) {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuth = authToken(1);
    if (isAuth) {
      navigate('/admin/home');
    } else {
      removeCookie('token');
    }
  }, [navigate]);

  return (
    <HomeLayout>
      <Statement />
      <Footer />
    </HomeLayout>
  );
}

export default React.memo(AdminHome);
