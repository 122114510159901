import styled from 'styled-components';
import { flexAlign, pixelToRem } from '@util/commonStyles';
import theme from '@util/theme';
import { media } from '@util/mediaQuery';

export const ContactContainer = styled.section`
  height: ${pixelToRem(250)};
  background-color: ${theme.light.background.whiteF5f5f5};
  .contact-section {
    height: 100%;
    margin: 0 auto;
    max-width: ${pixelToRem(1500)};
    ${flexAlign('center', 'space-between', 'center', 'flex')};

    ${media.medium} {
      margin: 0 2rem;
    }
    ${media.custom(600)} {
      margin: 0 ${pixelToRem(15)};
      flex-direction: column;
      justify-content: center;
    }
    .main-contact {
      line-height: 1.7;
      & > h1 {
        font-size: ${pixelToRem(33)};
        margin: 0;

        ${media.custom(600)} {
          font-size: ${pixelToRem(16)};
          text-align: center;
        }
      }

      p {
        margin: 0;
        font-size: ${pixelToRem(18)};

        ${media.custom(600)} {
          font-size: ${pixelToRem(14)};
          margin: ${pixelToRem(20)} 0;
          text-align: center;
        }
      }
    }

    .contact-kakao {
      & > button {
        height: ${pixelToRem(47)};
        width: ${pixelToRem(190)};
        border: none;
        background-color: ${theme.light.background.gray707070};
        color: ${theme.light.color.white};
        border-radius: ${pixelToRem(30)};
        font-size: ${pixelToRem(15)};
        font-weight: 700;
        ${media.custom(600)} {
          margin-top: ${pixelToRem(20)};
          height: ${pixelToRem(37)};
          width: ${pixelToRem(150)};
        }
      }
    }
  }
`;
