import React from 'react';
import UserBoard from '@comp/user/default/board/UserBoard';
import BottomBanner from '@comp/user/default/bottomBanner/BottomBanner';
import ExchangeRequest from '@comp/user/default/exchangeRequest/ExchangeRequest';
import TopBanner from '@comp/user/default/topBanner/TopBanner';
import UserLayout from '@layout/user/home/UserLayout';
import Contact from '@comp/user/default/contact/Contact';
import SEO from '@comp/SEO';
import Modal from '@comp/modal/Modal';

function ModalBody() {
  return (
    <>
      <div className='notice'>
        <div>안녕하세요, 캐시핀입니다.</div>
        <br />
        {/* <div>최근 캐시핀 서비스 환경이 불안정하여</div> */}
        <div>
          보다 안정적인 서비스 이용을 위해 서비스 정기 점검을 실시합니다.
        </div>
        <div>이용에 불편을 드린 점 진심으로 사과드리며,</div>
        <div>예정된 시간 안에 점검을 마칠 수 있도록 최선을 다하겠습니다.</div>
      </div>
      <div className='notice'>
        <ul className='disc'>
          <li>
            점검기간 :{' '}
            <span className='navy'>
              2023.02.08 23:00(수요일) ~ 2023.02.15 15:00(수요일)
            </span>
          </li>
          <li>
            점검대상 : <span className='navy'>상품권 교환신청 서비스</span>
          </li>
          <li>점검목적 : 안정적인 서비스 이용을 위한 정기점검</li>
        </ul>
      </div>
    </>
  );
}

function UserHome() {
  const [hidden, setHidden] = React.useState(false);

  const onHidden = React.useCallback(() => {
    setHidden(!hidden);
  }, [hidden]);

  return (
    <SEO>
      <UserLayout>
        <Modal
          hidden={hidden}
          onHidden={onHidden}
          bodyText=''
          headerFontSize={18}
          headerText='캐시핀 서비스 점검중입니다'
          bodyFontSize={15}
          isVisibleButton={false}
          subText='확인'
          bodyChildren={<ModalBody />}
        />
        <TopBanner />
        <UserBoard />
        <BottomBanner />
        <ExchangeRequest />
        <Contact />
      </UserLayout>
    </SEO>
  );
}

export default React.memo(UserHome);
