import React from 'react';
import theme from '@util/theme';
import RoundButton from '../RoundButton';
import { ProcessStatus } from './types';

type ProcessStatusBtnProps = {
  status: ProcessStatus;
};

function ProcessStatusBtn({ status }: ProcessStatusBtnProps) {
  if (status === 'COMPLETED') {
    return (
      <RoundButton
        borderWidth={0}
        bgColor={theme.light.background.lightGreen}
        fontSize={17}
        fontWeight={700}
        width={96}
        height={35}
        cursor='initial'
        fontColor={theme.light.color.white}
        mediaQueryFontSize={17}
        mediaQueryWidth={96}
        mediaQueryHeight={35}
      >
        입금완료
      </RoundButton>
    );
  }

  if (status === 'REJECTED') {
    return (
      <RoundButton
        borderWidth={0}
        bgColor={theme.light.background.lightRed}
        fontSize={17}
        fontWeight={700}
        width={96}
        height={35}
        cursor='initial'
        fontColor={theme.light.color.white}
        mediaQueryFontSize={17}
        mediaQueryWidth={96}
        mediaQueryHeight={35}
      >
        처리불가
      </RoundButton>
    );
  }

  if (status === 'REQUESTED' || status === 'WAITDEPOSIT') {
    return (
      <RoundButton
        borderWidth={0}
        bgColor={theme.light.background.lightYellow}
        fontSize={17}
        fontWeight={700}
        width={96}
        height={35}
        cursor='initial'
        fontColor={theme.light.color.white}
        mediaQueryFontSize={17}
        mediaQueryWidth={96}
        mediaQueryHeight={35}
      >
        입금대기
      </RoundButton>
    );
  }

  if (status === 'RQSTDEPOSIT') {
    return (
      <RoundButton
        borderWidth={0}
        bgColor={theme.light.background.lightYellow}
        fontSize={17}
        fontWeight={700}
        width={96}
        height={35}
        cursor='initial'
        fontColor={theme.light.color.white}
        mediaQueryFontSize={17}
        mediaQueryWidth={96}
        mediaQueryHeight={35}
      >
        입금요청
      </RoundButton>
    );
  }

  if (status === 'CPLTDEPOSIT') {
    return (
      <RoundButton
        borderWidth={0}
        bgColor={theme.light.background.lightGreen}
        fontSize={17}
        fontWeight={700}
        width={96}
        height={35}
        cursor='initial'
        fontColor={theme.light.color.white}
        mediaQueryFontSize={17}
        mediaQueryWidth={96}
        mediaQueryHeight={35}
      >
        입금완료
      </RoundButton>
    );
  }

  if (status === 'FAILDEPOSIT') {
    return (
      <RoundButton
        borderWidth={0}
        bgColor={theme.light.background.lightRed}
        fontSize={17}
        fontWeight={700}
        width={96}
        height={35}
        cursor='initial'
        fontColor={theme.light.color.white}
        mediaQueryFontSize={17}
        mediaQueryWidth={96}
        mediaQueryHeight={35}
      >
        입금 실패
      </RoundButton>
    );
  }

  if (status === 'RJTDDEPOSIT') {
    return (
      <RoundButton
        borderWidth={0}
        bgColor={theme.light.background.lightRed}
        fontSize={17}
        fontWeight={700}
        width={96}
        height={35}
        cursor='initial'
        fontColor={theme.light.color.white}
        mediaQueryFontSize={17}
        mediaQueryWidth={96}
        mediaQueryHeight={35}
      >
        입금 거절
      </RoundButton>
    );
  }

  return null;
}

export default ProcessStatusBtn;
