import styled from 'styled-components';
import { flexAlign, pixelToRem } from '@util/commonStyles';
import theme from '@util/theme';
import { media } from '@util/mediaQuery';

export const FooterContainer = styled.footer`
  height: ${pixelToRem(300)};
  background-color: ${theme.light.background.navy};

  .footer-section {
    max-width: ${pixelToRem(1500)};
    margin: 0 auto;
    height: 100%;
    ${flexAlign(undefined, 'space-between', undefined, 'flex')}
    font-size: ${pixelToRem(20)};
    color: ${theme.light.color.white};
    line-height: 1.7;

    .agrees {
      cursor: pointer;
      text-decoration: none;
      color: inherit;
    }

    ${media.medium} {
      margin: 0 2rem;
    }

    .footer-content {
      font-size: ${pixelToRem(27)};
      display: flex;
      justify-content: center;
    }

    ${media.custom(600)} {
      margin: 0 ${pixelToRem(15)};
      flex-direction: column;
      justify-content: center;

      .footer-content {
        font-size: ${pixelToRem(17)};
        flex: 1;
        align-items: center;
      }

      .footer-info {
        font-size: ${pixelToRem(10)};
        margin: 0;
        flex: 1;
      }
    }
  }
`;
