export const headList = [
  '상품권명',
  '교환금액',
  '예금주명',
  '교환시작일',
  '교환완료일',
  '입금액',
  '처리상태',
  '재이체',
];

export const accountAddForm = [
  {
    id: 1,
    name: 'name',
    text: '대표자명',
    placeholder: '이름을 입력하세요.',
    type: 'text',
  },
  {
    id: 2,
    name: 'phone',
    text: '연락처',
    placeholder: '(- 제외) 연락처를 입력하세요.',
    type: 'tel',
  },
  {
    id: 3,
    name: 'companyName',
    text: '회사명',
    placeholder: '회사명을 입력하세요.',
    type: 'text',
  },
  {
    id: 4,
    name: 'license',
    text: '사업자번호',
    placeholder: '사업자 번호를 입력하세요.',
    type: 'text',
  },
  {
    id: 5,
    name: 'siteUrl',
    text: '사이트 주소',
    placeholder: '사이트 주소(URL)를(을) 입력하세요.',
    type: 'text',
  },
  {
    id: 6,
    name: 'siteName',
    text: '사이트명',
    placeholder: '사이트명을 입력하세요.',
    type: 'text',
  },
  {
    id: 7,
    name: 'email',
    text: '아이디',
    placeholder: '아이디(이메일)를(을) 입력하세요.',
    type: 'email',
  },
  {
    id: 8,
    name: 'password',
    text: '비밀번호',
    placeholder: '비밀번호를 입력하세요.',
    type: 'password',
  },
  {
    id: 9,
    name: 'passwordConfirm',
    text: '비밀번호 확인',
    placeholder: '비밀번호를 한 번 더 입력하세요.',
    type: 'password',
  },
];

export const accountEditForm = [...accountAddForm]
  .filter((k) => k.id !== 8 && k.id !== 9)
  .sort((a, b) => a.id - b.id)
  .map((c) => {
    const newObj = { id: c.id, name: c.name, text: c.text, type: c.type };
    return newObj;
  });

export const buttonList = [
  { id: 1, to: '/superadmin/account/list', text: '계정현황' },
];

export const addedBtnList = buttonList.concat({
  id: 2,
  to: '/superadmin/account',
  text: '계정관리',
});

export const adminMenuText = [
  { id: 1, name: '사이트 현황', path: '/superadmin/home' },
  { id: 2, name: '어드민 계정 관리', path: '/superadmin/account' },
];

export const menus = [
  { id: 1, name: '사이트 현황', path: '/superadmin/home' },
  { id: 2, name: '어드민 계정 관리', path: '/superadmin/account/list' },
];

export const siteChoiceButtons = [
  { id: 1, text: '컬쳐랜드' },
  { id: 2, text: '해피머니' },
  { id: 3, text: '도서상품권' },
];

export const siteAccountColumn = [
  { id: 2, text: '연동 상태' },
  { id: 3, text: '사이트' },
  { id: 4, text: '판매자 아이디' },
  { id: 5, text: '사이트 주소' },
  { id: 6, text: '연동 정보 관리' },
];
