import { IconType } from 'react-icons';
import { GrCertificate } from 'react-icons/gr';
import { IoTicketOutline } from 'react-icons/io5';
import { MdOutlinePublishedWithChanges } from 'react-icons/md';

export const realtimeExchangeHeadColumn: string[] = [
  '처리상태',
  '고객명',
  '교환신청내역',
  '소요시간',
  '신청시간',
  '완료시간',
];
export const realtimeHeadColumn = [
  '처리상태',
  '교환신청정보',
  '고객명',
  '소요시간',
];

interface AutoTextType {
  id: string;
  title: string;
  subTitle: Array<string>;
  Icon: IconType;
}

export const UseExchangeText: AutoTextType[] = [
  {
    id: '01',
    title: '최초 1회 본인인증',
    subTitle: [
      '최초 1회 휴대폰 본인인증 및 본인계좌',
      '인증을 진행 해주세요.(타인 계좌 사용 불가)',
    ],
    Icon: GrCertificate,
  },
  {
    id: '02',
    title: '상품권 교환 신청',
    subTitle: [
      '상품권 교환 페이지를 통해 핀번호(PIN)를',
      '입력 후 교환 신청을 해주세요.',
    ],
    Icon: IoTicketOutline,
  },
  {
    id: '03',
    title: '입금 완료',
    subTitle: ['신청 즉시 자동으로 핀번호(PIN)', '확인 후 입금이 완료됩니다.'],
    Icon: MdOutlinePublishedWithChanges,
  },
];
