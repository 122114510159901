import React from 'react';
import AccountList from '@comp/superAdmin/account/AccountList';
import ManagerLayout from '@layout/superAdmin/ManagerLayout';

function SuperAdminAccountList() {
  return (
    <ManagerLayout>
      <AccountList />
    </ManagerLayout>
  );
}

export default React.memo(SuperAdminAccountList);
