import React from 'react';
import AccountManager from '@comp/superAdmin/account/AccountManager';
import ManagerLayout from '@layout/superAdmin/ManagerLayout';

function SuperAdminManager() {
  return (
    <ManagerLayout>
      <AccountManager />
    </ManagerLayout>
  );
}

export default React.memo(SuperAdminManager);
