import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  SubNavigator,
  SubNavigatorMenu,
  ContentInner,
} from '@comp/superAdmin/styles';
import { buttonList, adminMenuText, addedBtnList } from '@static/admin/admin';
import SuperLayout from './SuperLayout';

type ManagerLayoutProps = {
  children: React.ReactNode;
};

function ManagerLayout({ children }: ManagerLayoutProps) {
  const location = useLocation();
  return (
    <SuperLayout padding={0}>
      <SubNavigator>
        <h2>
          {
            adminMenuText.find((c) => location.pathname.startsWith(c.path))
              ?.name
          }
        </h2>
        {location.pathname.includes('list')
          ? buttonList.map((c) => (
              <SubNavigatorMenu key={c.id} ismatched={1}>
                {c.text}
              </SubNavigatorMenu>
            ))
          : addedBtnList.map((c) => (
              <SubNavigatorMenu
                key={c.id}
                ismatched={!c.to.includes('list') ? 1 : 0}
              >
                {c.text}
              </SubNavigatorMenu>
            ))}
      </SubNavigator>
      <ContentInner borderLeft={0}>{children}</ContentInner>
    </SuperLayout>
  );
}

export default React.memo(ManagerLayout);
