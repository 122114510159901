import {
  ChargingStatus,
  ChargingStatusText,
  DepositStatus,
  DepositStatusText,
} from './types';

export function ChargingStatusToText(
  status: ChargingStatus,
): ChargingStatusText {
  switch (status) {
    case 'REQUESTED':
      return '교환요청';
    case 'COMPLETED':
      return '교환완료';
    case 'REJECTED':
      return '교환거부';
    default:
      return '교환요청';
  }
}

export function DepositStatusToText(status: DepositStatus): DepositStatusText {
  switch (status) {
    case 'WAITDEPOSIT':
      return '입금대기';
    case 'RQSTDEPOSIT':
      return '입금요청';
    case 'CPLTDEPOSIT':
      return '입금완료';
    case 'FAILDEPOSIT':
      return '입금실패';
    case 'RJTDDEPOSIT':
      return '입금거절';
    default:
      return '입금대기';
  }
}
