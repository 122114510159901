import styled from 'styled-components';
import { flexAlign, pixelToRem, pixelToRemBorder } from '@util/commonStyles';
import { mediaQuery, media } from '@util/mediaQuery';
import theme from '@util/theme';

export const BottomBannerContainer = styled.article`
  width: 100%;
  background-color: ${theme.light.background.whiteF5f5f5};

  .bottom-banner-inner {
    max-width: 1920px;
    ${flexAlign('column')};

    & > h2 {
      font-weight: 700;
      font-size: ${pixelToRem(35)};
      margin: 0;
      margin-top: ${pixelToRem(30)};
    }

    & > h4 {
      font-size: ${pixelToRem(20)};
      margin-bottom: ${pixelToRem(100)};
      font-weight: 500;
    }
  }

  ${mediaQuery()} {
    height: auto;
    width: auto;
    padding: ${pixelToRem(60)} ${pixelToRem(30)};

    .bottom-banner-inner {
      & > h2 {
        font-size: ${pixelToRem(21)};

        ${media.custom(600)} {
          font-size: ${pixelToRem(21)};
        }
      }

      & > h4 {
        font-size: ${pixelToRem(16)};
        margin: 0;
        margin-bottom: ${pixelToRem(30)};

        ${media.custom(600)} {
          margin-top: ${pixelToRem(10)};
          font-size: ${pixelToRem(16)};
        }
      }
    }
  }

  ${media.custom(600)} {
    /* padding: ${pixelToRem(40)} ${pixelToRem(15)}; */
    padding: ${pixelToRem(40)} ${pixelToRem(10)};
  }
`;

export const BottomBannerWrapper = styled.div`
  background-color: ${theme.light.background.whiteF5f5f5};
`;

export const BottomImageWrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
`;

export const BottomImageContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 ${pixelToRem(30)};
  padding-bottom: ${pixelToRem(30)};

  .bottom-banner-image-item {
    border-radius: ${pixelToRem(50)};
    background-color: ${theme.light.background.white};
    border: ${pixelToRemBorder(1, theme.light.border.gray)};
    ${flexAlign('column', 'flex-end', undefined)};
    padding-bottom: ${pixelToRem(55)};
    padding-top: ${pixelToRem(55)};

    & > h3 {
      font-size: ${pixelToRem(25)};
      font-weight: 700;
      margin-bottom: ${pixelToRem(32)};
    }

    & > p {
      font-size: ${pixelToRem(17)};
      margin: 0;
    }
  }

  ${mediaQuery()} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: ${pixelToRem(30)} ${pixelToRem(10)};
    padding-left: ${pixelToRem(15)};
    padding-right: ${pixelToRem(15)};

    .bottom-banner-image-item {
      padding-top: ${pixelToRem(10)};
      padding-bottom: ${pixelToRem(10)};
      height: 250px;
      white-space: nowrap;
      word-wrap: break-word;
      word-break: break-all;

      ${flexAlign('column', 'center', 'center', 'flex')};

      & > h3 {
        font-size: ${pixelToRem(15)};
        padding: 0;
        margin: 0;
        margin-top: ${pixelToRem(30)};
      }

      & > p {
        margin-top: ${pixelToRem(10)};
        font-size: ${pixelToRem(11)};
      }
    }
  }
`;

export const BottomImageItem = styled.div``;
