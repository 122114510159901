import React from 'react';
import { createGlobalStyle } from 'styled-components';
import LoginStatus from '@comp/admin/LoginStatus';
import {
  AdminContainer,
  AdminHeaderContainer,
  DashboardHeader,
  DashboardInner,
} from '@comp/admin/styles';
import { siteTitleEnglish } from '@static/common';

const GlobalStyles = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
`;

type HomeLayoutProps = {
  children: React.ReactNode;
};

function HomeLayout({ children }: HomeLayoutProps) {
  return (
    <>
      <GlobalStyles />
      <AdminContainer>
        <AdminHeaderContainer>
          <div>{siteTitleEnglish}</div>
          <LoginStatus />
        </AdminHeaderContainer>
        <DashboardHeader>스토어 현황</DashboardHeader>
        <DashboardInner>{children}</DashboardInner>
      </AdminContainer>
    </>
  );
}

export default React.memo(HomeLayout);
