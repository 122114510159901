import styled from 'styled-components';
import { flexAlign, pixelToRem } from '@util/commonStyles';
import theme from '@util/theme';
import { media } from '@util/mediaQuery';

export const TableContainer = styled.div`
  ${flexAlign('column', undefined, 'stretch', 'stretch')};
  max-width: ${pixelToRem(1400)};
  margin: 4rem auto;
  flex: 1;
  ${media.custom(1430)} {
    margin: 3rem 2rem 0;
  }
  ${media.medium} {
    width: auto;
    margin: 3rem 1rem 0;
  }
`;

export const TopContainer = styled.div`
  ${flexAlign(undefined, 'space-between', 'center')};
  flex: 1;
  height: ${pixelToRem(100)};
  ${media.custom(500)} {
    h1 {
      font-size: ${pixelToRem(24)};
    }
  }
  p {
    margin-top: ${pixelToRem(20)};
    font-size: ${pixelToRem(17)};
    font-weight: 500;
    color: ${theme.light.color.black};
  }
  .searchBox {
    height: ${pixelToRem(40)};
    box-sizing: border-box;
    border: 1px solid ${theme.light.border.gray};
    background-color: ${theme.light.background.white};
    ${flexAlign(undefined, 'space-between')};
    padding: ${pixelToRem(16)};
    border-radius: ${pixelToRem(10)};
    flex: 0.15;
    margin-top: ${pixelToRem(25)};
    ${media.custom(500)} {
      height: ${pixelToRem(30)};
      margin-top: ${pixelToRem(30)};
      padding: 0 ${pixelToRem(5)};
      svg {
        scale: 0.8;
      }
    }
    input {
      flex: 1;
      outline: none;
      border: none;
      &::placeholder {
        color: #707070;
      }
    }
    svg {
      cursor: pointer;
    }
  }
`;

export const HeadGrid = styled.div`
  ${flexAlign('center', 'center', 'center', 'grid')};
  font-size: ${pixelToRem(20)};
  grid-template-columns: 1fr 6fr 2fr 1fr;
  grid-template-rows: 1fr;
  box-sizing: border-box;
  border-top: 2px solid ${theme.light.border.black};
  border-bottom: 1px solid ${theme.light.border.gray};
  padding: ${pixelToRem(16)} 0;
  font-size: 20px;
  ${media.medium} {
    display: flex;
    justify-content: flex-start;
  }

  & > div {
    box-sizing: border-box;
    border-right: 2px solid ${theme.light.border.gray};
    ${flexAlign()};
    padding: ${pixelToRem(5)} ${pixelToRem(5)};
    height: 40px;
    margin: 0;
    ${media.medium} {
      height: ${pixelToRem(30)};
      border-right: none;
    }
  }
  & > div:nth-child(1) {
    ${media.medium} {
      display: none;
    }
  }
  & > div:nth-child(2) {
    padding: 0 ${pixelToRem(35)};
    justify-content: flex-start;
    ${media.medium} {
      padding: 0 ${pixelToRem(10)};
    }
  }
  & > div:nth-child(3) {
    ${media.medium} {
      display: none;
    }
  }
  & > div:nth-child(4) {
    border-right: none;
    ${media.medium} {
      display: none;
    }
  }
`;

export const BodyGrid = styled(HeadGrid)`
  background-color: ${theme.light.background.white};
  border-top: 1px solid ${theme.light.border.gray};
  border-bottom: 1px solid ${theme.light.border.gray};
  font-size: 19px;
  & > div {
    overflow: hidden;
  }
  & > div:nth-child(2) {
    cursor: pointer;
  }
  & > div:nth-child(4) {
    border-right: none;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${pixelToRem(30)} 0;
`;

export const BodyGridNoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${theme.light.border.gray};
  background-color: ${theme.light.background.white};
  box-sizing: border-box;
  height: ${pixelToRem(180)};
  font-size: 20px;
  color: ${theme.light.color.black};
`;

export const ReadHead = styled.div`
  ${flexAlign('column', 'center', 'center', 'flex')};
  box-sizing: border-box;
  border-top: 2px solid ${theme.light.border.black};
  border-bottom: 1px solid ${theme.light.border.gray};
  padding: ${pixelToRem(30)} 0;
  .notice-title {
    font-size: ${pixelToRem(30)};
    font-weight: 700;
    margin-bottom: ${pixelToRem(10)};
  }
  .notice-data {
    font-size: ${pixelToRem(20)};
    color: ${theme.light.color.gray707070};
  }
`;

export const ReadContent = styled.pre`
  font-size: 20px;
  color: ${theme.light.color.black};
  border-bottom: 1px solid ${theme.light.border.gray};
  padding: ${pixelToRem(50)} 0;
`;

export const RoundButton = styled.button`
  ${flexAlign(undefined, 'center', 'center', 'flex')};
  margin: 3.5rem auto 0;
  width: ${pixelToRem(238)};
  height: ${pixelToRem(60)};
  border-radius: ${pixelToRem(30)};
  border: none;
  background-color: ${theme.light.background.navy};
  color: ${theme.light.color.white};
  font-size: ${pixelToRem(20)};
  font-weight: 700;
`;

export const MoveNotice = styled.div`
  div {
    height: 4rem;
    font-size: ${pixelToRem(19)};
    color: ${theme.light.color.gray707070};
    /* margin: 2rem 0; */
    border-bottom: 1px solid ${theme.light.border.gray};
    display: flex;
    align-items: center;
    justify-content: start;
    > button {
      font-size: ${pixelToRem(17)};
      font-weight: 900;
      margin-left: ${pixelToRem(100)};
      border: none;
      background: none;
    }
  }
`;
