import React from 'react';
import { ContactContainer } from './styles';

function Contact() {
  return (
    <ContactContainer>
      <div className='contact-section'>
        <div className='main-contact'>
          <h1>CASH PIN은 24시간 교환신청이 가능합니다</h1>
          <p>언제든 편하게 문의주시면 친절하게 안내해드리겠습니다.</p>
        </div>
        <div className='contact-kakao'>
          <div> </div>
        </div>
      </div>
    </ContactContainer>
  );
}

export default React.memo(Contact);
