import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import UserRead from '@comp/user/default/notice/UserRead';
import UserHome from '@page/user/default/UserHome';
import UserAutoExchange from '@page/user/default/UserAutoExchange';
import UserRealTime from '@page/user/default/UserRealTime';
import UserNotice from '@page/user/default/UserNotice';
import UserFAQ from '@page/user/default/UserFAQ';
import MyExchange from '@page/user/default/MyExchange';
import MyExchangeAuth from '@page/user/default/MyExchangeAuth';

import AdminHome from '@page/admin/AdminHome';
import AdminLogin from '@page/admin/AdminLogin';

import SuperAdminHome from '@page/superAdmin/SuperAdminHome';
import SuperAdminManager from '@page/superAdmin/SuperAdminManager';
import SuperAdminCreate from '@page/superAdmin/SuperAdminCreate';
import SuperAdminLogin from '@page/superAdmin/SuperAdminLogin';
import SuperAdminSiteCreate from '@page/superAdmin/SuperAdminSiteCreate';
import SuperAdminAccountList from '@page/superAdmin/SuperAdminAccountList';

function UserRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<UserHome />} path='/' caseSensitive />
        <Route
          element={<UserAutoExchange />}
          path='/user/exchange'
          caseSensitive
        />
        <Route element={<UserRealTime />} path='/user/realtime' caseSensitive />
        <Route element={<UserNotice />} path='/user/notice' caseSensitive />
        <Route element={<UserFAQ />} path='/user/faq' caseSensitive />
        <Route element={<UserRead />} path='/user/notice/:id' caseSensitive />
        {/* <Route element={<MyExchange />} path='/user/mylist' caseSensitive /> */}
        {/* <Route element={<MyExchangeAuth />} path='/user/myauth' caseSensitive /> */}

        <Route element={<AdminHome />} path='/admin/home' caseSensitive />
        <Route element={<AdminLogin />} path='/admin/login' caseSensitive />

        <Route
          element={<SuperAdminLogin />}
          path='/superadmin/login'
          caseSensitive
        />
        <Route
          element={<SuperAdminHome />}
          path='/superadmin/home'
          caseSensitive
        />
        <Route
          element={<SuperAdminAccountList />}
          path='/superadmin/account/list'
          caseSensitive
        />
        <Route
          element={<SuperAdminManager />}
          path='/superadmin/account/manage/:id'
          caseSensitive
        />
        <Route
          element={<SuperAdminCreate />}
          path='/superadmin/account/create'
          caseSensitive
        />
        <Route
          element={<SuperAdminSiteCreate />}
          path='/superadmin/account/site/create'
          caseSensitive
        />
        <Route path='/docs/terms.pdf' element={<></>} />
        <Route path='/docs/privacy.pdf' element={<></>} />
        {/* <Route path='/rss.xml' element={<></>} /> */}
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
}

export default React.memo(UserRouter);
