import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { btnInit, flexAlign, pixelToRem } from '@util/commonStyles';
import theme from '@util/theme';
import { mediaQuery } from '@util/mediaQuery';

export const HeaderContainer = styled.header`
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: ${theme.light.background.navy};
  height: 6.25rem;
  ${flexAlign()};
  margin-bottom: -${pixelToRem(100)};
  ${mediaQuery()} {
    height: ${pixelToRem(45)};
  }
`;

export const HeaderInner = styled.div`
  width: 100%;
  margin: 0 2rem;
  max-width: ${pixelToRem(1500)};
  ${flexAlign(undefined, 'space-between', 'center')};
  height: 100%;
  div {
    color: ${theme.light.color.white};
    font-size: ${pixelToRem(40)};
    font-weight: 500;
  }

  .user-header-dummy,
  .user-header-mobile-menu {
    display: none;
  }

  ${mediaQuery()} {
    .user-header-dummy,
    .user-header-mobile-menu {
      display: block;
    }

    .user-header-dummy {
      width: ${pixelToRem(24)};
      height: 100%;
    }

    .user-header-mobile-menu {
      cursor: pointer;
      /* margin-right: ${pixelToRem(20)}; */
      svg {
        fill: ${theme.light.color.black};
      }
    }
  }
`;

export const HeaderMenuContainer = styled.div`
  color: ${theme.light.color.white};
  ${flexAlign()};
  height: 100%;

  ${mediaQuery()} {
    display: none;
  }
`;

export const MenuElementLogo = styled(Link)`
  ${btnInit(theme.light.color.white, 'transparent')};
  font-weight: 900;
  font-size: ${pixelToRem(35)};
  margin-right: 4rem;
  height: 100%;
  ${flexAlign()};

  ${mediaQuery()} {
    font-size: ${pixelToRem(17)};
    font-weight: 900;
    margin-right: 0;
    img {
      display: block;
      width: 24px;
      margin-right: 0.5rem;
    }
  }
`;

export const MenuElement = styled(Link)`
  ${btnInit(theme.light.color.white, 'transparent')};
  font-weight: 700;
  font-size: ${pixelToRem(23)};
  margin-right: ${pixelToRem(40)};

  height: 100%;
  position: relative;
  ${flexAlign()};

  &:last-child {
    margin-right: 0;
  }

  &:hover:after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: ${pixelToRem(8)};
    background-color: ${theme.light.color.white};
  }
`;

export const LinkRadius = styled(Link)`
  text-decoration: none;
  color: ${theme.light.color.gray707070};
  background-color: ${theme.light.background.white};
  border-radius: ${pixelToRem(50)};
  width: ${pixelToRem(140)};
  height: ${pixelToRem(45)};
  font-size: ${pixelToRem(20)};
  font-weight: 700;
  ${flexAlign()};

  &:visited {
    text-decoration: none;
    color: ${theme.light.color.gray707070};
  }

  ${mediaQuery()} {
    display: none;
  }
`;
