/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';

type textType = { [key: string]: string };

export default function useInputObj(
  initValue: textType,
): [
  textType,
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  React.Dispatch<React.SetStateAction<textType>>,
] {
  const [text, setText] = useState(initValue ?? {});

  const onText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setText({ ...text, [e.target.name]: e.target.value });
    },
    [text],
  );

  return [text, onText, setText];
}
