import React from 'react';
import AccountCreate from '@comp/superAdmin/account/AccountCreate';
import SuperLayout from '@layout/superAdmin/SuperLayout';

function SuperAdminCreate() {
  return (
    <SuperLayout>
      <AccountCreate />
    </SuperLayout>
  );
}

export default React.memo(SuperAdminCreate);
