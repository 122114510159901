import React from 'react';
import SuperLayout from '@layout/superAdmin/SuperLayout';
import Statement from '@comp/superAdmin/statement/Statement';

function SuperAdminHome() {
  return (
    <SuperLayout>
      <Statement />
    </SuperLayout>
  );
}

export default React.memo(SuperAdminHome);
