import React from 'react';
import UserHeader from '@comp/user/default/header/UserHeader';
import UserFooter from '@comp/user/default/footer/UserFooter';
import DrawerNavigation from '@comp/DrawerNavigation';
import SocialLink from '@comp/SocialLink';
import { UserGlobalStyles, UserLayoutContainer } from './styles';

type UserLayoutProps = {
  children?: React.ReactNode;
};

function UserLayout({ children }: UserLayoutProps) {
  return (
    <UserLayoutContainer>
      <UserGlobalStyles />
      {/* <SocialLink /> */}
      <UserHeader />
      <DrawerNavigation />
      {children || null}
      <UserFooter />
    </UserLayoutContainer>
  );
}

export default React.memo(UserLayout);
