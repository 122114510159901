import styled from 'styled-components';
import { pixelToRem, flexAlign } from '@util/commonStyles';
import theme from '@util/theme';
import { media } from '@util/mediaQuery';

export const AutoExchangeContainer = styled.div`
  background-color: #f7f7f7;
  padding: 2rem 0;
  width: 100%;
  ${media.medium} {
    width: auto;
    margin: 0 1rem;
    background-color: ${theme.light.background.white};
    border-bottom: 2px solid ${theme.light.border.gray};
  }
`;

export const AutoExchangeText = styled.div`
  ${flexAlign(undefined, 'center', 'none', 'flex')}
  font-size: ${pixelToRem(20)};
  ${media.medium} {
    justify-content: flex-start;
    font-size: ${pixelToRem(17)};
    padding: 0;
    margin-bottom: -2rem;
  }
  ${media.custom(500)} {
    justify-content: center;
    font-size: ${pixelToRem(14)};
  }
`;

export const BoxContainer = styled.div`
  ${flexAlign('row', 'space-around', 'center', 'flex')}
  /* text-align: center; */
  max-width: ${pixelToRem(1500)};
  margin: ${pixelToRem(40)} auto 0;
  ${media.medium} {
    margin: ${pixelToRem(40)} 1rem;
    ${flexAlign('column', 'none', 'flex-start', 'flex')};

    & > :nth-child(1) {
      margin-left: 10rem;

      ${media.medium} {
        margin: 0;
      }
    }

    & > :nth-child(3) {
      margin-right: 10rem;

      ${media.medium} {
        margin: 0;
      }
    }
  }

  .box-container {
    width: 500px;
    ${media.medium} {
      width: auto;
    }
  }
  .box-container-img {
    width: 200px;
    height: 200px;
    background-color: ${theme.light.background.white};
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 3rem;

    svg,
    path {
      color: ${theme.light.background.navy};
      stroke: ${theme.light.background.navy};
    }

    ${media.medium} {
      display: none;
    }
  }

  .box-container-text {
    text-align: center;
    width: auto;
    span {
      font-weight: 700;
      margin-right: 1rem;
    }
    .box-container-title {
      font-size: ${pixelToRem(25)};
      font-weight: 800;
      margin-left: -1rem;

      ${media.medium} {
        font-size: ${pixelToRem(19)};
        display: flex;
        margin: 1rem auto 0;
      }
    }
    .box-container-subtitle {
      ${media.medium} {
        color: ${theme.light.color.gray707070};
        display: flex;
      }

      ${media.custom(575)} {
        ${flexAlign('column', 'none', 'flex-start', 'flex')}
        & > :last-child {
          margin-top: -0.3rem;
        }
      }
      p {
        line-height: 1;
        padding: 0;
        font-size: ${pixelToRem(15)};
      }
    }
  }
`;
